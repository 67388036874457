import { gql } from 'apollo-boost';

export const CANCEL_SUBSCRIPTION = gql`
  mutation cancelSubscription {
    cancelSubscription {
      user {
        plan
        cancellation_date
        route_settings {
          destinations_visited
          logs {
            id
            created_at
            date
            mileage
            source
            category
          }
          mileage
          payment_status
          route_id
          stamps
        }
      }
    }
  }
`;
