import React from 'react';
import {
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonGrid,
  IonIcon,
  IonItem,
  IonRow,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { List } from '../List';
import { SlideHeader } from './SlideHeader';
import {
  compassOutline,
  giftOutline,
  newspaperOutline,
  trailSignOutline,
  tvOutline,
} from 'ionicons/icons';
import { Card } from '../Card/Card';
import { Button } from '../Button/Button';

export enum PLANS {
  MONTHLY = 'monthly',
  FLAT = 'flat',
}
interface Props {
  onPlanSelect(plan: PLANS): void;
  selectedPlan: PLANS;
}

const defaultPerks = [
  { icon: tvOutline, text: 'VIRTUAL_ACCESS' },
  { icon: giftOutline, text: 'BUFF' },
  { icon: newspaperOutline, text: 'CERTIFICATE' },
];

const Perk = ({ icon, text }: any) => {
  const { t } = useTranslation();
  return (
    <IonItem>
      <IonIcon icon={icon} style={{ color: 'var(--color-green-500)' }} /> &nbsp;{' '}
      {t(`components.PlansListSlide.PERK_${text}`)}
    </IonItem>
  );
};

const PurchaseCard = ({
  children,
  isSelected,
  onPlanSelect,
  subtitle,
  title,
  emptySpace = '',
}: any) => (
  <Card
    bgStyle={{ backgroundImage: 'none' }}
    style={{
      border: '1px solid #f2f5f7',
      boxShadow: isSelected
        ? '0 0 0 4px var(--color-primary-500)'
        : '0 0 0 1px #eee',
      paddingBottom: 0,
    }}
  >
    <IonCardHeader>
      <IonCardSubtitle>{subtitle}</IonCardSubtitle>
      <IonCardTitle>{title}</IonCardTitle>
    </IonCardHeader>
    <IonCardContent
      style={{ padding: 0, marginBottom: emptySpace ? emptySpace : 0 }}
    >
      <List>
        {children}
        {defaultPerks.map((perk) => (
          <Perk key={perk.text} {...perk} />
        ))}
      </List>
    </IonCardContent>
    <div style={{ textAlign: 'right' }}>
      <Button onClick={onPlanSelect}>Select</Button>
    </div>
  </Card>
);

export const PlansListSlide = ({ onPlanSelect, selectedPlan }: Props) => {
  const { t } = useTranslation();

  return (
    <IonGrid style={{ width: '100vw' }}>
      <SlideHeader caption="Select a plan" title="Plans" />
      <IonRow>
        <IonCol sizeMd="6" sizeXs="12">
          <PurchaseCard
            onPlanSelect={() => onPlanSelect(PLANS.MONTHLY)}
            subtitle={t('components.PlansListSlide.PER_MONTH')}
            title={t('components.PlansListSlide.PLAN_MONTHLY')}
            isSelected={selectedPlan === PLANS.MONTHLY}
            emptySpace={'44px'}
          >
            <IonItem>
              <IonIcon icon={trailSignOutline} /> &nbsp;{' '}
              {t('components.PlansListSlide.ACCESS_ALL_ROUTES')}
            </IonItem>
            <IonItem>
              <IonIcon icon={compassOutline} /> &nbsp;{' '}
              {t('components.PlansListSlide.PERK_ROUTES')}
            </IonItem>
          </PurchaseCard>
        </IonCol>
        <IonCol sizeMd="6">
          <PurchaseCard
            onPlanSelect={() => onPlanSelect(PLANS.FLAT)}
            subtitle={t('components.PlansListSlide.PER_ROUTE')}
            title={t('components.PlansListSlide.PLAN_FLAT')}
            isSelected={selectedPlan === PLANS.FLAT}
            emptySpace={'88px'}
          >
            <IonItem>
              <IonIcon icon={trailSignOutline} /> &nbsp;{' '}
              {t('components.PlansListSlide.ACCESS_ROUTE')}
            </IonItem>
          </PurchaseCard>
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
