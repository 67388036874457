import {
  IonAlert,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  isPlatform,
  IonItemDivider,
  IonBadge,
  IonRouterLink,
} from '@ionic/react';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { Button } from '../components/Button/Button';
import { CancelModal } from '../components/CancelModal/CancelModal';
import { ContactInfoForm } from '../components/ContactInfoForm';
import { LanguageSettings } from '../components/LanguageSettings';
import { UserContext } from '../context/UserContext';
import { Toolbar } from '../components/Toolbar';
import { PageWrapper } from '../components/PageWrapper';
import { UserDetail } from '../components/UserDetail/UserDetail';
import { WebNotifications } from '../components/settings/WebNotifications';
import { PaymentModal } from '../components/PaymentModal/PaymentModal';
import { RouteSettings } from '../components/RouteSettings';
import { TrackerSettings } from '../components/Trackers/TrackerSettings';
import { ToggleItem, ToggleItemEvent } from '../components/settings/ToggleItem';
import { useNotification } from '../context/NotificationContext';
import { useBoolean } from '../hooks/useBoolean';
import { isTestEmail } from '../util/features';
import { useActiveRoute } from '../hooks/useActiveRoute';
import { oneSignalUtil } from '../util/oneSignal';
import constants from '../util/constants';

import { CapacitorHealthkit } from '@perfood/capacitor-healthkit';

export const Settings = () => {
  const { notify } = useNotification();
  const { t } = useTranslation();

  const { resetUser, setUser, user } = useContext(UserContext);
  const [openModal, setOpenModal] = useState(false);
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [showResetAlert, openResetAlert, hideResetAlert] = useBoolean(false);
  const { route, payment_status } = useActiveRoute();

  const onChangeDistanceUnit = (e: any) =>
    setUser(
      {
        user_settings: {
          ...user?.user_settings,
          distance_unit: e.detail.value,
        },
      },
      {
        persist: true,
        onSuccess: () => notify(t('pages.settings.CHANGES_SAVED')),
      }
    );

  const onChangePrivacy = ({ detail: { checked } }: ToggleItemEvent) =>
    setUser(
      {
        user_settings: {
          ...user?.user_settings,
          private: checked,
        },
      },
      {
        persist: true,
        onSuccess: () =>
          notify(
            `${t('pages.settings.UPDATE_PRIVACY')} ${
              checked ? t('pages.settings.Private') : t('pages.settings.Public')
            }`
          ),
      }
    );

  const checkHealthkitAuth = () => {
    return (
      !isPlatform('ipad') &&
      isPlatform('cordova') &&
      CapacitorHealthkit &&
      CapacitorHealthkit.isAvailable()
    );
  };

  const isPaid: boolean = !!payment_status;
  const isMonthlySubscriber: boolean = user.plan === 'monthly';
  let daysToExpire = null;

  if (user.cancellation_date) {
    const cancellationDate = moment.unix(user.cancellation_date);
    const currentDay = moment().startOf('day');

    daysToExpire = Math.floor(
      moment.duration(cancellationDate.diff(currentDay)).asDays()
    );
  }

  return user ? (
    <>
      <Toolbar />
      <PaymentModal
        onClose={() => setOpenModal(false)}
        isOpen={openModal}
        product={route.product}
      />
      <CancelModal
        onClose={() => setOpenCancelModal(false)}
        isOpen={openCancelModal}
        product={route.product}
      />
      <PageWrapper padding="0px">
        {!isPlatform('desktop') && <UserDetail route={route} user={user} />}
        <ContactInfoForm />
        <IonItemDivider style={{ paddingTop: '25px' }}>
          {t('pages.settings.Distance')}
        </IonItemDivider>
        <IonRadioGroup
          onIonChange={onChangeDistanceUnit}
          value={user.user_settings.distance_unit}
        >
          <IonItem>
            <IonLabel>{t('pages.settings.Miles (default)')}</IonLabel>
            <IonRadio slot="end" value="miles" />
          </IonItem>
          <IonItem>
            <IonLabel>{t('pages.settings.Km')}</IonLabel>
            <IonRadio slot="end" value="km" />
          </IonItem>
        </IonRadioGroup>
        <br />
        <br />

        <IonItemDivider>{t('pages.settings.General Settings')}</IonItemDivider>
        {user?.team && (
          <>
            <IonItem lines="none">
              <IonLabel>{t('common.TEAM')}</IonLabel>
              <IonLabel style={{ textAlign: 'right' }}>
                {user?.team.name}
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonRouterLink
                color="primary"
                href={`mailto:${user?.team.owner}?subject=[${user?.team.name}] Had a question `}
                target="blank"
              >
                {t('pages.settings.TEAM_ADMIN')}
              </IonRouterLink>
            </IonItem>
          </>
        )}
        <ToggleItem
          checked={user.user_settings.private}
          label={t('pages.settings.Keep account private')}
          onToggle={onChangePrivacy}
        />
        {oneSignalUtil.isAvailable() && <WebNotifications />}
        {!isPaid && (
          <IonItem>
            <IonLabel>
              <h2 style={{ display: 'flex' }}>
                {t('pages.settings.UPGRADE_DESCRIPTION')}
                <IonBadge
                  style={{
                    // @ts-ignore
                    '--background': 'var(--ion-color-warning)',
                    '--color': 'white',
                    marginLeft: '4px',
                  }}
                >
                  1
                </IonBadge>
              </h2>
            </IonLabel>
            <Button
              onClick={() => {
                setOpenModal(true);
              }}
              slot="end"
            >
              {t('pages.settings.UPGRADE_BUTTON')}
            </Button>
          </IonItem>
        )}
        {/* Remove IsTestEmail() when RouteSettings is complete  */}
        {isTestEmail(user.email) && <RouteSettings />}
        <LanguageSettings />
        <br />
        <br />
        <IonItemDivider>{t('pages.settings.Trackers')}</IonItemDivider>
        <TrackerSettings source="fitbit" disabled={!isPaid} />
        {checkHealthkitAuth() && isPlatform('cordova') && (
          <>
            <TrackerSettings source="healthkit" disabled={!isPaid} />
            <p style={{ padding: '10px' }}>
              <small
                dangerouslySetInnerHTML={{
                  __html: t('pages.settings.disable healthkit message'),
                }}
              />
            </p>
          </>
        )}

        {isMonthlySubscriber && (
          <>
            <br />
            <br />
            <IonItemDivider>
              {t('pages.settings.MANAGE_SUBSCRIPTION')}
            </IonItemDivider>
            <br />
            <IonItem>
              {user.cancellation_date ? (
                <p>
                  {daysToExpire} {t('pages.settings.DAYS_TO_EXPIRE')}
                </p>
              ) : (
                <Button
                  color="light"
                  onClick={() => {
                    setOpenCancelModal(true);
                  }}
                  slot="end"
                >
                  {t('components.STOP_SUBSCRIPTION.STOP')}
                </Button>
              )}
            </IonItem>
          </>
        )}

        <br />
        <br />
        <IonItemDivider>{t('pages.settings.Reset Account')}</IonItemDivider>
        <IonItem>
          <IonLabel>{t('pages.settings.RESET_DESCRIPTION')}</IonLabel>
          <Button
            color="danger"
            onClick={openResetAlert}
            slot="end"
            id="reset-account"
          >
            {t('pages.settings.Reset')}
          </Button>
        </IonItem>

        <br />
        <br />
        <IonItemDivider>{t('pages.settings.LEGAL')}</IonItemDivider>
        <IonItem>
          <IonRouterLink
            color="primary"
            href={`${constants.WEB_URL}/privacy/`}
            target="blank"
          >
            {t('common.PRIVACY_POLICY')}
          </IonRouterLink>
        </IonItem>
        <IonItem>
          <IonLabel color="dark">{t('pages.settings.VERSION')}</IonLabel>
          <IonLabel color="medium" style={{ textAlign: 'right' }}>
            v{process.env.REACT_APP_VERSION}
          </IonLabel>
        </IonItem>
        <IonAlert
          buttons={[
            {
              handler: hideResetAlert,
              role: 'cancel',
              text: t('common.CANCEL'),
            },
            {
              handler: resetUser,
              text: t('pages.settings.Delete'),
              cssClass: 'confirm-delete',
            },
          ]}
          header={t('pages.settings.Reset Account')}
          isOpen={showResetAlert}
          message={t('pages.settings.reset message')}
          onDidDismiss={hideResetAlert}
          subHeader={t('pages.settings.This action is permanent')}
        />
      </PageWrapper>
    </>
  ) : null;
};
