import { IonContent, IonItem, IonPage, isPlatform } from '@ionic/react';
import React, { ReactElement, useContext, useEffect } from 'react';

import { Caption, P } from '../components/Text';
import i18next from 'i18next';
import { LoginDesktopView } from '../components/LoginView/LoginDesktopView';
import { LoginMobileView } from '../components/LoginView/LoginMobileView';
import { LoginForm } from '../components/LoginForm/LoginForm';
import { LoginContext } from '../context/LoginContext';
import { Link } from '../components/Link/Link';
import { Text } from '../components/Text';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { SplashScreen } from '@capacitor/splash-screen';

export const Login = ({ message }: { message: ReactElement }) => {
  const { error, loading, login } = useContext(LoginContext);

  const [_get, set] = useLocalStorage(); // eslint-disable-line @typescript-eslint/no-unused-vars

  const LoginContent = isPlatform('desktop')
    ? LoginDesktopView
    : LoginMobileView;

  useEffect(() => {
    set('prompt_timer_status', true);
  }, [set]);

  SplashScreen.hide();
  return (
    <IonPage>
      <IonContent
        style={{
          // @ts-ignore
          '--background': isPlatform('desktop') ? '#eee' : '',
          alignItems: 'center',
          display: 'flex',
          height: '100vh',
        }}
      >
        {
          <div style={{ display: loading ? 'none' : 'initial' }}>
            <LoginContent>
              {message || (
                <P color="grey" style={{ marginBottom: '20px' }}>
                  {String(
                    i18next.t(
                      isPlatform('desktop')
                        ? 'pages.login.intro'
                        : 'pages.login.intro_short'
                    )
                  )}
                </P>
              )}
              <LoginForm onSubmit={login} />
              {error && <Text color="var(--ion-color-danger)">{error}</Text>}
              <br />
              <IonItem lines="none">
                <Caption>
                  Don't have an account? <Link href="/signup">Signup</Link>
                  <br />
                  <Link href="/forgot-password">Forgot Password</Link>
                </Caption>
              </IonItem>
            </LoginContent>
          </div>
        }
      </IonContent>
    </IonPage>
  );
};
