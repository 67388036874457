import { ceil } from 'lodash';
import constants from './constants';
import { customizeDistance } from './customizeDistance';
import i18n from '../i18n';

type RenderDistanceConfig = {
  decimalPoints?: number;
  displayUnit?: boolean;
};

export const renderDistance = (
  distance: number,
  unit: string = constants.DISTANCE_UNITS.DEFAULT,
  { decimalPoints = 1, displayUnit = true }: RenderDistanceConfig = {}
) => {
  // @TODO: FIX RENDERDISTANCE UTIL
  // This function is returning an incorrect result when passing the distance `215.201`
  // renderDistance(215.201, 'km') returns "215.3 km" and it should return "215.2  km"
  const convertedMileage = ceil(
    customizeDistance(distance || 0, unit),
    decimalPoints
  );

  const distanceToDisplay = convertedMileage.toFixed(decimalPoints);

  // Miles need to be translated to spanish but not able to import useTranslation inside renderDistance
  return `${distanceToDisplay} ${displayUnit ? i18n.t(unit) : ''}`;
};
