import { gql } from 'apollo-boost';

export const TEAM_TYPE = `
    onTeam
    owner
    team
    seatCount
    seatsAvailable
    seatsUsed
`;

export const GET_TEAM = gql`
  query teamMembership($id: String!) {
    teamMembership(id: $id) {
      name: String
      owner: String
      hasCapacity: Boolean
    }
  }
`;

export const JOIN_TEAM = gql`
  mutation joinTeam($id: String!) {
    joinTeam(id: $id) {
      ${TEAM_TYPE}
    }
  }
`;
