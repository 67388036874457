export type TrackerType = 'fitbit' | 'healthkit' | 'under_armour';

const constants = {
  ENVIRONMENT: process.env.REACT_APP_ENVIRONMENT,
  ENDPOINT:
    process.env.REACT_APP_ENVIRONMENT === 'test'
      ? `http://${window.location.hostname}:4000`
      : process.env.REACT_APP_BACKEND,
  DISTANCE_UNITS: {
    DEFAULT: 'miles',
    MULTIPLIER: 0.62137099999751455037, // miles per km
    KM: 'km',
    MILES: 'miles',
  },
  PAYWALL_DISTANCE: 8,
  PLANS: {
    MONTHLY: 'Monthly',
    FLAT: 'Flat',
  },
  TRACKERS: {
    fitbit: { base_date: 'fitbit_base_date', id: 'fitbit', name: 'Fitbit' },
    healthkit: {
      base_date: 'healthkit_base_date',
      id: 'healthkit',
      name: 'HealthKit',
    },
    under_armour: {
      base_date: 'ua_base_date',
      id: 'under_armour',
      name: 'Under Armour',
    },
  },
  S3: 'https://camino-for-good.s3.us-east-2.amazonaws.com',
  WEB_URL: 'https://caminoforgood.com',
};

export default constants;
