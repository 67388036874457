import { includes } from 'lodash';
import React from 'react';
import { DestinationListItem } from './DestinationListItem';
import { List, ListHeader } from '../../components/List';
import { Text } from '../../components/Text';
import { IDestination } from '../../context/DestinationContext';
import constants from '../../util/constants';
import { useActiveRoute } from '../../hooks/useActiveRoute';
interface DestinationListProps {
  currentMileage: number;
  destinations: IDestination[];
  distanceUnit?: string;
  header?: React.ReactNode;
}

export const DestinationList: React.FC<DestinationListProps> = ({
  currentMileage,
  destinations,
  distanceUnit = constants.DISTANCE_UNITS.DEFAULT,
  header,
}) => {
  const { destinations_visited, stamps } = useActiveRoute();

  const isStamped = (id: string): boolean => includes(stamps, id);

  return (
    <List>
      {header && (
        <ListHeader style={{ marginBottom: '16px' }}>
          <Text fontWeight={800}>{header}</Text>
        </ListHeader>
      )}
      {destinations.map((d) => (
        <DestinationListItem
          currentMileage={currentMileage}
          distanceUnit={distanceUnit}
          destination={d}
          isStamped={isStamped(d.id)}
          key={d.id}
          visited={destinations_visited.includes(d.id)}
        />
      ))}
    </List>
  );
};
