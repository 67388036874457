import React, { CSSProperties, ReactNode } from 'react';

interface TextProps {
  color?: string;
  className?: string;
  fontFamily?: string;
  fontSize?: string;
  fontWeight?: number;
  id?: string;
  inline?: boolean;
  lineHeight?: string;
  onClick?(e?: React.SyntheticEvent): void;
  style?: CSSProperties;
  textDecoration?: string;
  children: ReactNode | string;
}

const resetStyleProps = {
  margin: 0,
  padding: 0,
};

export const Caption: React.FC<TextProps> = ({
  children,
  color,
  id,
  inline,
  lineHeight = '20px',
  onClick,
  style,
  textDecoration,
}) => (
  <div
    id={id}
    onClick={onClick}
    style={{
      ...resetStyleProps,
      color,
      display: inline ? 'inline-block' : 'block',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight,
      textDecoration,
      ...style,
    }}
  >
    {children}
  </div>
);

export const H1: React.FC<TextProps> = ({ children, style }) => (
  <h1
    style={{ ...resetStyleProps, fontSize: '48px', fontWeight: 200, ...style }}
  >
    {children}
  </h1>
);

export const H2: React.FC<TextProps> = ({
  children,
  fontSize = '36px',
  fontWeight = 200,
  style,
}) => (
  <h2 style={{ ...resetStyleProps, fontSize, fontWeight, ...style }}>
    {children}
  </h2>
);

export const H3: React.FC<TextProps> = ({ children, className, style }) => (
  <h3
    className={className}
    style={{ ...resetStyleProps, fontSize: '30px', fontWeight: 300, ...style }}
  >
    {children}
  </h3>
);

export const H4: React.FC<TextProps> = ({ children, color, style }) => (
  <h4
    style={{
      ...resetStyleProps,
      color,
      fontSize: '24px',
      fontWeight: 300,
      ...style,
    }}
  >
    {children}
  </h4>
);

export const P: React.FC<TextProps> = ({ children, color, style }) => (
  <p
    style={{
      ...resetStyleProps,
      color,
      fontSize: '18px',
      lineHeight: '1.8rem',
      ...style,
    }}
  >
    {children}
  </p>
);

export const Text: React.FC<TextProps> = ({
  children,
  className,
  color,
  fontFamily,
  fontSize,
  fontWeight = 400,
  lineHeight,
  style,
}) => (
  <div
    className={className}
    style={{
      ...resetStyleProps,
      color,
      fontFamily,
      fontSize: fontSize || '16px',
      fontWeight,
      lineHeight,
      ...style,
    }}
  >
    {children}
  </div>
);
