// import { Plugins } from '@capacitor/core';
import React, { useEffect } from 'react';
// import { useHistory } from 'react-router-dom';

// const { App } = Plugins;
// const CapApp = App;

export const DeepLink: React.FC<any> = () => {
  // let history = useHistory();

  useEffect(() => {
    // @TODO deep link
    // CapApp.addListener('appUrlOpen', (data: any) => {
    //   const slug = data.url.split('.com').pop();
    //   if (slug) {
    //     history.push(slug);
    //   }
    // });
  }, []); // eslint-disable-line

  return null;
};
