import { groupBy, Dictionary } from 'lodash';

import { Log } from '../context/UserContext';
import moment from 'moment';
import { useActiveRoute } from './useActiveRoute';
import { useMemo } from 'react';

export const useGroupedLogs = () => {
  const { logs: currentLogs } = useActiveRoute();

  const logs: Dictionary<any> = useMemo(
    () =>
      groupBy(currentLogs, (log: Log) =>
        log?.date
          ? moment(log.date).format('YYYY-MM-DD')
          : moment.unix(parseInt(log?.created_at) / 1000).format('YYYY-MM-DD')
      ),
    [currentLogs]
  );
  return [logs];
};
