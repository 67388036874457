// import { Plugins } from '@capacitor/core';
import { IonAlert, IonItem, IonLabel, isPlatform, IonImg } from '@ionic/react';
import { InAppBrowser } from '@ionic-native/in-app-browser';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { find } from 'lodash';

import under_armour from '../../assets/under_armour.svg';
import appleIcon from '../../assets/apple.png';
import fitbitIcon from '../../assets/fitbit.svg';
import { UserContext } from '../../context/UserContext';
import { Route, RouteContext } from '../../context/RouteContext';
import { useBoolean } from '../../hooks/useBoolean';
import constants, { TrackerType } from '../../util/constants';
import { TrackerWizard } from './TrackerWizard';
import { createPopup } from './createPopup';
import { Button } from '../Button/Button';

// const { CapacitorHealthkit } = Plugins;

type Props = {
  disabled: boolean;
  source: TrackerType;
};

export const TrackerSettings: React.FC<Props> = ({ disabled, source }) => {
  const { updateTrackerSettings, user, authorizeThirdParty, fetchCurrentUser } =
    useContext(UserContext);
  const { routes } = useContext(RouteContext);
  const { t } = useTranslation();

  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [alertVisible, showAlert, hideAlert] = useBoolean(false);
  const [settingsModalVisible, showSettingsModal, hideSettingsModal] =
    useBoolean(false);
  const [connected, setConnected] = useState(user?.providers.includes(source));
  const routeFitbit = find(routes, {
    id: user.tracker_settings.fitbit,
  }) as Route;
  const routeHealthKit = find(routes, {
    id: user.tracker_settings.healthkit,
  }) as Route;
  const isConnected = user?.providers.includes(source);

  useEffect(() => {
    (async () => {
      await fetchCurrentUser();
      setConnected(isConnected);
      switch (source) {
        case 'under_armour':
          setName('Under Armour');
          setImage(under_armour);
          break;
        case 'fitbit':
          setName(routeFitbit ? `Fitbit (${routeFitbit.name})` : 'Fitbit');
          setImage(fitbitIcon);
          break;
        case 'healthkit':
          setName(
            routeHealthKit
              ? `Apple HealthKit (${routeHealthKit.name})`
              : 'Apple HealthKit'
          );
          setImage(appleIcon);
          break;
        default:
          return;
      }
    })();
  }, [source, routeFitbit, routeHealthKit, isConnected, fetchCurrentUser]);

  const authorizeTracker = async (): Promise<boolean> => {
    return new Promise((resolve) => {
      let url = `${constants.ENDPOINT}/auth/${source}/`;

      if (isPlatform('cordova')) {
        const browser = InAppBrowser.create(url, '_blank', {
          clearcache: 'yes',
          toolbar: 'yes',
        });
        try {
          browser.on('loadstop').subscribe(async (event) => {
            if (event.url.includes(`/auth/${source}/callback`)) {
              const [isSuccessful] = await browser.executeScript({
                code: `window.result;`,
              });
              if (isSuccessful) {
                await authorizeThirdParty(source, isSuccessful);
                setConnected(true);
                resolve(true);
              } else {
                resolve(false);
              }
              browser.close();
            }
          });
        } catch (e) {
          console.log(e);
        }
      } else {
        // Open a popup in browser
        createPopup(
          url,
          `${t('components.TrackerSettings.Text1')} ${name} ${t(
            'components.TrackerSettings.Text2'
          )}`,
          source,
          async (source: string, data: any) => {
            try {
              await authorizeThirdParty(source, data);
              resolve(true);
            } catch (e) {
              resolve(false);
            }
          }
        );
      }
    });
  };

  return (
    <>
      <IonAlert
        buttons={[
          {
            text: t('common.CANCEL'),
            role: 'cancel',
            handler: hideAlert,
          },
          {
            text: t('components.TrackerSettings.DeleteAndDisconnect'),
            handler: async () => {
              updateTrackerSettings(source, {
                date: null,
                token: null,
              });
            },
          },
          {
            text: t('components.TrackerSettings.SaveAndDisconnect'),
            handler: async () => {
              updateTrackerSettings(source, {
                date: null,
                token: null,
                deleteOnDisconnect: false,
              });
            },
          },
        ]}
        isOpen={alertVisible}
        onDidDismiss={hideAlert}
        header={`${t('components.TrackerSettings.DisconnectingFrom')} ${name}`}
        message={`${t('components.TrackerSettings.DeleteText1')} ${name}. ${t(
          'components.TrackerSettings.DeleteText2'
        )}`}
      />
      <IonItem disabled={disabled}>
        <IonImg slot="start" src={image} style={{ width: '20px' }} />
        <IonLabel>{name}</IonLabel>
        <Button
          // FIXME Toggle is being stupid
          // <IonToggle
          //   checked={
          //     source === 'healthkit'
          //       ? CapacitorHealthkit.isAvailable()
          //       : settingsModalVisible
          //       ? false
          //       : connected
          // }
          // disabled={source === 'healthkit'}
          // IF healthkit then we will always showSettingsModal
          disabled={disabled}
          onClick={connected ? showAlert : showSettingsModal}
          slot="end"
        >
          {/* IF healthkit then we will always display 'Configure' */}
          {connected
            ? t('components.TrackerSettings.Disconnect')
            : t('components.TrackerSettings.Connect')}
        </Button>
        <TrackerWizard
          isOpen={settingsModalVisible}
          onAuthorize={authorizeTracker}
          onDidDismiss={hideSettingsModal}
          source={source}
        />
      </IonItem>
    </>
  );
};
