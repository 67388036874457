import { findIndex, map, upperCase } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { LoginContext, ProviderListItem } from '../../context/LoginContext';
import { Button } from '../Button/Button';

import { Preferences } from '@capacitor/preferences';

type Props = {
  onEmailClick?(): void;
  submitText: string;
};

export const LastLoginMethod: React.FC<Props> = ({
  onEmailClick,
  submitText,
}) => {
  const { providerList, providerLogin } = useContext(LoginContext);
  const [lastLoginMethod, setLastLoginMethod] = useState<ProviderListItem>();
  const { t } = useTranslation();

  useEffect(() => {
    (async () => {
      const { value } = await Preferences.get({
        key: 'auth_method',
      });
      const loginIndex = findIndex(providerList, (p) => p.id === value);
      setLastLoginMethod(
        loginIndex > -1 ? providerList[loginIndex] : providerList[0]
      );
    })();
  }, []); // eslint-disable-line

  return (
    <>
      {lastLoginMethod && (
        <Button
          color="light"
          expand="block"
          onClick={
            lastLoginMethod.id === 'camino'
              ? onEmailClick
              : () => {
                  providerLogin(lastLoginMethod);
                }
          }
          size="small"
        >
          <div style={{ marginRight: '8px', width: '20px' }}>
            {lastLoginMethod.logo}
          </div>
          {`${submitText} with ${
            lastLoginMethod.id === 'camino'
              ? 'Email'
              : t(
                  `pages.login.${upperCase(
                    lastLoginMethod.id.replaceAll(' ', '')
                  )}`
                )
          }`}
        </Button>
      )}
      {map(providerList, ({ id, logo }, key) =>
        lastLoginMethod?.id === id ? null : (
          <Button
            expand="block"
            color="outline"
            key={key}
            onClick={
              id === 'camino'
                ? onEmailClick
                : () => {
                    providerLogin(providerList[key]);
                  }
            }
            size="small"
            style={{ flex: 0 }}
          >
            <div style={{ marginRight: '8px', width: '20px' }}>{logo}</div>
            {`${submitText} with ${
              id === 'camino' ? 'Email' : t(`pages.login.${upperCase(id)}`)
            }`}
          </Button>
        )
      )}
    </>
  );
};
