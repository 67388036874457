import { keys, map, reduce, size, orderBy } from 'lodash';
import React, { useContext } from 'react';

import bootsImage from '../assets/boots.png';
import { MileageForm } from '../components/MileageForm';
import { PageWrapper, PageRefreshCallback } from '../components/PageWrapper';
import { Toolbar } from '../components/Toolbar';
import { UserContext } from '../context/UserContext';

import {
  IonButton,
  IonList,
  IonLabel,
  IonItem,
  IonImg,
  IonIcon,
  isPlatform,
  IonPopover,
} from '@ionic/react';
import { MileageFab } from '../components/MileageFab/MileageFab';
import { useGroupedLogs } from '../hooks/useGroupedLogs';
import moment from 'moment';
import { renderDistance } from '../util/renderDistance';
import { estimatedArrivalDate } from '../util/eta';
import { arrowBackOutline, informationCircle } from 'ionicons/icons';
import { useBoolean } from '../hooks/useBoolean';
import { Link } from '../components/Link/Link';
import { Trans, useTranslation } from 'react-i18next';
import { useActiveRoute } from '../hooks/useActiveRoute';

export function Progress({ match }: any) {
  const { t } = useTranslation();
  const { user, syncLogs } = useContext(UserContext);
  const [alertVisible, showAlert, hideAlert] = useBoolean(false);
  const { route, showPaywall } = useActiveRoute();
  const [logs] = useGroupedLogs();
  const { date } = match.params;
  const { avgDaily, daysLeft, estDate } = estimatedArrivalDate(logs, route);

  const dailyAverage = renderDistance(
    avgDaily,
    user?.user_settings.distance_unit
  );

  const onRefresh: PageRefreshCallback = async (onComplete) => {
    await syncLogs();
    onComplete('Logs updated.');
  };

  return (
    <>
      <IonPopover isOpen={alertVisible} onDidDismiss={hideAlert}>
        <div style={{ padding: '10px 30px' }}>
          <Trans
            i18nKey="pages.progress.STATS_COMPONENT"
            values={{
              dailyAverage,
              daysLeft,
              estimatedArrivalDate: estDate,
            }}
            // eslint-disable-next-line
            components={{ heading: <h4 />, Link: <Link onClick={hideAlert} /> }}
          />
        </div>
      </IonPopover>
      <Toolbar />
      {!date && <MileageFab isOpen={size(logs) === 0} />}
      <PageWrapper onRefresh={onRefresh}>
        {date ? (
          logs[date] !== undefined ? (
            <IonList>
              {logs[date].map((log: any) => (
                <MileageForm log={log} key={log.id} />
              ))}
            </IonList>
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                flexDirection: 'column',
                height: '80vh',
              }}
            >
              <div>
                <IonImg
                  src={bootsImage}
                  style={{ margin: 'auto', opacity: 0.5, width: '50vw' }}
                />
                <h3>{t('pages.progress.GetMoving')}</h3>
                <p>{t('pages.progress.Logs')}</p>
                <IonButton color="dark" routerLink="/dashboard/progress">
                  <IonIcon
                    icon={arrowBackOutline}
                    style={{ color: 'var(--color-primary-500)' }}
                  />
                  &nbsp; {t('pages.progress.Back')}
                </IonButton>
              </div>
            </div>
          )
        ) : (
          <IonList>
            {isPlatform('desktop') ? (
              <h3>{t('pages.progress.YourProgress')}</h3>
            ) : null}
            <IonItem>
              <IonLabel>
                <h3 style={{ display: 'flex', fontWeight: 'bold' }}>
                  {size(logs) === 0 ? (
                    t('pages.progress.STARTLOGGING')
                  ) : daysLeft === 0 ? (
                    t('pages.progress.YOUARRIVED')
                  ) : (
                    <IonLabel>
                      {t('pages.progress.ESTARRIVAL')}
                      <IonIcon
                        color="grey"
                        icon={informationCircle}
                        onClick={showAlert}
                        size="small"
                        slot="end"
                      />
                      <p style={{ fontWeight: 'bold' }}>{estDate}</p>
                    </IonLabel>
                  )}
                </h3>
              </IonLabel>
            </IonItem>
            {showPaywall && (
              <IonItem>
                <p style={{ fontWeight: 'bold' }}>
                  *{t('pages.progress.PAYWALL')}
                </p>
              </IonItem>
            )}
            {map(
              orderBy(
                keys(logs),
                [(date) => moment(date).format('X')],
                ['desc']
              ),
              (date: string, idx) => {
                const logGroup: any[] = logs[date];
                return (
                  <IonItem
                    key={`item-${idx}`}
                    routerLink={`/dashboard/progress/${date}`}
                  >
                    <IonLabel>
                      <h3>{moment(date).format('MMM DD, YYYY')}</h3>
                      <p>
                        {renderDistance(
                          reduce(
                            logGroup,
                            (sum: number, log: any) =>
                              parseFloat(log?.mileage) + sum,
                            0
                          ),
                          user?.user_settings.distance_unit
                        )}
                      </p>
                    </IonLabel>
                  </IonItem>
                );
              }
            )}
          </IonList>
        )}
      </PageWrapper>
    </>
  );
}
