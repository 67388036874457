import { IDestination } from '../context/DestinationContext';

/**
 * Compare a list of all destinations with user mileage, and find the last town the user has reached.
 * If the user has just started out, we place them at the first town.
 */
export const lastReachedTown = (
  destinations: IDestination[],
  mileage: number
) =>
  destinations.reduce((prev, curr) => {
    // Only search for the current town if the user has traveled further than the starting point.
    if (mileage > destinations[0].from_start) {
      // If the current town is greater than users' mileage
      // but previous town is less than or equal to current mileage
      return curr.from_start > mileage && prev.from_start <= mileage
        ? prev
        : curr;
    }
    return {
      ...destinations[0],
    };
  });
