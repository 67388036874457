import {
  IonBadge,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonItemOption,
  IonItemOptions,
  IonItemSliding,
  IonLabel,
  IonSearchbar,
} from '@ionic/react';
import _, { filter, includes } from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { List } from './List';
import { Caption, H2, H4 } from './Text';
import { Group, GroupContext } from '../context/GroupContext';
import { User, UserContext } from '../context/UserContext';
import { Spinner } from './Spinner/Spinner';
import { RouteContext } from '../context/RouteContext';
import { useActiveRoute } from '../hooks/useActiveRoute';
import { helpCircle } from 'ionicons/icons';

export const GroupEntry = ({ group, onLeave, onJoin, onDelete, user }: any) => {
  const { getById } = useContext(RouteContext);
  const [isOwner, setOwner] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (group.owner && group.owner.avatar && user.avatar) {
      setOwner(group.owner.avatar === user.avatar);
    }
  }, [group, user]);

  return (
    <IonItemSliding>
      <IonItemOptions side="start">
        {!isOwner && onLeave && (
          <IonItemOption color="danger" onClick={() => onLeave(group.secret)}>
            {t('components.GroupList.Leave')}
          </IonItemOption>
        )}
        {onJoin && (
          <IonItemOption color="danger" onClick={() => onJoin(group.secret)}>
            {t('components.GroupList.Join')}
          </IonItemOption>
        )}
      </IonItemOptions>
      <IonItem routerLink={`/dashboard/group/${group.secret}/details`}>
        <IonLabel>
          <h3>{group.name}</h3>
          <Caption color="#999">
            {group.users.length}{' '}
            {t('components.GroupList.MEMBER').toLowerCase()}(s) &bull;{' '}
            {group.public
              ? t('components.GroupList.Public')
              : t('components.GroupList.Private')}{' '}
            &bull; {getById(group.route_id).name}
          </Caption>
        </IonLabel>
        <IonBadge slot="end" color="primary">
          {group.users.length}
        </IonBadge>
      </IonItem>
      {isOwner && (
        <IonItemOptions side="end">
          <IonItemOption
            color="danger"
            slot="end"
            onClick={() => onDelete(group.secret)}
          >
            {t('components.GroupList.Delete')}
          </IonItemOption>
        </IonItemOptions>
      )}
    </IonItemSliding>
  );
};

export const GroupList = () => {
  const { getMyGroups, groups, loading, deleteGroup, joinGroup, leaveGroup } =
    useContext(GroupContext);
  const { user = {} as User, setUser } = useContext(UserContext);
  const { route } = useActiveRoute();
  const { t } = useTranslation();

  const onDelete = async (secret: string) => {
    await deleteGroup(secret);

    setUser({
      groups: _.filter(user.groups, (g) => g.secret !== secret),
    });
  };

  const onJoin = async (secret: string) => {
    const group = await joinGroup(secret);

    setUser({
      groups: _.uniqBy([...user.groups, group], 'secret'),
    });
  };

  const onLeave = async (secret: string) => {
    await leaveGroup(secret);

    setUser({
      groups: _.filter(user.groups, (group) => group.secret !== secret),
    });
  };

  const [searchTerm, setSearchTerm] = useState('');
  const [filteredList, setFilteredList] = useState<Group[]>([]);

  const filterList = useCallback(
    (e?: CustomEvent) => {
      const newTerm = e ? e.detail.value : searchTerm;
      const newList =
        newTerm.length > 0
          ? filter(groups, (d) =>
              includes(d.name.toLowerCase(), newTerm.toLowerCase())
            )
          : groups;

      setSearchTerm(newTerm);

      setFilteredList(newList);
    },
    [groups, searchTerm, setFilteredList]
  );

  useEffect(() => setFilteredList(groups), [groups]);
  const userGroups = _.filter(getMyGroups(user.groups, user), {
    route_id: route.id,
  });

  return loading ? (
    <Spinner />
  ) : (
    <>
      <List>
        {groups.length === 0 && user.groups.length === 0 && (
          <div style={{ textAlign: 'center', paddingTop: '40px' }}>
            <H2>{t('components.GroupList.NoGroupsText1')}</H2>
            <br />
            <H4>{t('components.GroupList.NoGroupsText2')}</H4>
          </div>
        )}
        {user.groups?.length > 0 && (
          <>
            <IonItemDivider>
              <IonLabel>{t('components.GroupList.MY_GROUPS')} </IonLabel>
            </IonItemDivider>
            <IonItem
              routerLink={`/dashboard/routes`}
              detailIcon="chevron-forward"
            >
              <IonIcon color="primary" icon={helpCircle} slot="end" />
              <IonLabel color="primary">
                Not seeing your group? Try switching your route
              </IonLabel>
            </IonItem>
            {_.map(
              userGroups,
              (group) =>
                group && (
                  <GroupEntry
                    key={group?.secret}
                    group={group}
                    onDelete={onDelete}
                    onLeave={onLeave}
                    user={user}
                  />
                )
            )}
          </>
        )}
        {groups?.length > 0 && (
          <>
            <br />
            <IonItemDivider>
              {t('components.GroupList.ALL_GROUPS')}
            </IonItemDivider>
            <IonSearchbar onIonChange={filterList} value={searchTerm} />{' '}
            {_.map(filteredList, (group) => (
              <GroupEntry
                key={group.secret}
                group={group}
                onDelete={onDelete}
                onJoin={onJoin}
                user={user}
              />
            ))}
          </>
        )}
      </List>
    </>
  );
};
