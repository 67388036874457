import { IonIcon } from '@ionic/react';
import { checkmarkCircleOutline, chevronForwardOutline } from 'ionicons/icons';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Route, RouteSettings } from '../../context/RouteContext';
import { Button } from '../Button/Button';
import { UserContext } from '../../context/UserContext';

type Props = {
  active?: boolean;
  isPaid: boolean; // User has purchased this route
  onPurchase?(route: Route): void;
  onSelect?(route: Route): void;
  showButtons: boolean;
  route: Route;
  settings: RouteSettings;
};

export const RouteCardFooter: React.FC<Props> = ({
  active,
  isPaid = false,
  onPurchase = () => {},
  onSelect = () => {},
  showButtons,
  route,
  settings = { logs: [] },
}) => {
  const { t } = useTranslation();
  const { user } = useContext(UserContext);

  const isSubscriberOrMember = user.plan === 'monthly' || user.plan === 'team';
  const lng = t('lng');

  return (
    <>
      {active && showButtons && (
        <div
          style={{
            alignItems: 'center',
            backgroundColor: '#1e2023',
            color: 'white',
            display: 'flex',
            padding: '10px 20px',
          }}
        >
          <IonIcon icon={checkmarkCircleOutline} /> &nbsp;
          <strong>{t('pages.routes.WALKING')}</strong>
        </div>
      )}
      <div style={{ backgroundColor: 'white', padding: '20px' }}>
        <div>{(route.description as any)[lng]}</div>

        {showButtons ? (
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between',
              marginTop: '10px',
            }}
          >
            {!isPaid && (
              <Button onClick={() => onPurchase(route)}>
                {t('pages.routes.BUY_ROUTE')}
              </Button>
            )}
            {active ? (
              <Button color="light" disabled onClick={() => onSelect(route)}>
                <IonIcon color="success" icon={checkmarkCircleOutline} /> &nbsp;
                {t('common.SELECTED')}
              </Button>
            ) : isSubscriberOrMember ? (
              <Button color="light" onClick={() => onSelect(route)}>
                {t('pages.routes.CONTINUE')} {route.name}
              </Button>
            ) : settings.logs.length || isPaid ? (
              <Button color="light" onClick={() => onSelect(route)}>
                {t('common.SELECT')} &nbsp;
                <IonIcon color="medium" icon={chevronForwardOutline} />
              </Button>
            ) : (
              <Button color="light" onClick={() => onSelect(route)}>
                {t('pages.routes.START_FREE_TRIAL')}
              </Button>
            )}
          </div>
        ) : null}
      </div>
    </>
  );
};
