import { IonToast } from '@ionic/react';
import React from 'react';

type Props = {
  children: string;
  isOpen: boolean;
  onDidDismiss(): void;
};

export const Toast: React.FC<Props> = ({ children, isOpen, onDidDismiss }) => (
  <IonToast
    color="dark"
    duration={3000}
    isOpen={isOpen}
    onDidDismiss={onDidDismiss}
    message={children}
    position="bottom"
  />
);
