import { IonIcon, IonInput, IonItem, isPlatform } from '@ionic/react';
import { eyeOffOutline, eyeOutline } from 'ionicons/icons';
import React, { useState } from 'react';

import { Button } from '../Button/Button';
import { LastLoginMethod } from '../LastLoginMethod/LastLoginMethod';
import { Text } from '../Text';
import { regexValidateEmail } from '../../util/isEmailValid';

// N.B. Google error message is scaring users, so we'll replace with a more gentle message.
const GoogleErrorMessage =
  'There is no user record corresponding to this identifier. The user may have been deleted.';

type Props = {
  onSubmit(config: { email: string; password: string }): void;
  submitText?: string;
};

export const LoginForm: React.FC<Props> = ({
  onSubmit,
  submitText = 'Login',
}) => {
  const [isPasswordVisible, setPasswordVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [msg, setMsg] = useState('');
  const [password, setPassword] = useState('');
  const [showForm, setShowForm] = useState(false);

  const submit = async () => {
    try {
      await onSubmit({ email, password });
    } catch (e: any) {
      setMsg(e.message === GoogleErrorMessage ? 'Login error' : e.message);
    }
  };

  return (
    <form
      onSubmit={(e: any) => {
        e.preventDefault();
        submit();
      }}
    >
      {msg && (
        <IonItem lines="none">
          <Text color="var(--ion-color-danger)">{msg}</Text>
        </IonItem>
      )}
      {showForm ? (
        <>
          <IonItem>
            <IonInput
              label="Email"
              labelPlacement="floating"
              autoFocus={true}
              onIonChange={(e: any) =>
                setEmail((e.target as unknown as HTMLInputElement).value)
              }
              type="email"
              pattern={regexValidateEmail.toString()}
            />
          </IonItem>
          <IonItem>
            <IonInput
              label="Password"
              labelPlacement="floating"
              onIonChange={(e: any) =>
                setPassword((e.target as unknown as HTMLInputElement).value)
              }
              type={isPasswordVisible ? 'text' : 'password'}
            />
            <IonIcon
              size="small"
              style={{ alignSelf: 'center' }}
              slot="end"
              icon={isPasswordVisible ? eyeOutline : eyeOffOutline}
              onClick={() => setPasswordVisible(!isPasswordVisible)}
            />
          </IonItem>
          <div
            style={{
              display: 'grid',
              gridGap: '10px',
              gridTemplateColumns: '1fr 0fr 0fr',
              marginTop: '10px',
              textAlign: 'right',
            }}
          >
            <div />
            <Button color="light" onClick={() => setShowForm(false)}>
              Cancel
            </Button>
            <Button
              style={
                isPlatform('desktop') ? { flex: 1 } : { alignSelf: 'normal' }
              }
              type="submit"
            >
              {submitText}
            </Button>
          </div>
        </>
      ) : (
        <LastLoginMethod
          onEmailClick={() => setShowForm(true)}
          submitText={submitText}
        />
      )}
    </form>
  );
};
