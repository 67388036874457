import React, { CSSProperties } from 'react';
import { VictoryContainer, VictoryLabel, VictoryPie } from 'victory';

type ChartStyles = {
  data?: CSSProperties;
  labels?: CSSProperties;
  parent?: CSSProperties;
};

type Slice = {
  label?: string;
  y: number;
};

interface Props {
  // Data source for chart
  chartData: Slice[];

  // The user's current mileage
  currentMileage?: number;

  // If true, hide labels on top of slices. Requires 'label' to be undefiend on Slice.
  hasHiddenLabels?: boolean;

  // The height of the chart
  height?: number;

  // Number of pixels for center hole
  innerRadius?: number;

  // If true, fill space of parent container. If false, size is static.
  isResponsive?: boolean;

  // Number of pixels to offset labels from slices
  labelRadius?: number;

  // Text label
  text: string;

  // Colors for each slice
  sliceColors?: string[];

  // Styles to apply to data, labels, or parent
  style?: ChartStyles;

  // The width of the chart
  width?: number;
}

export const percentOfCompletion = (chartData: Slice[]) => {
  const absolutePercentage = chartData[0].y / (chartData[0].y + chartData[1].y);
  const isFinished = chartData[1].y === 0;
  return `${isFinished ? 100 : Math.floor(absolutePercentage * 1000) / 10}%`;
};

export const DonutChart = ({
  chartData,
  hasHiddenLabels,
  height = 300,
  innerRadius,
  isResponsive,
  text,
  sliceColors,
  width = 300,
}: Props) => {
  return (
    <VictoryContainer
      height={height}
      responsive={isResponsive}
      width={width}
      style={{ height: 'auto' }}
    >
      <VictoryPie
        animate={{ duration: 500 }}
        colorScale={sliceColors}
        containerComponent={undefined}
        data={chartData}
        innerRadius={innerRadius || height * 0.4}
        standalone={false}
        padding={0}
        height={height}
        width={width}
        {...(hasHiddenLabels ? { labels: () => null } : {})}
      />
      <VictoryLabel
        textAnchor="middle"
        text={percentOfCompletion(chartData)}
        // @TODO: Theme all charts
        style={{
          fill: 'var(--color-secondary-100)',
          fontFamily: 'var(--font-family)',
          fontSize: `${height * 0.16}px`,
          fontWeight: 200,
        }}
        x={width / 2}
        y={height / 2.2}
      />
      <VictoryLabel
        textAnchor="middle"
        text={text}
        // @TODO: Theme all charts
        style={{
          fill: 'var(--color-secondary-300)',
          fontFamily: 'var(--font-family)',
          fontSize: `${height * 0.06}px`,
        }}
        x={width / 2}
        y={height / 1.7}
      />
    </VictoryContainer>
  );
};
