import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserContext } from '../../context/UserContext';
import { oneSignalUtil } from '../../util/oneSignal';

import { ToggleItem } from './ToggleItem';
import { isPlatform, IonRouterLink, IonItem } from '@ionic/react';

export const WebNotifications = () => {
  const { user } = useContext(UserContext);

  const [
    { hasPermission, loading, notificationsEnabled },
    setNotificationStatus,
  ] = useState({
    hasPermission: false,
    loading: true,
    notificationsEnabled: false,
  });
  const { t } = useTranslation();

  // Check one signal util for current status.
  useEffect(() => {
    (async () => {
      const { hasPermission, isSubscribed } = await oneSignalUtil.status();
      setNotificationStatus((s) => ({
        loading: false,
        hasPermission,
        notificationsEnabled: isSubscribed,
      }));
    })();
  }, [setNotificationStatus]);

  const toggleNotifications = () => {
    oneSignalUtil.setSubscription(!notificationsEnabled);
    oneSignalUtil.setUser(user);
    setNotificationStatus((s) => ({
      ...s,
      notificationsEnabled: !notificationsEnabled,
    }));
  };

  return !isPlatform('cordova') && isPlatform('ios') ? null : (
    <>
      <ToggleItem
        checked={notificationsEnabled}
        loading={loading}
        label={t('components.WebNotifications.Notifications')}
        onToggle={toggleNotifications}
        lines="none"
      />
      {!loading && !hasPermission && (
        <IonItem>
          <IonRouterLink
            color="primary"
            href="https://caminoforgood.com/support#notifications"
            target="blank"
          >
            {t('components.WebNotifications.NotificationsBlocked')}
          </IonRouterLink>
        </IonItem>
      )}
    </>
  );
};
