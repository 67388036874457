import { sortBy } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Map as MapComponent } from '../../components/Map/Map';
import { UserAvatarSlides } from '../../components/UserAvatarSlides/UserAvatarSlides';
import { Group } from '../../context/GroupContext';
import { PathContext, Waypoint } from '../../context/PathContext';
import { Route } from '../../context/RouteContext';
import { PublicUser, User, UserContext } from '../../context/UserContext';

interface Props {
  group: Group;
  route: Route;
  user: User;
}
export const Map = ({ group, route, user }: Props) => {
  const { getWaypoints } = useContext(PathContext);
  const { getRouteSettings } = useContext(UserContext);
  const sortedUsers = useMemo(() => {
    return sortBy(
      group.users,
      (user) => getRouteSettings(route, user).mileage
    ).reverse();
  }, [getRouteSettings, group.users, route]);

  const [waypoints, setWaypoints] = useState<Waypoint[]>([]);
  useEffect(() => {
    (async () => {
      setWaypoints(await getWaypoints(route));
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div style={{ height: '100%' }}>
      <div style={{ padding: '10px' }}>
        <UserAvatarSlides users={sortedUsers} />
      </div>

      <MapComponent
        activeUserId={user?.id}
        center={{ lat: 42.32916, lng: -4.80366 }}
        controls={{ zoomControl: true }}
        paths={[{ color: '#e9b20a', dashed: true, path: waypoints }]}
        route={route}
        users={group.users as PublicUser[]}
        zoom={6}
      />
    </div>
  );
};
