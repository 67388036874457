import { filter, keys, last, reduce, size, orderBy, sumBy } from 'lodash';
import { Log } from '../context/UserContext';
import moment from 'moment';
import { Route } from '../context/RouteContext';

export type LogDictionary = { [key: string]: Log[] };

export function estimatedArrivalDate(logs: LogDictionary, route: Route) {
  let orderedLogs = orderBy(
    keys(logs),
    [(date) => moment(date).format('X')],
    ['asc']
  );
  // returns only logs from the previous 7 days of the user's most recent log
  const filteredLogs = filter(orderedLogs, (log) => {
    return moment(last(orderedLogs)).diff(log, 'days') < 7;
  });
  // adds together distances from all logs in a time frame
  const reduceAndAdd = (filteredLogs: string[]) =>
    sumBy(filteredLogs, (date) =>
      reduce(logs[date], (sum: number, log: Log) => log.mileage + sum, 0)
    );

  // get distance travelled total and distance travelled from filteredLogs
  const totalDistance = reduceAndAdd(orderedLogs);
  const filteredDistance = reduceAndAdd(filteredLogs);

  // returns daily average based on filtered distance over how many of the filtered days DO have logs. Does not count days that do not have logs.
  const dailyAverage = filteredDistance / size(filteredLogs);
  // uses total distance left over daily average to determine days left
  const daysLeft =
    totalDistance >= route.meta.distance
      ? 0
      : size(filteredLogs) > 0
      ? Math.ceil((route.meta.distance - totalDistance) / dailyAverage)
      : null;

  // returns no average if no recent logs, grabs date based on days left from today
  return {
    avgDaily: size(filteredLogs) > 0 ? dailyAverage : 0,
    daysLeft,
    estDate:
      daysLeft !== null
        ? daysLeft > 0
          ? moment().add(daysLeft, 'days').format('MMM D, YYYY')
          : moment().format('MMM D, YYYY')
        : null,
  };
}
