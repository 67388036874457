import { IonRouterOutlet, IonSplitPane, isPlatform } from '@ionic/react';
import { Redirect, Route, Switch } from 'react-router-dom';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Challenge } from './Challenge';
import { Destination } from './Destination';
import { Destinations } from './Destinations';
import { GroupPage } from './Group';
import { Groups } from './Groups';
import { Home } from './Home';
import { Passport } from './Passport';
import { Progress } from './Progress';
import { Redeem } from './Redeem';
import { Settings } from './Settings';
import { Map } from './Map';
import { Sidebar } from '../components/Sidebar/Sidebar';
import { GroupProvider } from '../context/GroupContext';
import { NavigationProvider } from '../context/NavigationContext';
import { User } from './User';

import { useIonRouter } from '@ionic/react';
import { Plugins } from '@capacitor/core';
import { Routes } from './Routes';

export const Dashboard = ({ match, ...rest }: any) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const ionRouter = useIonRouter();
  const toggle = () => {
    setIsOpen((o) => !o);
  };

  if (isPlatform('android')) {
    //TODO: could not resolve ts error so I added 'any'
    document.addEventListener('ionBackButton', (ev: any) => {
      ev.detail.register(-1, () => {
        if (!ionRouter.canGoBack()) {
          Plugins.App.exitApp();
        }
      });
    });
  }

  return (
    <NavigationProvider>
      <GroupProvider>
        <IonSplitPane contentId="main">
          <Sidebar onToggle={toggle} open />
          {/* ionPage prop available on @see https://github.com/ionic-team/ionic-framework/issues/20219*/}
          <IonRouterOutlet
            id="main"
            ionPage
            style={{ top: isOpen ? '1px' : '0px' }}
          >
            <Switch>
              {/* TODO: Route set up, but commented out until group_name exists  */}
              <Route path={`${match.path}/dest/:id`} component={Destination} />
              <Route
                path={`${match.path}/destinations`}
                component={Destinations}
              />
              <Route path={`${match.path}/challenge`} component={Challenge} />
              <Route path={`${match.path}/groups`} component={Groups} />
              <Redirect
                exact
                from={`${match.path}/group/:id`}
                to={`${match.path}/group/:id/details`}
              />

              <Route
                path={`${match.path}/group/:id/details`}
                component={GroupPage}
              />
              <Route
                path={`${match.path}/group/:id/map`}
                component={GroupPage}
              />
              <Route
                path={`${match.path}/group/:id/messages`}
                component={GroupPage}
              />
              <Route path={`${match.path}/home`} component={Home} />
              <Route path={`${match.path}/map`} component={Map} />
              <Route path={`${match.path}/map/:mileage`} component={Map} />
              <Route path={`${match.path}/passport`} component={Passport} />
              <Route
                exact
                path={`${match.path}/progress`}
                component={Progress}
              />
              <Route
                path={`${match.path}/progress/:date`}
                component={Progress}
              />
              <Route path={`${match.path}/routes`} component={Routes} />
              <Route path={`${match.path}/redeem/:id`} component={Redeem} />

              <Route path={`${match.path}/settings`} component={Settings} />
              <Route path={`${match.path}/user/:id`} component={User} />
              <Route
                path="/unauthorized"
                component={() => (
                  <>{t('Errors.Unauthorized', 'Unauthorized')}</>
                )}
              />
              <Route
                render={() => {
                  window.location.href = '/dashboard/home';
                  return null;
                }}
              />
            </Switch>
          </IonRouterOutlet>
        </IonSplitPane>
      </GroupProvider>
    </NavigationProvider>
  );
};
