import { ApolloProvider } from '@apollo/react-hooks';
import ApolloClient, { InMemoryCache } from 'apollo-boost';
import { createRoot } from 'react-dom/client';
import App from './App';
import { LoginProvider } from './context/LoginContext';
import { NotificationProvider } from './context/NotificationContext';
import { RouteProvider } from './context/RouteContext';
import { UserProvider } from './context/UserContext';
import { getStorage } from './hooks/useLocalStorage';
import i18n from './i18n';
import constants from './util/constants';
import { IonReactRouter } from '@ionic/react-router';
import { IonApp } from '@ionic/react';

const endpoint = constants.ENDPOINT;
const root = createRoot(document.getElementById('root')!);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  //@ts-ignore
  defaultFetchPolicy: 'no-cache',
  //@ts-ignore
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  },
  uri: `${endpoint}/graphql?v=${process.env.REACT_APP_VERSION}`,
  request: async (operation) => {
    const token = await getStorage('token');
    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
        'X-App-Version': process.env.REACT_APP_VERSION,
      },
    });
  },
});
client.defaultOptions.query = {
  errorPolicy: 'all',
  fetchPolicy: 'no-cache',
};
client.defaultOptions.mutate = {
  errorPolicy: 'all',
  fetchPolicy: 'no-cache',
};
client.defaultOptions.watchQuery = {
  errorPolicy: 'all',
  fetchPolicy: 'no-cache',
};

i18n
  .init({
    lng: 'en',
    fallbackLng: 'en',
    debug: process.env.NODE_ENV !== 'production',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
  })
  .then(() => {
    root.render(
      <IonApp>
        <IonReactRouter>
          <ApolloProvider client={client}>
            <UserProvider>
              <LoginProvider>
                <NotificationProvider>
                  <RouteProvider>
                    <App />
                  </RouteProvider>
                </NotificationProvider>
              </LoginProvider>
            </UserProvider>
          </ApolloProvider>
        </IonReactRouter>
      </IonApp>
    );
  });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
