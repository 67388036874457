import React, { useContext } from 'react';
import { Trans } from 'react-i18next';
import { PaymentModal } from '../components/PaymentModal/PaymentModal';
import { UserContext } from '../context/UserContext';
import { useActiveRoute } from '../hooks/useActiveRoute';

import { Login } from './Login';

export const Upgrade = () => {
  const { loggedIn } = useContext(UserContext);
  const { route } = useActiveRoute();
  return (
    <Login
      message={
        <>
          <Trans
            i18nKey="pages.upgrade.JOIN_US"
            components={{
              highlight: (
                <div
                  style={{
                    color: 'var(--color-secondary-500)',
                    margin: '20px 0 10px',
                  }}
                />
              ),
            }}
          />
          <PaymentModal
            onClose={() => (window.location.pathname = '/')}
            isOpen={!!loggedIn}
            product={route.product}
          />
        </>
      }
    />
  );
};
