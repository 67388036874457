import { groupBy, map } from 'lodash';
import { IDestination } from '../context/DestinationContext';
import { RouteSettings } from '../context/RouteContext';
import { PublicUser } from '../context/UserContext';
import { lastReachedTown } from './reachedLocations';

/**
 * Returns a list of users grouped by the last destination they have arrived at.
 * e.g.
 * {
 *   'Town 1': [{ first_name: 'Alice'}, {first_name: 'Bob'}],
 *   'Town 2': [{ first_name: 'Cat'}, {first_name: 'Doug'}]
 * }
 * @param destinations A list of destinations
 * @param getRouteSettings A helper function to return route settings
 * @param users A list of users
 * @returns Dictionary<PublicUser[]>
 */
export const groupUsersByTown = ({
  destinations,
  getRouteSettings,
  users,
}: {
  destinations: IDestination[];
  getRouteSettings(user: PublicUser): RouteSettings;
  users: PublicUser[];
}) =>
  groupBy(
    map(users, (user) => ({
      ...user,
      town: lastReachedTown(destinations, getRouteSettings(user).mileage || 0),
    })),
    'town.name'
  );
