import {
  IonBadge,
  IonItem,
  IonIcon,
  IonMenuToggle,
  IonLabel,
} from '@ionic/react';
import { add } from 'ionicons/icons';
import React from 'react';

const isDisabled = (path: string) => path === window.location.pathname;

export const SidebarItem = ({
  color = 'light',
  badgeValue = undefined,
  icon,
  link,
  name,
  href,
  target,
  onClick = undefined,
}: any) => (
  <IonMenuToggle auto-hide="false">
    <IonItem
      button
      style={{
        cursor: 'pointer',
        pointerEvents: 'initial',
      }}
      disabled={isDisabled(link)}
      color={color}
      detail={false}
      routerLink={link}
      // href={href}
      target={target}
      {...(onClick !== undefined ? { onClick } : {})}
    >
      <IonIcon
        style={{ color: 'var(--color-primary-500)' }}
        icon={icon || add}
        size="small"
        slot="start"
      />
      <IonLabel
        style={{
          pointerEvents: 'initial',
        }}
      >
        {name}
        {badgeValue && (
          <IonBadge
            style={{
              // @ts-ignore
              '--background': 'var(--ion-color-danger)',
              '--color': 'white',
            }}
            slot="end"
          >
            {badgeValue}
          </IonBadge>
        )}
      </IonLabel>
    </IonItem>
  </IonMenuToggle>
);
