import { chunk, differenceWith, isEmpty, map, orderBy } from 'lodash';
import React, {
  useCallback,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import {
  IonRow,
  IonCol,
  IonRouterLink,
  isPlatform,
  IonGrid,
  IonIcon,
} from '@ionic/react';
import {
  informationCircle,
  arrowForwardCircleOutline,
  arrowBackCircleOutline,
} from 'ionicons/icons';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

import { Text } from '../components/Text';
import passportBackground from '../assets/passport_bg.jpg';
import passportLogo from '../assets/passport_logo.jpg';
import { DestinationContext } from '../context/DestinationContext';
import { UserContext } from '../context/UserContext';
import { PageWrapper } from '../components/PageWrapper';
import { Caption } from '../components/Text';
import { Toolbar } from '../components/Toolbar';
import { Badge, BadgeConfig } from '../components/Badge/Badge';
import { renderDistance } from '../util/renderDistance';
import { PassportInfoModal } from '../components/PassportInfoModal';
import { useTranslation } from 'react-i18next';
import { useActiveRoute } from '../hooks/useActiveRoute';

export const Passport: React.FC = () => {
  const { t } = useTranslation();

  const { findDestination, getUnlockedDestinations } =
    useContext(DestinationContext);
  const { user } = useContext(UserContext);
  const { mileage, route, stamps } = useActiveRoute();
  const [openModal, setOpenModal] = useState(false);
  const [stampPages, setStampPages] = useState<BadgeConfig[][]>([]);
  const _passportRef = useRef<any>(null);
  const [isBeginning, setIsBeginning] = useState<boolean>(true);
  const [isEnd, setIsEnd] = useState<boolean>(true);

  const styles = {
    colStyle: isPlatform('desktop')
      ? { display: 'flex', flexDirection: 'column', alignItems: 'center' }
      : {},
  };

  const areStampsAvailable = !isEmpty(
    differenceWith(
      getUnlockedDestinations(mileage),
      stamps,
      (dest, stamp) => stamp === dest.id
    )
  );

  const updateNavArrows = useCallback(() => {
    if (_passportRef && _passportRef.current) {
      const swiperInstance = _passportRef.current.swiper;
      setIsBeginning(swiperInstance.isBeginning);
      setIsEnd(swiperInstance.isEnd);
    }
  }, [_passportRef]);

  const nextSlide = () => {
    if (_passportRef.current) {
      _passportRef.current.swiper.slideNext();
      setIsBeginning(false);
      updateNavArrows();
    }
  };

  const prevSlide = () => {
    if (_passportRef.current) {
      _passportRef.current.swiper.slidePrev();
      setIsEnd(false);
      updateNavArrows();
    }
  };

  useEffect(() => {
    const destinations: BadgeConfig[] = [];

    updateNavArrows();

    stamps.forEach((id: any) => {
      const destination = findDestination({ id });
      destination && destinations.push(destination);
    });

    route.meta.milestones.forEach((markerString: string) => {
      const marker = parseInt(markerString, 10);
      if (mileage > marker) {
        destinations.push({
          id: `badge-${marker}`,
          from_start: marker,
        });
      }
    });

    setStampPages(
      chunk(
        orderBy(destinations, 'from_start', 'asc'),
        isPlatform('desktop') ? 12 : 4
      )
    );
  }, [
    findDestination,
    user,
    stamps,
    route.meta.milestones,
    mileage,
    updateNavArrows,
  ]);

  return (
    <>
      {openModal && <PassportInfoModal onClose={() => setOpenModal(false)} />}
      <Toolbar />
      <PageWrapper
        gridStyle={{
          backgroundImage: `url(${passportBackground})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: isPlatform('desktop') ? 'center' : 'left top',
          height: '100%',
          width: '100%',
        }}
      >
        <div style={{ position: 'absolute', top: '1rem', right: '1rem' }}>
          <IonIcon
            onClick={prevSlide}
            style={{ opacity: isBeginning ? 0.5 : 1 }}
            size="large"
            icon={arrowBackCircleOutline}
          />
          <IonIcon
            style={{ opacity: isEnd ? 0.5 : 1 }}
            onClick={nextSlide}
            size="large"
            icon={arrowForwardCircleOutline}
          />
          <IonIcon
            size="large"
            onClick={() => setOpenModal(true)}
            icon={informationCircle}
          />
        </div>
        <IonRow>
          <IonCol size="6" sizeLg="3">
            <img
              alt="Credencial del Peregrino"
              src={passportLogo}
              style={{ height: '100px' }}
            />
          </IonCol>
        </IonRow>
        {areStampsAvailable && (
          <div
            style={{ lineHeight: '20px', maxWidth: '375px', padding: '5px' }}
          >
            <Text
              style={{
                color: 'var(--color-secondary-700)',
                fontFamily: 'var(--font-stamp)',
              }}
            >
              {t('pages.passport.Unlock')}
              <IonRouterLink
                style={{ textDecorationLine: 'underline' }}
                routerLink={`/dashboard/destinations`}
              >
                {t('pages.passport.Destinations')}
              </IonRouterLink>
              {t('pages.passport.Unclaimed')}
            </Text>
          </div>
        )}
        {stamps.length > 0 ? (
          <Swiper
            ref={_passportRef}
            onSlideChange={updateNavArrows}
            modules={[Navigation]}
            navigation={{
              prevEl: '.prev-slide-button',
              nextEl: '.next-slide-button',
            }}
          >
            {map(stampPages, (destinationsOnPage, idx) => (
              <SwiperSlide key={`slide-${idx}`}>
                <IonGrid>
                  <IonRow>
                    {destinationsOnPage.map(
                      (destination: BadgeConfig, idx: number) => {
                        return (
                          destination && (
                            <IonCol
                              className="passport-stamp"
                              key={destination.id || idx}
                              size="6"
                              sizeLg="3"
                              // @ts-ignore
                              style={{
                                ...styles.colStyle,
                                padding: 0,
                              }}
                            >
                              {destination.name ? (
                                <>
                                  <IonRouterLink
                                    routerLink={`/dashboard/dest/${destination.id}`}
                                  >
                                    <Badge
                                      destinationName={destination.name}
                                      distance={destination.from_start}
                                      name={destination.name}
                                      size={150}
                                    />
                                  </IonRouterLink>
                                  <Caption color="var(--color-secondary-400)">
                                    {renderDistance(
                                      destination.from_start,
                                      user?.user_settings.distance_unit
                                    )}
                                  </Caption>
                                </>
                              ) : (
                                <Badge
                                  distance={destination.from_start}
                                  size={150}
                                />
                              )}
                            </IonCol>
                          )
                        );
                      }
                    )}
                  </IonRow>
                </IonGrid>
              </SwiperSlide>
            ))}
          </Swiper>
        ) : (
          !areStampsAvailable && (
            <>
              <div style={{ width: '50%', padding: '5px' }}>
                <Text
                  style={{
                    color: 'var(--color-secondary-700)',
                    fontFamily: 'var(--font-stamp)',
                  }}
                >
                  {t('pages.passport.Log')}
                </Text>
              </div>
              <PassportInfoModal />
            </>
          )
        )}
      </PageWrapper>
    </>
  );
};
