import { IonButton, IonInput } from '@ionic/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { usePayment } from '../../hooks/usePayment';
import { Caption } from '../Text';

/* @FIXME Type should come from Stripe? */
export type Coupon = {
  amount_off: string;
  id: string;
  code: string;
  percent_off: string;
};

export type PromotionCode = {
  id: string;
  code: string;
  coupon: Coupon;
};

interface Props {
  isLoggedIn: boolean;
  onCancel(): void;
  onSuccess(code?: PromotionCode): void;
}

export const DiscountForm = ({ isLoggedIn, onCancel, onSuccess }: Props) => {
  const [code, setCode] = useState<string>();
  const [discountError, setDisError] = useState();
  const { validateCoupon } = usePayment(isLoggedIn);
  const { t } = useTranslation();

  const handleSubmit = async () => {
    if (code) {
      try {
        const result = await validateCoupon(code);
        if (result.error) {
          // @TODO this should really be removed but might affect old apps
          throw result.error;
        }
        onSuccess && onSuccess(result);
      } catch (e: any) {
        setDisError(e.message || e);
      }
    }
  };

  return (
    <div style={{ marginBottom: '5px' }}>
      <IonInput
        onIonChange={(e: any) => setCode(e.target.value)}
        placeholder="Coupon Code"
        style={{
          border: discountError
            ? '1px solid var(--ion-color-danger)'
            : '1px solid var(--color-primary-300)',
          borderRadius: '8px',
          width: '100%',
          // @ts-ignore
          '--padding-end': '16px',
          '--padding-start': '16px',
        }}
        value={code}
      />
      <br />
      {discountError && (
        <>
          <Caption color="var(--ion-color-danger)">{discountError}</Caption>
          <br />
        </>
      )}
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <IonButton color="light" onClick={onCancel}>
          {t('common.CANCEL')}
        </IonButton>
        <IonButton onClick={handleSubmit}>{t('common.APPLY')}</IonButton>
      </div>
    </div>
  );
};
