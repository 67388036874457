import { isString } from 'lodash';
import React, { createContext, useContext, useState } from 'react';
import { Toast } from '../components/Toast/Toast';

type Notification = {
  message: string;
};

interface INotificationContext {
  notify(message: Notification): void;
}

export const NotificationContext = createContext<INotificationContext>(
  {} as INotificationContext
);

export const NotificationProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [list, setList] = useState<Notification[]>([]);

  const notify = (newItem: Notification) => setList([...list, newItem]);

  return (
    <NotificationContext.Provider value={{ notify }}>
      {children}
      {list.length > 0 && (
        <Toast isOpen onDidDismiss={() => setList(list.slice(1))}>
          {list[0].message}
        </Toast>
      )}
    </NotificationContext.Provider>
  );
};

export function useNotification() {
  const { notify } = useContext(NotificationContext);

  return {
    notify: (message: Notification | string) =>
      notify(isString(message) ? { message } : message),
  };
}
