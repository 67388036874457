import { RefresherEventDetail } from '@ionic/core/components';
import {
  IonContent,
  IonGrid,
  IonRefresher,
  IonRefresherContent,
} from '@ionic/react';
import React, { CSSProperties, useCallback } from 'react';

import { Spinner } from './Spinner/Spinner';
import { useNotification } from '../context/NotificationContext';

export type PageRefreshCallback = (
  onComplete: (msg?: string) => void,
  e: CustomEvent<RefresherEventDetail>
) => void;

type Props = {
  children: React.ReactNode;
  gridStyle?: CSSProperties;
  loading?: boolean;
  onRefresh?: PageRefreshCallback;
  padding?: number | string;
  style?: CSSProperties;
};

/**
 * @description
 * The PageWrapper component provides consistent spacing and layout for pages.
 * It includes optional pull-to-refresh functionality.
 */
export const PageWrapper: React.FC<Props> = ({
  children,
  gridStyle,
  loading = false,
  onRefresh,
  padding = '10px',
  style = {},
}) => {
  const { notify } = useNotification();

  const handleRefresh = useCallback(
    (event: CustomEvent<RefresherEventDetail>) => {
      if (onRefresh) {
        onRefresh((msg?: string) => {
          if (msg) notify(msg);
          event.detail.complete();
        }, event);
      } else {
        event.detail.complete();
      }
    },
    [notify, onRefresh]
  );

  return (
    <IonContent
      style={{
        // @ts-ignore
        '--padding-bottom': '0px',
        '--padding-start': '0px',
        '--padding-end': '0px',
        '--padding-top': '0px',
        ...style,
      }}
    >
      {loading ? (
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
          }}
        >
          <Spinner />
        </div>
      ) : (
        <>
          {onRefresh && (
            <IonRefresher
              slot="fixed"
              pullFactor={0.75}
              onIonRefresh={handleRefresh}
            >
              <IonRefresherContent
                pullingText="Pull to refresh"
                refreshingSpinner="crescent"
              />
            </IonRefresher>
          )}
          <IonGrid
            className="grid-wrapper"
            fixed
            style={{ padding, ...gridStyle }}
          >
            {children}
          </IonGrid>
        </>
      )}
    </IonContent>
  );
};
