import {
  IonContent,
  IonFab,
  IonFabButton,
  IonModal,
  IonIcon,
  IonRouterLink,
  isPlatform,
} from '@ionic/react';
import { last, map } from 'lodash';
import React, { useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { P } from '../Text';
import { UserContext } from '../../context/UserContext';
import { DestinationContext } from '../../context/DestinationContext';
import { Product, ProductType, RouteContext } from '../../context/RouteContext';
import { PaymentForm } from '../PaymentForm/PaymentForm';
import { closeOutline } from 'ionicons/icons';
import { renderDistance } from '../../util/renderDistance';
import { ModalHeader } from '../ModalHeader';
import { PaymentInApp } from '../PaymentForm/PaymentInApp';
import { formatCurrency } from '../../util/formatCurrency';
import { useActiveRoute } from '../../hooks/useActiveRoute';
import _ from 'lodash';

interface Props {
  isOpen: boolean;
  onClose?(): void;
  product: Product;
}

export const PaymentModal = ({
  isOpen,
  onClose = () => {},
  product,
}: Props) => {
  const { fetchCurrentUser, user, updateRouteAccess } = useContext(UserContext);
  const { routes } = useContext(RouteContext);
  const { showPaywall } = useActiveRoute();
  const { currency, type } = product;
  const { destinations } = useContext(DestinationContext);
  const isSubscription = type === ProductType.SUBSCRIPTION;
  const price = formatCurrency(
    (product.amount || 6000) / 100,
    (currency || 'usd').toUpperCase()
  );

  const { t } = useTranslation();

  const onSuccess = useCallback(async () => {
    await fetchCurrentUser();
    if (isSubscription) {
      map(routes, (route) => {
        updateRouteAccess(route);
      });
    } else {
      // @ts-ignore - Argument of type 'Route | Subscription' is not assignable to parameter of type 'Route'
      updateRouteAccess(
        _.find(routes, {
          product: {
            external_id: product.external_id,
          },
        })!
      );
    }
    onClose();
  }, [onClose, fetchCurrentUser, updateRouteAccess, product, routes]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <IonModal onDidDismiss={onClose} isOpen={isOpen}>
      <div>
        <IonFab vertical="top" horizontal="end">
          <IonFabButton
            style={{ marginTop: isPlatform('ios') ? '30px' : 'auto' }}
            color="dark"
            onClick={onClose}
            size="small"
          >
            <IonIcon icon={closeOutline} size="small" />
          </IonFabButton>
        </IonFab>
        <ModalHeader
          height={isPlatform('desktop') ? '200px' : '40vh'}
          imageUrl={last(destinations)?.image}
          maxHeight="210px"
        />
        <IonContent
          style={{ height: isPlatform('desktop') ? '350px' : '60vh' }}
        >
          <div style={{ padding: '10px' }}>
            <P style={{ fontSize: '14px', margin: '25px 0' }}>
              {isSubscription ? (
                <>
                  {t('components.Upgrade.Subscription')}{' '}
                  <IonRouterLink
                    href="https://www.caminoforgood.com/faq"
                    target="_blank"
                  >
                    FAQ
                  </IonRouterLink>
                </>
              ) : showPaywall && window.location.pathname !== '/upgrade' ? (
                <>
                  <span>{t('components.Upgrade.PaywallText1')}</span>
                  {renderDistance(8, user?.user_settings.distance_unit, {
                    decimalPoints: 0,
                  })}
                  {t('components.Upgrade.PaywallText2')}
                  {price}
                  {t('components.Upgrade.PaywallText3')}
                  <IonRouterLink
                    href="https://www.caminoforgood.com/faq"
                    target="_blank"
                  >
                    FAQ
                  </IonRouterLink>
                  .
                </>
              ) : (
                <>
                  {t('components.Upgrade.UpgradeText1')}
                  {price}
                  {t('components.Upgrade.UpgradeText2')}
                  <IonRouterLink
                    href="https://www.caminoforgood.com/faq"
                    target="_blank"
                  >
                    FAQ
                  </IonRouterLink>
                  .
                </>
              )}
            </P>
            {isPlatform('cordova') && isPlatform('ios') ? (
              <PaymentInApp onSuccess={onSuccess} product={product} />
            ) : (
              <PaymentForm
                email={user.email}
                key={product.external_id}
                name={`${user.first_name} ${user.last_name}`}
                onCancel={onClose}
                onSuccess={onSuccess}
                product={product!}
              />
            )}
          </div>
        </IonContent>
      </div>
    </IonModal>
  );
};
