import styled from '@emotion/styled';
import { IonCol, IonGrid, IonLoading, IonRow, isPlatform } from '@ionic/react';
import { find } from 'lodash';
import React, { useMemo } from 'react';
import { Route, Subscription } from '../../context/RouteContext';
import { formatCurrency } from '../../util/formatCurrency';
import { Card } from '../Card/Card';
import { PaymentForm } from '../PaymentForm/PaymentForm';
import { RouteCard } from '../RouteCard/RouteCard';
import { P } from '../Text';
import { PLANS } from './SelectPlanSlide';
import { SlideHeader } from './SlideHeader';

interface Props {
  onSuccess(data?: any): void;
  plan: string;
  route: string;
  routes: Route[];
  subscriptions: Subscription[];
}

const Wrapper = styled.div`
  background-color: #fff;
  border: 1px solid #f2f5f7;
  border-radius: 10px;
  box-shadow: 0 0 0 1px #eee;
  padding: 0px 10px;
`;

export const CheckoutSlide = ({
  onSuccess,
  plan,
  route: routePath,
  routes,
  subscriptions: [subscription],
}: Props) => {
  const route = find(routes, { path: routePath }) || routes[0];

  const routeCards = useMemo(() => {
    let cards = null;
    switch (plan) {
      case PLANS.MONTHLY:
        cards = routes ? (
          <Card
            bg={`${route.route_image}`}
            subtitle={`${formatCurrency(
              subscription?.product?.amount / 100
            )} / month`}
            style={{ width: '100%' }}
            textColor="light"
            title="Monthly Subscription"
          >
            {isPlatform('desktop') ? undefined : <P>Access to all routes</P>}
          </Card>
        ) : null;
        break;
      case PLANS.FLAT:
        cards = route ? (
          <RouteCard
            isDesktop={false}
            key={route.id}
            route={route}
            selected={false}
          />
        ) : null;
        break;
    }
    return cards;
  }, [plan, route, routes, subscription?.product?.amount]);

  const OuterWrapper = isPlatform('desktop') ? Wrapper : React.Fragment;

  return (
    <IonGrid style={{ width: '100vw' }}>
      <SlideHeader caption="Checkout" title="My Cart" />
      <IonRow>
        <IonCol offsetMd="3" sizeMd="6" sizeXs="12">
          {routes.length ? (
            <OuterWrapper>
              <IonRow>{routeCards}</IonRow>
              <PaymentForm
                isLoggedIn={false}
                email=""
                name={''}
                onSuccess={onSuccess}
                product={
                  plan === PLANS.FLAT ? route.product : subscription.product
                }
              />
            </OuterWrapper>
          ) : (
            <IonLoading isOpen />
          )}
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};
