import { IonContent, IonPage, isPlatform } from '@ionic/react';
import React, { ReactElement, useContext } from 'react';

import { Caption, P } from '../components/Text';
import i18next from 'i18next';
import { LoginDesktopView } from '../components/LoginView/LoginDesktopView';
import { LoginMobileView } from '../components/LoginView/LoginMobileView';
import { LoginContext } from '../context/LoginContext';
import { ForgotPasswordForm } from '../components/ForgotPassword/ForgotPassword';
import { Link } from '../components/Link/Link';

export const ForgotPassword = ({ message }: { message: ReactElement }) => {
  const { forgotPassword, loading } = useContext(LoginContext);

  const LoginContent = isPlatform('desktop')
    ? LoginDesktopView
    : LoginMobileView;

  return (
    <IonPage>
      <IonContent
        style={{
          // @ts-ignore
          '--background': isPlatform('desktop') ? '#eee' : '',
          alignItems: 'center',
          display: 'flex',
          height: '100vh',
        }}
      >
        <div style={{ display: loading ? 'none' : 'initial' }}>
          <LoginContent title="Forgot Password">
            {message || (
              <P color="grey">
                {String(i18next.t('pages.login.FORGOT_PASSWORD'))}
              </P>
            )}
            <br />
            <ForgotPasswordForm forgotPassword={forgotPassword} />
            <br />
            <Caption>
              <Link href="/signup">Signup</Link> or{' '}
              <Link href="/login">Login</Link>
            </Caption>
          </LoginContent>
        </div>
      </IonContent>
    </IonPage>
  );
};
