import { reverse, take } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import {
  IonCard,
  IonRow,
  IonCol,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCardContent,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';
import { H4, P, Text } from '../Text';
import { DestinationTitle } from '../DestinationTitle/DestinationTitle';
import { ProgressDonutChart } from './ProgressDonutChart';
import { DestinationList } from '../DestinationList/DestinationList';
import { Map } from '../Map/Map';
import { PathContext, Waypoint } from '../../context/PathContext';

import bootLeft from '../../assets/bootprint_alt.png';
import bootRight from '../../assets/bootprint.png';
import { chevronForwardOutline } from 'ionicons/icons';
import { useUserDestinations } from '../../hooks/useUserDestinations';
import { useActiveRoute } from '../../hooks/useActiveRoute';

export const HomeDesktop = () => {
  const {
    getNextWaypoint,
    getLastWaypoint,
    getUnlockedWaypoints,
    getWaypoints,
  } = useContext(PathContext);
  const { user } = useContext(UserContext);
  const { mileage, route } = useActiveRoute();
  const {
    finishedCamino,
    lastDestination,
    nextDestination,
    unlockedDestinations,
  } = useUserDestinations();
  const { t } = useTranslation();
  const [waypoints, setWaypoints] = useState<Waypoint[]>([]);
  useEffect(() => {
    (async () => {
      setWaypoints(await getWaypoints(route));
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const { lat, lng } =
    getNextWaypoint(mileage) ||
    (finishedCamino ? getLastWaypoint(mileage) : {});

  return (
    <>
      <IonRow>
        <IonCol sizeMd="12">
          <IonCard color="dark">
            <IonCardContent>
              <IonRow>
                <IonCol size="1">
                  <img
                    alt="nothing"
                    src={bootLeft}
                    style={{
                      height: '40px',
                      marginBottom: '10px',
                      opacity: '0.5',
                    }}
                  />
                  <img
                    alt="nothing"
                    src={bootRight}
                    style={{
                      height: '40px',
                      marginLeft: '10px',
                      opacity: '0.5',
                    }}
                  />
                </IonCol>
                <IonCol size="10">
                  <H4>
                    {t('components.HomeDesktop.WelcomeBackText1')}
                    {user?.first_name}
                  </H4>
                  <Text>{t('components.HomeDesktop.WelcomeBackText2')}</Text>
                </IonCol>
                <IonCol size="1">
                  <IonButton color="" routerLink="/dashboard/progress">
                    <IonIcon icon={chevronForwardOutline} />
                  </IonButton>
                </IonCol>
              </IonRow>
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol sizeMd="6">
          <IonCard
            style={{
              backgroundImage: `linear-gradient(rgba(0,0,0,0.25), rgba(0,0,0,0.5)), url(${
                finishedCamino ? lastDestination.image : nextDestination?.image
              })`,
              backgroundPosition: '50%',
              backgroundSize: 'cover',
              height: '350px',
              position: 'relative',
            }}
          >
            <IonCardContent>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <ProgressDonutChart
                  currentMileage={
                    finishedCamino ? route.meta.distance : mileage
                  }
                  distanceUnit={user?.user_settings.distance_unit}
                  isResponsive={false}
                  route={route}
                  size={240}
                />
              </div>
              {!finishedCamino && nextDestination && (
                <DestinationTitle
                  destination={nextDestination}
                  distanceUnit={user?.user_settings.distance_unit}
                  mileage={mileage}
                />
              )}
            </IonCardContent>
          </IonCard>
        </IonCol>
        <IonCol sizeMd="6">
          <IonCard style={{ height: '350px' }}>
            <IonCardHeader>
              <IonCardSubtitle>
                {t('components.HomeDesktop.LastDestinations')}
              </IonCardSubtitle>
              <IonCardTitle>
                {t('components.HomeDesktop.RecentVisits')}
              </IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              {unlockedDestinations.length > 0 ? (
                <DestinationList
                  currentMileage={mileage}
                  destinations={take(reverse([...unlockedDestinations]), 3)}
                  distanceUnit={user?.user_settings.distance_unit}
                />
              ) : (
                <P>{t('components.HomeDesktop.FirstWelcomeText')}</P>
              )}
            </IonCardContent>
          </IonCard>
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol sizeMd="12">
          <IonCard style={{ height: '400px' }}>
            {/* checking for 'lat' and 'lng' before rendering Map to prevent a blank map from rendering */}
            {user && lat && lng && (
              <Map
                activeUserId={user.id}
                center={{ lat, lng }}
                controls={false}
                draggable={false}
                paths={[
                  // @TODO: Can we use CSS variables here?
                  { color: '#e9b20a', dashed: true, path: waypoints },
                  {
                    color: '#de4e00',
                    path: getUnlockedWaypoints(mileage),
                  },
                ]}
                route={route}
                users={[user]}
              />
            )}
          </IonCard>
        </IonCol>
      </IonRow>
    </>
  );
};
