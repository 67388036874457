import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
} from 'react-share';

import { IDestination } from '../../context/DestinationContext';

type Props = {
  destination: IDestination;
  disabled?: boolean;
};

export const DestinationShare: React.FC<Props> = ({
  destination,
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <div className="share-buttons" style={{ margin: '10px' }}>
      <FacebookShareButton
        url="www.caminoforgood.com"
        quote={`${t('components.DestinationDetails.ShareText1')} ${
          destination.name
        }${t('components.DestinationDetails.ShareText2')}`}
        hashtag="#caminoforgood"
        disabled={disabled && true}
      >
        <FacebookIcon size={32} round={true} />
        <span style={{ padding: '8px', float: 'right' }}>
          {t('components.DestinationDetails.FacebookShare')}
        </span>
      </FacebookShareButton>
      <TwitterShareButton
        url="www.caminoforgood.com"
        title={`${t('components.DestinationDetails.ShareText1')} ${
          destination.name
        }${t('components.DestinationDetails.ShareText2')}`}
        hashtags={['caminoforgood']}
        disabled={disabled && true}
      >
        <TwitterIcon size={32} round={true} />
        <span style={{ padding: '8px', float: 'right' }}>
          {t('components.DestinationDetails.TwitterShare')}
        </span>
      </TwitterShareButton>
    </div>
  );
};
