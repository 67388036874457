import { filter, includes, isEmpty } from 'lodash';
import { IonAlert, isPlatform } from '@ionic/react';
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Toolbar } from '../components/Toolbar';
import { DestinationContext } from '../context/DestinationContext';
import { UserContext } from '../context/UserContext';
import { renderDistance } from '../util/renderDistance';
import { H2, Text } from '../components/Text';
import { PageWrapper } from '../components/PageWrapper';
import { Badge } from '../components/Badge/Badge';
import { ImageContext } from '../context/ImageContext';
import { DestinationDetails } from '../components/DestinationDetails/DestinationDetails';
import { useActiveRoute } from '../hooks/useActiveRoute';
import { ImageSlide, ImageSlider } from '../components/ImageSlider/ImageSlider';

export const Destination = ({ match }: any) => {
  const destinationId = match.params.id;
  const { findDestination, firstDestinationId, isDestinationLocked } =
    useContext(DestinationContext);
  const { t } = useTranslation();
  const { findImagesNear } = useContext(ImageContext);
  const { user } = useContext(UserContext);
  const {
    addStamp,
    destinations_visited,
    mileage,
    removeStamp,
    stamps,
    updateDestinations,
  } = useActiveRoute();
  const [stampAvailableAlert, setStampAvailableAlert] = useState(false);
  const [showAlert, setShowAlert] = useState(
    // if user is at the first destination without any mileage they will see the intro IonAlert
    // ID matches St Jean Pied de Port
    destinationId === firstDestinationId && isEmpty(destinations_visited)
  );

  const selectedDestination = findDestination({
    id: destinationId,
  });

  const isLocked = isDestinationLocked(
    selectedDestination?.id || '',
    mileage || 0
  );

  const destinationImages = selectedDestination
    ? [
        {
          src: selectedDestination.image,
          thumbnail: selectedDestination.image,
          title: selectedDestination.name,
        },
        ...filter(findImagesNear(selectedDestination.id), (img: ImageSlide) => {
          img.thumbnail = img.src;
          img.title = selectedDestination.name;
          return img.src !== selectedDestination.image;
        }),
      ]
    : [];

  const isStampCollected = selectedDestination
    ? includes(stamps, selectedDestination.id)
    : false;

  useEffect(() => {
    selectedDestination && updateDestinations([selectedDestination.id]);
  }, [selectedDestination]); // eslint-disable-line react-hooks/exhaustive-deps

  return selectedDestination ? (
    <>
      <Toolbar title={selectedDestination.name} />
      <PageWrapper padding={0}>
        {isLocked && (
          <div className="ion-text-center cfg-modal">
            <h3>
              {renderDistance(
                selectedDestination.from_start - (mileage || 0),
                user?.user_settings.distance_unit
              )}
            </h3>
            <p>{t('pages.destination.UNLOCK_INSTRUCTIONS')}</p>
          </div>
        )}
        {/* {isPlatform('desktop') ? (
          <div>
            <ImageGallery images={destinationImages as ImageSlide[]} />
          </div>
        ) : (
        )} */}

        <ImageSlider
          images={destinationImages as ImageSlide[]}
          disabled={isLocked}
        />
        <div
          style={{
            backgroundColor: '#f4f4f4',
            marginBottom: '20px',
            padding: '20px',
          }}
        >
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              justifyContent: 'space-between',
              filter: isLocked ? 'blur(4px)' : '',
            }}
          >
            <div>
              <Text>{t('pages.destination.WELCOME_TO')}</Text>
              <H2 fontSize="24px" fontWeight={500}>
                {selectedDestination.name}
              </H2>
            </div>
            <div
              className="destination-stamp"
              style={{
                alignItems: 'center',
                cursor: 'pointer',
                display: 'flex',
              }}
            >
              <Badge
                disabled={!isStampCollected}
                destinationName={selectedDestination.name}
                name={selectedDestination.name}
                onClick={() => setStampAvailableAlert(true)}
                size={90}
              />
            </div>
          </div>
        </div>
        <div style={{ padding: isPlatform('mobile') ? '20px' : '' }}>
          <DestinationDetails
            destination={selectedDestination}
            locked={isLocked}
          />
        </div>
        <IonAlert
          buttons={[t("Let's Go")]}
          isOpen={showAlert}
          onDidDismiss={() => setShowAlert(false)}
          header={t('pages.destination.WELCOME_TO')}
          subHeader={selectedDestination.name}
          message={t('pages.destination.Welcome message')}
        />
        {stampAvailableAlert && !isStampCollected ? (
          <IonAlert
            isOpen={stampAvailableAlert}
            onDidDismiss={() => setStampAvailableAlert(false)}
            header={t('pages.destination.ADD_STAMP_CONFIRM')}
            message={t('pages.destination.CLICK_TO_STAMP_LONG')}
            buttons={[
              t('pages.destination.NO'),
              {
                text: t('pages.destination.YES'),
                handler: () => addStamp(selectedDestination.id),
              },
            ]}
          />
        ) : (
          <IonAlert
            isOpen={stampAvailableAlert}
            onDidDismiss={() => setStampAvailableAlert(false)}
            header={t('pages.destination.REMOVE_STAMP_CONFIRM')}
            message={t('pages.destination.CLICK_TO_REMOVE_STAMP')}
            buttons={[
              t('pages.destination.NO'),
              {
                text: t('pages.destination.YES'),
                handler: () => removeStamp(selectedDestination.id),
              },
            ]}
          />
        )}
      </PageWrapper>
    </>
  ) : (
    <h1>{t('pages.destination.DESTINATION_NOT_FOUND')}</h1>
  );
};
