import { IonRouterLink } from '@ionic/react';
import { take, reverse } from 'lodash';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { DestinationList } from '../../components/DestinationList/DestinationList';
import { DestinationTitle } from '../../components/DestinationTitle/DestinationTitle';
import { UserContext } from '../../context/UserContext';
import { useActiveRoute } from '../../hooks/useActiveRoute';
import { useUserDestinations } from '../../hooks/useUserDestinations';
import { ProgressDonutChart } from './ProgressDonutChart';

export const HomeMobile = () => {
  const { user } = useContext(UserContext);
  const { mileage, route } = useActiveRoute();
  const {
    finishedCamino,
    lastDestination,
    nextDestination,
    unlockedDestinations,
  } = useUserDestinations();
  const { t } = useTranslation();

  return (
    <>
      <div
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0.25), rgba(0,0,0,0.75)), url(${
            finishedCamino ? lastDestination.image : nextDestination?.image
          })`,
          backgroundPosition: '50% 50%',
          backgroundSize: 'cover',
          padding: '30px',
        }}
      >
        <ProgressDonutChart
          currentMileage={finishedCamino ? route.meta.distance : mileage}
          distanceUnit={user?.user_settings.distance_unit}
          route={route}
        />
        {nextDestination && (
          <DestinationTitle
            destination={nextDestination}
            distanceUnit={user?.user_settings.distance_unit}
            mileage={mileage}
          />
        )}
      </div>
      {unlockedDestinations.length > 0 && (
        <DestinationList
          currentMileage={mileage}
          destinations={take(reverse([...unlockedDestinations]), 3)}
          distanceUnit={user?.user_settings.distance_unit}
          header={
            <div>
              {t('components.HomeDesktop.RecentVisits')} &nbsp;
              {unlockedDestinations.length > 3 && (
                <IonRouterLink
                  routerLink="/dashboard/destinations"
                  style={{ fontSize: '14px', fontWeight: 400 }}
                >
                  {t('components.HomeMobile.View All')}
                </IonRouterLink>
              )}
            </div>
          }
        />
      )}
    </>
  );
};
