const LOCAL_STORAGE_KEY = 'cfgFeatureFlag';

function getFlagsFromLocalStorage(): { [key: string]: boolean } {
  let lStorage = {};
  try {
    lStorage = localStorage.getItem(LOCAL_STORAGE_KEY)
      ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY) || '')
      : {};
  } catch (e) {
    lStorage = {};
  }

  return lStorage;
}

function getFlagState(flagName: string): boolean {
  return (
    // @TODO: Check environment variables
    // env(flagName) === 'true' ||
    // @TODO Check query params
    // || (QUERY_PARAM_KEY in queryParams && queryParams.ff.indexOf(param) >= 0)
    // Check local storage
    getFlagsFromLocalStorage()[flagName] || false
  );
}

export const FLAG = {
  TRACKERS_ENABLED: getFlagState('TRACKERS_ENABLED'),
};

export const isTestEmail = (email: string) =>
  email?.includes('@caminoforgood.com') || email?.includes('@f1v.co');
