import { filter, find, last, includes } from 'lodash';
import React, { createContext, useState, useCallback } from 'react';
import { useActiveRoute } from '../hooks/useActiveRoute';

export interface IDestination {
  id: any;
  country: string;
  description: string;
  audio: string;
  audio_title: string;
  audio_credit?: string;
  audio_credit_url?: string;
  quote: string;
  tip: string;
  image: string;
  from_start: number;
  lat: number;
  lng: number;
  locked: boolean;
  name: string;
  section: string;
}

export interface IDestinationContext {
  destinations: IDestination[];
  firstDestinationId: string;
  findDestination(config: Partial<IDestination>): IDestination | null;
  finishedCamino(m?: number): boolean;
  getNextDestination(m: number): IDestination | null;
  getUnlockedDestinations(m: number): IDestination[];
  loadDestinations(language: string): void;
  loading: boolean;
  isDestinationLocked(id: string, m: number): boolean;
}

export const DestinationContext = createContext<IDestinationContext>(
  {} as IDestinationContext
);

const destinationCache: { [key: string]: IDestination[] } = {};

export const DestinationProvider = ({ children }: any) => {
  const { route } = useActiveRoute();
  const [destinations, setDestinations] = useState<IDestination[]>([]);
  const [firstDestinationId, setFirstDestinationId] = useState<string>('');
  const [loading, setLoading] = useState(true);

  const findDestination = useCallback(
    (config: Partial<IDestination>) => find(destinations, config) || null,
    [destinations]
  );

  const getUnlockedDestinations: (mileage: number) => IDestination[] =
    useCallback(
      (mileage) => filter(destinations, (d) => d.from_start <= mileage),
      [destinations]
    );

  const getNextDestination = useCallback(
    (m: number) => filter(destinations, (d) => d.from_start > m)[0],
    [destinations]
  );

  const loadDestinations = useCallback(
    async (language: 'en' | 'es') => {
      if (route) {
        const cacheKey = `${route.path}_${language}`;
        if (destinationCache[cacheKey]) {
          setDestinations(destinationCache[cacheKey]);
          setFirstDestinationId(destinationCache[cacheKey][0].id);
          setLoading(false);
          return;
        }

        const towns = await fetch(route.meta.places[language || 'en']).then(
          (res) => res.json()
        );

        destinationCache[cacheKey] = towns;
        setFirstDestinationId(towns[0].id);
        setDestinations(towns);
        setLoading(false);
      }
    },
    [route]
  );

  const isDestinationLocked = useCallback(
    (id: string, mileage: number) => {
      const d = findDestination({ id });
      return d ? d.from_start > mileage : true;
    },
    [findDestination]
  );

  const finishedCamino = useCallback(
    (mileage: number = 0) =>
      includes(getUnlockedDestinations(mileage), last(destinations)),
    [destinations, getUnlockedDestinations]
  );

  return (
    <DestinationContext.Provider
      value={{
        destinations,
        loading,
        firstDestinationId,
        findDestination,
        finishedCamino,
        getNextDestination,
        getUnlockedDestinations,
        loadDestinations,
        isDestinationLocked,
      }}
    >
      {children}
    </DestinationContext.Provider>
  );
};
