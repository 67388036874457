import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { useState } from 'react';
import { Route } from '../../context/RouteContext';
import { RouteCard } from '../RouteCard/RouteCard';
import { SlideHeader } from './SlideHeader';

interface Props {
  onRouteSelect(route: string): void;
  routes: Route[];
  selectedRoutePath: string;
}

export const SelectRouteSlide = ({
  selectedRoutePath,
  onRouteSelect,
  routes,
}: Props) => {
  const [highlightedRouteId, highlightRouteId] = useState(
    selectedRoutePath || (routes && routes[0]?.path)
  );

  return (
    <IonGrid>
      <SlideHeader caption="Select a route" title="Flat Rate Plan" />
      <IonRow>
        {routes.map((route: Route) => {
          const isDisabled = false;
          return (
            <IonCol key={route.id} sizeMd="4" sizeXs="12">
              <RouteCard
                isActive={!isDisabled && route.path === highlightedRouteId}
                isDesktop={false}
                isDisabled={isDisabled}
                isLoggedIn={false}
                onSelect={(r: Route) => {
                  highlightRouteId(r.path);
                  onRouteSelect(r.path);
                }}
                route={route}
                selected={!isDisabled && highlightedRouteId === route.path}
                showButtons={false}
              />
            </IonCol>
          );
        })}
      </IonRow>
    </IonGrid>
  );
};
