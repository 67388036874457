import {
  IonIcon,
  IonItem,
  IonLabel,
  IonPopover,
  IonToggle,
} from '@ionic/react';
import React from 'react';
import { informationCircle as helpIcon } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

import { useBoolean } from '../../hooks/useBoolean';
import { Spinner } from '../Spinner/Spinner';

export type ToggleItemEvent = CustomEvent<{ checked: boolean }>;

type Props = {
  checked?: boolean;
  helpText?: string;
  label: string;
  loading?: boolean;
  onToggle(e?: ToggleItemEvent): void;
  lines?: 'none' | 'full' | 'inset';
};

export const ToggleItem: React.FC<Props> = ({
  checked,
  helpText,
  label,
  loading = false,
  onToggle,
  lines,
}) => {
  const [popoverVisible, showPopover, hidePopover] = useBoolean(false);
  const { t } = useTranslation();

  return (
    <IonItem lines={lines}>
      <IonLabel
        onClick={showPopover}
        style={{
          alignItems: 'center',
          display: 'grid',
          gridTemplateColumns: '1fr 0fr',
        }}
      >
        {label}
        {helpText && (
          <IonIcon icon={helpIcon} onClick={showPopover} size="large" />
        )}
      </IonLabel>

      {loading ? (
        <Spinner />
      ) : (
        <IonToggle checked={checked} onIonChange={onToggle} slot="end" />
      )}
      {helpText && (
        <IonPopover
          isOpen={popoverVisible}
          mode="ios"
          onDidDismiss={hidePopover}
        >
          <div style={{ padding: '20px' }}>
            <div
              style={{
                alignItems: 'center',
                display: 'flex',
                fontSize: '24px',
              }}
            >
              <IonIcon icon={helpIcon} onClick={showPopover} size="large" />
              &nbsp; {t('components.ToggleItem.Help')}
            </div>
            <p>{helpText}</p>
          </div>
        </IonPopover>
      )}
    </IonItem>
  );
};
