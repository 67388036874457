import _ from 'lodash';
import React, { useState } from 'react';
import { useApolloClient, useMutation } from 'react-apollo';

import {
  CREATE_GROUP,
  DELETE_GROUP,
  FETCH_GROUP,
  GROUPS,
  JOIN_GROUP,
  LEAVE_GROUP,
  UPDATE_GROUP,
} from '../queries/group';
import { Message } from './MessageContext';
import { PublicUser, User } from './UserContext';

export interface Group {
  conversation: Partial<Message>;
  name: string;
  owner: Partial<User>;
  public: boolean;
  route_id: number;
  secret: string;
  users: PublicUser[];
}

interface GroupConsumer {
  groups: Group[];
  loading?: boolean;
  createGroup(variables: Partial<Group>): Promise<Group>;
  deleteGroup(secret: string): Promise<void>;
  fetchGroup(secret: string): Promise<Group>;
  getMyGroups(groups: Group[], user: User): Group[];
  joinGroup(secret: string): Promise<Group>;
  leaveGroup(secret: string): Promise<Group>;
  loadGroups(): void;
  setLoading(l: boolean): void;
  updateGroup(secret: string, options: Partial<Group>): Promise<Group>;
}

export const GroupContext = React.createContext<GroupConsumer>({
  groups: [] as Group[],
} as GroupConsumer);

export const GroupProvider = ({ children }: any) => {
  const client = useApolloClient();
  const [groups, setGroups]: [Group[], any] = useState([]);
  const [loading, setLoading] = useState(true);
  const [createGroupMutation] = useMutation(CREATE_GROUP);
  const [deleteGroupMutation] = useMutation(DELETE_GROUP);
  const [joinGroupMutation] = useMutation(JOIN_GROUP);
  const [leaveGroupMutation] = useMutation(LEAVE_GROUP);
  const [updateGroupMutation] = useMutation(UPDATE_GROUP);

  const loadGroups = async () => {
    setLoading(true);

    let g;
    try {
      const {
        data: { groups },
      } = await client.query({
        query: GROUPS,
      });
      g = groups;
    } catch (e) {
      console.error('Error Fetching Groups:', e);
    }

    setGroups(g || []);
    setLoading(false);
  };

  const fetchGroup = async (secret: string) => {
    try {
      const {
        data: { group },
      } = await client.query({
        query: FETCH_GROUP,
        variables: { secret },
      });

      return group;
    } catch (e) {
      console.error('Error Fetching Group:', e);
    }
  };

  const getMyGroups = (userGroups: Group[], user: User) =>
    _.compact(
      _.uniq(
        _.flatten([
          // returns the full group objects from the context
          _.map(userGroups, ({ secret }) => _.find(groups, { secret })),
          // private groups won't show up in the list above
          _.filter(userGroups, (group) => !group.public),
        ])
      )
    );

  const joinGroup = async (secret: string) => {
    const {
      data: { joinGroup: result },
    } = await joinGroupMutation({
      variables: {
        secret,
      },
    });

    const copiedList = [...groups];
    const cleanList = _.filter(copiedList, (group) => group.secret !== secret);
    setGroups([...cleanList, result]);

    return result;
  };

  const leaveGroup = async (secret: string) => {
    const {
      data: { leaveGroup: result },
    } = await leaveGroupMutation({
      variables: {
        secret,
      },
    });

    const copiedList = [...groups];
    const cleanList = _.filter(copiedList, (group) => group.secret !== secret);
    setGroups([...cleanList, result]);

    return result;
  };

  const createGroup = async (variables: Partial<Group>) => {
    const {
      data: { createGroup: result },
    } = await createGroupMutation({
      variables,
    });
    setGroups([...groups, result]);
    return result;
  };

  const deleteGroup = async (secret: string) => {
    const {
      data: { deleteGroup: result },
    } = await deleteGroupMutation({
      variables: {
        secret,
      },
    });

    setGroups(_.filter(groups, (group) => group.secret !== secret));
    return result;
  };

  const updateGroup = async (secret: string, options: Partial<Group>) => {
    const {
      data: { updateGroup: result },
    } = await updateGroupMutation({
      variables: {
        secret,
        options,
      },
    });

    setGroups(_.map(groups, (g) => (g.secret === secret ? result : g)));
    return result;
  };

  return (
    <GroupContext.Provider
      value={{
        groups: [...groups],
        loading,
        createGroup,
        deleteGroup,
        fetchGroup,
        getMyGroups,
        joinGroup,
        leaveGroup,
        loadGroups,
        setLoading,
        updateGroup,
      }}
    >
      {children}
    </GroupContext.Provider>
  );
};
