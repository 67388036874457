import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';

import shellImage from '../../assets/shell.png';
import { Text, H3 } from '../../components/Text';
import { renderDistance } from '../../util/renderDistance';
import { IDestination } from '../../context/DestinationContext';
import { subtractDistance } from '../../util/subtractDistance';

interface DestinationTitleProps {
  arrived?: boolean;
  destination: IDestination;
  distanceUnit?: string;
  mileage?: number;
  style?: React.CSSProperties;
}

const StyledTitle = styled(H3)`
  margin: 0px;
  padding: 0px;
  font-size: 30px;
  font-weight: 300;
  white-space: nowrap;
  width: calc(100vw - 90px);
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const DestinationTitle = ({
  arrived,
  destination,
  distanceUnit,
  mileage,
  style,
}: DestinationTitleProps) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        alignItems: 'center',
        color: 'white',
        display: 'flex',
        marginTop: '20px',
        ...style,
      }}
    >
      <img
        alt="Shell"
        src={shellImage}
        style={{ height: '30px', marginRight: '10px' }}
      />
      <div>
        <Text style={{ whiteSpace: 'nowrap' }}>
          {arrived ? (
            t('components.DestinationTitle.You arrived at')
          ) : (
            <>
              {t('components.DestinationTitle.NextDestination')}&nbsp;
              <strong>
                {renderDistance(
                  mileage
                    ? subtractDistance(destination.from_start, mileage)
                    : destination.from_start,
                  distanceUnit
                )}
              </strong>
            </>
          )}
        </Text>
        <StyledTitle>{destination.name}</StyledTitle>
      </div>
    </div>
  );
};
