import { isPlatform } from '@ionic/react';
import React, { useEffect } from 'react';

import { PageWrapper } from '../components/PageWrapper';
import { Toolbar } from '../components/Toolbar';
import { MileageFab } from '../components/MileageFab/MileageFab';
import { HomeDesktop } from '../components/Home/HomeDesktop';
import { HomeMobile } from '../components/Home/HomeMobile';
import { BlankSlate } from '../components/BlankSlate/BlankSlate';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { RateAppModal } from '../components/RateAppModal/RateAppModal';
import { UpdateEmailModal } from '../components/UpdateEmailModal';
import { useActiveRoute } from '../hooks/useActiveRoute';

export const Home = () => {
  const { mileage } = useActiveRoute();

  const [_get, set] = useLocalStorage(); // eslint-disable-line @typescript-eslint/no-unused-vars

  useEffect(() => {
    set('hasLoggedIn', true);
  }, [set]);

  return (
    <>
      <RateAppModal />
      <MileageFab />
      <Toolbar showBack={false} />
      <PageWrapper padding="0px">
        <BlankSlate isOpen={!mileage} />
        <UpdateEmailModal />
        {isPlatform('desktop') ? <HomeDesktop /> : <HomeMobile />}
      </PageWrapper>
    </>
  );
};
