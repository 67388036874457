import { IonHeader, IonItem, IonLabel } from '@ionic/react';
import React, { useContext } from 'react';

import { Avatar } from '../Avatar';
import { Caption, Text } from '../Text';
import { renderDistance } from '../../util/renderDistance';
import { PublicUser, UserContext } from '../../context/UserContext';
import { Route } from '../../context/RouteContext';

type Props = {
  color?: string;
  distanceUnit?: string;
  height?: string;
  isTransparent?: boolean;
  route: Route;
  showDistance?: boolean;
  size?: string;
  user: PublicUser;
};

export const UserDetail = ({
  color = 'light',
  distanceUnit,
  height = '260px',
  isTransparent,
  route,
  showDistance,
  size = 'medium',
  user,
}: Props) => {
  const { getRouteSettings } = useContext(UserContext);
  const mileage = getRouteSettings(route, user).mileage || 0;
  return size !== 'small' ? (
    <IonHeader
      className="ion-text-center"
      style={{
        alignItems: 'center',
        backgroundColor: isTransparent
          ? ''
          : color === 'dark'
          ? 'var(--color-secondary-800)'
          : '#f4f4f4',
        display: 'flex',
        flexDirection: 'column',
        height: height,
        justifyContent: 'center',
      }}
    >
      <Avatar
        image={user?.avatar}
        alt={user?.first_name}
        size={100}
        style={{ margin: '20px 0' }}
      />
      <Text color={color === 'dark' ? 'white' : ''}>
        {user?.first_name} {user?.last_name.charAt(0)}
      </Text>
      {showDistance && (
        <Caption color="var(--color-secondary-400)">
          {user?.user_settings.distance_unit &&
            renderDistance(
              mileage,
              distanceUnit || user?.user_settings.distance_unit
            )}
        </Caption>
      )}
    </IonHeader>
  ) : (
    <IonHeader
      style={{
        backgroundColor: 'var(--ion-color-dark)',
      }}
    >
      <IonItem
        className="ion-no-border"
        color="dark"
        style={{
          // @ts-ignore
          '--ion-color-dark-shade': 'var(--ion-color-dark)',
        }}
      >
        <Avatar
          image={user?.avatar}
          alt={user?.last_name}
          size={30}
          slot="start"
        />
        <IonLabel>
          {user?.first_name} {user?.last_name}
        </IonLabel>
        {showDistance && (
          <Caption>
            {user?.user_settings.distance_unit &&
              renderDistance(
                mileage,
                distanceUnit || user?.user_settings.distance_unit
              )}
          </Caption>
        )}
      </IonItem>
    </IonHeader>
  );
};
