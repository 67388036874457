import { filter, includes } from 'lodash';
import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DestinationList } from '../components/DestinationList/DestinationList';
import { Toolbar } from '../components/Toolbar';
import { PageWrapper } from '../components/PageWrapper';
import { DestinationContext } from '../context/DestinationContext';
import { IonSearchbar } from '@ionic/react';
import { UserContext } from '../context/UserContext';
import constants from '../util/constants';
import { useActiveRoute } from '../hooks/useActiveRoute';

export const Destinations = () => {
  const { destinations } = useContext(DestinationContext);
  const { user } = useContext(UserContext);
  const { mileage } = useActiveRoute();
  const [filteredList, setFilteredList] = useState(destinations);
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();

  const filterList = useCallback(
    (e: CustomEvent) => {
      const newTerm = e.detail.value;
      const newList =
        newTerm.length > 0
          ? filter(destinations, (d) =>
              includes(d.name.toLowerCase(), newTerm.toLowerCase())
            )
          : destinations;

      setSearchTerm(newTerm);
      setFilteredList(newList);
    },
    [destinations]
  );

  useEffect(() => {
    if (!searchTerm) {
      setFilteredList(destinations);
    }
  }, [destinations, searchTerm]);

  return (
    <>
      <Toolbar />
      <PageWrapper>
        <>
          <IonSearchbar
            onIonChange={filterList}
            placeholder={t('pages.destination.SEARCH')}
            value={searchTerm}
          />
          <DestinationList
            currentMileage={mileage || 0}
            destinations={filteredList}
            distanceUnit={
              user?.user_settings.distance_unit ||
              t(constants.DISTANCE_UNITS.DEFAULT)
            }
          />
        </>
      </PageWrapper>
    </>
  );
};
