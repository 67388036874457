/**
 * This function normalizes irregularities(i.e., rounding errors) when performing subtraction between two floating point numbers.
 * For example:
 * > 215.3 - 215.299 returns 0.0010000000000047748
 * Instead, calling:
 * > subtractDistance(215.3, 215.299) returns 0.001
 */
export const subtractDistance = (distance1: number, distance2: number) => {
  return (distance1 * 1000 - distance2 * 1000) / 1000;
};
