import { gql } from 'apollo-boost';
import { USER_TYPE } from './user';

export const CREATE_USER_LOG = gql`
  mutation createUserLog($date: String!, $mileage: Float) {
    createUserLog(date: $date, mileage: $mileage) {
      user {
        ${USER_TYPE}
      }
    }
  }
`;

export const DELETE_USER_LOG = gql`
  mutation deleteUserLog($id: Int!) {
    deleteUserLog(id: $id) {
      user {
        ${USER_TYPE}
      }
    }
  }
`;

export const UPDATE_USER_LOG = gql`
  mutation updateUserLog($date: String!, $id: Int!, $mileage: Float) {
    updateUserLog(date: $date, id: $id, mileage: $mileage) {
      user {
        ${USER_TYPE}
      }
    }
  }
`;

export const CREATE_USER_LOGS = gql`
mutation createUserLogs($source: String!, $logs: [InputSourceLog], $date: String) {
  createUserLogs(source: $source, logs: $logs, date: $date) {
    user {
      ${USER_TYPE}
    }
  }
}
`;
