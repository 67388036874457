import { IonIcon } from '@ionic/react';
import { radioOutline } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IDestination } from '../../context/DestinationContext';
import { AudioButton } from '../AudioButton/AudioButton';
import { Caption, P } from '../Text';

type Props = {
  destination: IDestination;
  disabled?: boolean;
};

export const DestinationAudio: React.FC<Props> = ({
  destination,
  disabled,
}) => {
  const { t } = useTranslation();

  return destination.audio ? (
    <>
      {/* <img
        src="/assets/destination-audio.png"
        style={{ float: 'right', width: '80px' }}
      /> */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <P fontSize="14px">
          <strong>
            {t('components.DestinationDetails.PilgrimStoryTitle')}.
          </strong>
          &nbsp;
          {t('components.DestinationDetails.PilgrimStory')}
        </P>
        <div style={{ width: '40px' }}>
          <IonIcon
            color="medium"
            icon={radioOutline}
            size="large"
            style={{ flexBasis: '100px', fontSize: '36px !important' }}
          />
        </div>
      </div>
      {destination.audio_title && (
        <>
          <Caption
            style={{
              backgroundColor: '#f4f4f4',
              borderRadius: '10px',
              marginTop: '20px',
              padding: '12px 24px',
            }}
          >
            <span
              dangerouslySetInnerHTML={{
                __html: destination.audio_title,
              }}
            />
            <br />
            {destination.audio_credit_url ? (
              <a href={destination.audio_credit_url}>
                {destination.audio_credit
                  ? destination.audio_credit
                  : destination.audio_credit_url}
              </a>
            ) : (
              destination.audio_credit && (
                <span
                  dangerouslySetInnerHTML={{
                    __html: destination.audio_credit,
                  }}
                />
              )
            )}
          </Caption>
        </>
      )}
      <div style={{ marginTop: '20px' }}>
        <AudioButton
          audio={destination.audio}
          disabled={disabled}
          text={t('components.DestinationDetails.Listen')}
        />
      </div>
    </>
  ) : null;
};
