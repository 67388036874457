import { includes, last } from 'lodash';
import { useContext, useEffect, useState } from 'react';
import {
  DestinationContext,
  IDestination,
} from '../context/DestinationContext';
import { useActiveRoute } from './useActiveRoute';

/**
 * @description
 * A hook to store useful information relevant to the current users position along the
 * route. The values will update when the users current mileages updates.
 */
export const useUserDestinations = () => {
  const { destinations, getNextDestination, getUnlockedDestinations } =
    useContext(DestinationContext);
  const { mileage } = useActiveRoute();

  const [finishedCamino, setFinished] = useState(false);
  const [lastDestination, setLastDestination] = useState(destinations[0]);
  const [nextDestination, setNextDestination] = useState<IDestination | null>(
    null
  );
  const [unlockedDestinations, setUnlockedDestinations] = useState<
    IDestination[]
  >([]);

  useEffect(() => {
    setFinished(includes(getUnlockedDestinations(mileage), last(destinations)));
    setNextDestination(getNextDestination(mileage));

    const unlocked = getUnlockedDestinations(mileage);
    setUnlockedDestinations(unlocked);
    setLastDestination(last(unlocked) || destinations[0]);
  }, [destinations, getNextDestination, getUnlockedDestinations, mileage]);

  return {
    finishedCamino,
    lastDestination,
    nextDestination,
    unlockedDestinations,
  };
};
