import { IonFab, IonIcon, IonFabButton, IonModal } from '@ionic/react';
import { add, closeOutline } from 'ionicons/icons';
import React, { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { MileageForm } from '../../components/MileageForm';
import { UserContext } from '../../context/UserContext';
import { CongratulationsModal } from '../CongratulationsModal/CongratulationsModal';
import { PaymentModal } from '../PaymentModal/PaymentModal';
import { DestinationTitle } from '../DestinationTitle/DestinationTitle';
import { P } from '../Text';
import { ModalHeader } from '../ModalHeader';
import constants from '../../util/constants';
import { renderDistance } from '../../util/renderDistance';
import { useUserDestinations } from '../../hooks/useUserDestinations';
import { useActiveRoute } from '../../hooks/useActiveRoute';

type Props = {
  isOpen?: boolean;
  onClose?(n?: number): void;
};

export const MileageFab: React.FC<Props> = ({
  isOpen: isModalOpen,
  onClose = () => {},
}) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(isModalOpen || false);
  const [openPaywall, setOpenPaywall] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [dist, setDist] = useState<number>();
  const { user } = useContext(UserContext);
  const { mileage, route, showPaywall } = useActiveRoute();
  const milesOrKm = renderDistance(8, user?.user_settings.distance_unit, {
    decimalPoints: 0,
  });
  const { finishedCamino, nextDestination } = useUserDestinations();

  const close = (newDistance?: number) => {
    setDist(newDistance);
    setOpen(false);
    setShowClose(false);
  };

  const onClickOpenPaywall = () => {
    setOpenPaywall(true);
  };

  return (
    <>
      {!finishedCamino && (
        <IonFab vertical="bottom" horizontal="end">
          <IonFabButton onClick={() => setOpen(true)} id="add">
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
      )}
      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => {
          setOpen(false);
          onClose(dist);
        }}
        onDidPresent={() => setShowClose(true)}
      >
        {showClose && (
          <IonFab vertical="bottom" horizontal="end">
            <IonFabButton color="dark" onClick={() => close()} id="close">
              <IonIcon icon={closeOutline} />
            </IonFabButton>
          </IonFab>
        )}
        <div>
          <ModalHeader imageUrl={nextDestination?.image}>
            {nextDestination && (
              <DestinationTitle
                destination={nextDestination}
                distanceUnit={user?.user_settings.distance_unit}
                mileage={mileage}
              />
            )}
          </ModalHeader>
          <br />
          <MileageForm onClose={close} />
          {showPaywall ? (
            <P
              style={{
                padding: '20px',
              }}
            >
              <span>{t('components.MileageFab.FabText1')}</span>
              {milesOrKm}
              <span>{t('components.MileageFab.FabText2')}</span>
              <span
                onClick={onClickOpenPaywall}
                style={{
                  color: 'var(--ion-color-primary)',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
              >
                {t('components.MileageFab.Here')}
              </span>
              <span>{t('components.MileageFab.FabText3')}</span>
            </P>
          ) : (
            <P
              style={{
                padding: '20px',
              }}
            >
              <span>{t('components.MileageFab.EnterDistanceText1')}</span>
              {t(
                user?.user_settings.distance_unit ||
                  constants.DISTANCE_UNITS.DEFAULT
              )}
              <span>{t('components.MileageFab.EnterDistanceText2')}</span>
            </P>
          )}
        </div>
      </IonModal>
      <CongratulationsModal />
      <PaymentModal
        onClose={() => setOpenPaywall(false)}
        isOpen={openPaywall}
        product={route.product}
      />
    </>
  );
};
