import { isEmpty } from 'lodash';

export const shouldEditEmail = (email: string) => {
  let shouldUpdate = false;
  // if email is not empty and includes '@privaterelay'
  if (
    isEmpty(email) ||
    email?.includes('@privaterelay') ||
    email === 'private'
  ) {
    // set shouldUpdate to true
    shouldUpdate = true;
  }
  return shouldUpdate;
};
