import styled from '@emotion/styled';
import { IonModal, IonThumbnail, isPlatform, IonIcon } from '@ionic/react';
import { Swiper, SwiperSlide } from 'swiper/react';
// import { Zoom } from 'swiper';

import { chevronBackOutline } from 'ionicons/icons';
import React from 'react';

import 'swiper/css/zoom';

const Modal = styled(IonModal)`
  --background: rgba(0, 0, 0, 0.75);
`;

export const ImageModal = ({ showModal, setShowModal, modalImage }: any) => {
  return (
    <Modal isOpen={showModal}>
      <IonIcon
        color="light"
        icon={chevronBackOutline}
        onClick={() => setShowModal(false)}
        size="large"
        style={{
          padding: '15px 10px',
          marginTop: isPlatform('ios') ? '30px' : 'auto',
        }}
      />
      <Swiper
        // modules={[Zoom]}
        zoom={true}
        // options={{
        //   zoom: { maxRatio: 5 },
        // }}
      >
        <SwiperSlide
        // style={{ zoom: { maxRatio: 2 } }}
        >
          <div className="swiper-zoom-container">
            <IonThumbnail
              className="swiper-zoom-target"
              style={{
                backgroundImage: modalImage,
                backgroundSize: 'contain',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                // @ts-ignore
                '--size': isPlatform('desktop') ? '100%' : '100vw',
                height: '100vh',
              }}
            />
          </div>
        </SwiperSlide>
      </Swiper>
    </Modal>
  );
};
