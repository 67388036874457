import {
  IonButton,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonLabel,
} from '@ionic/react';
import {
  arrowForwardOutline,
  checkmarkOutline,
  closeOutline,
  heart,
  pencilOutline,
  shirt,
  trashOutline,
} from 'ionicons/icons';
import { round } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';

import _ from 'lodash';
import fitbitIcon from '../assets/fitbit.svg';
import { UserContext } from '../context/UserContext';
import { useActiveRoute } from '../hooks/useActiveRoute';
import constants from '../util/constants';
import { customizeDistance } from '../util/customizeDistance';
import { normalizeDistance } from '../util/normalizeDistance';
import { renderDistance } from '../util/renderDistance';
import MyDatePicker from './MyDatePicker';

export const MileageForm = ({
  autoFocus,
  log,
  onClose,
}: {
  autoFocus?: boolean;
  log?: any;
  onClose?(n?: number): void;
}) => {
  const { user, createLog, deleteLog, updateLog } = useContext(UserContext);
  const {
    mileage: userMileage,
    payment_status,
    showPaywall,
  } = useActiveRoute();
  const [isEditing, setIsEditing] = useState(!log);
  const [mileage, setMileage] = useState<number | undefined>(log?.mileage);
  const [date, setDate] = useState(
    moment(log?.date || parseInt(log?.created_at) || new Date()).format(
      'YYYY-MM-DD'
    )
  );
  const [isDisabled, setIsDisabled] = useState(false);
  const { t } = useTranslation();

  const mileageInputRef = useRef<HTMLIonInputElement>(null);

  const handleChange = (e: any) => {
    if (e.detail.value >= 0 && e.detail.value !== '') {
      setMileage(parseFloat(e.detail.value));
    }
  };

  const handleSubmit = useCallback(
    async (e: React.MouseEvent) => {
      e.preventDefault();
      setIsDisabled(true);

      // Retrieve the latest mileage value from the input ref
      const inputMileage = mileageInputRef.current?.value;
      const parsedMileage = parseFloat(inputMileage as string);

      if (isNaN(parsedMileage) || parsedMileage < 0) {
        alert(t('components.MileageForm.InvalidMileage'));
        setIsDisabled(false);
        return;
      }

      setMileage(parsedMileage);

      // Convert to km if the user's settings are miles
      let mileageInKm =
        user?.user_settings.distance_unit === constants.DISTANCE_UNITS.MILES
          ? normalizeDistance(parsedMileage, user?.user_settings.distance_unit)
          : parsedMileage;

      // if user has not upgraded
      if (!payment_status) {
        const newUserTotalMileage =
          userMileage + mileageInKm - (log ? log.mileage : 0);
        if (userMileage >= constants.PAYWALL_DISTANCE) {
          mileageInKm = 0;
        } else if (newUserTotalMileage >= constants.PAYWALL_DISTANCE) {
          // else set the mileage value as the difference between the mileage limit and the user's current mileage
          mileageInKm = _.max([
            constants.PAYWALL_DISTANCE -
              (log ? userMileage - (log.mileage as number) : userMileage),
            0,
          ]) as number;
        }
      }
      // If we have some mileage to log
      if (mileageInKm) {
        if (log) {
          await updateLog!({
            id: log.id,
            date,
            mileage: mileageInKm,
          });
          setMileage(mileageInKm);
          setIsEditing(false);
        } else {
          if (parsedMileage > 30) {
            let confirm = window.confirm(
              t('components.MileageForm.ConfirmMiles')
            );
            if (confirm) {
              await createLog!({
                date,
                mileage: mileageInKm,
              });
            }
          } else {
            await createLog!({
              date,
              mileage: mileageInKm,
            });
          }
        }
      }

      // If the user entered nothing in the input field
      if (!parsedMileage) {
        alert(t('components.MileageForm.NoMileage'));
      } else {
        onClose && onClose(mileageInKm);
        setMileage(() => undefined);
      }
      setIsDisabled(false);
    },
    [
      user,
      userMileage,
      payment_status,
      log,
      createLog,
      updateLog,
      t,
      date,
      onClose,
    ]
  );

  const deleteLogEntry = async (e: any) => {
    e.preventDefault();
    try {
      await deleteLog!({ id: log.id });
    } catch (e) {
      // user might spam click and trigger this twice.
      // @TODO debounce?
    }
    setMileage(() => undefined);
  };

  const startEditing = () => {
    setMileage(
      round(
        customizeDistance(mileage || 0, user?.user_settings.distance_unit),
        1
      )
    );
    setIsEditing(true);
  };

  const displayIcon = (log: any) => {
    switch (log.source) {
      case 'fitbit':
        return (
          <>
            <small style={{ color: 'grey' }}>Fitbit</small>
            <IonImg
              id={log.source}
              src={fitbitIcon}
              slot="end"
              style={{ width: '20px' }}
            />
          </>
        );
      case 'under_armour':
        return (
          <>
            <small style={{ color: 'grey' }}>Under Armour</small>
            <IonIcon
              id={log.source}
              color="danger"
              icon={shirt}
              size="small"
              slot="end"
            />
          </>
        );
      default:
        return (
          <>
            <small style={{ color: 'grey' }}>HealthKit</small>
            <IonIcon
              id={log.source}
              color="danger"
              icon={heart}
              size="small"
              slot="end"
            />
          </>
        );
    }
  };

  return (
    <div style={{ margin: 'auto', maxWidth: '940px' }}>
      {isEditing ? (
        <IonItem
          style={{
            // @ts-ignore
            '--background': 'transparent',
            backgroundColor: 'transparent',
          }}
          disabled={showPaywall}
        >
          <IonInput
            ref={mileageInputRef}
            autoFocus={autoFocus}
            inputMode="decimal"
            name="mileage"
            min="0"
            onIonChange={handleChange}
            placeholder={`${t('components.MileageForm.Enter')} ${t(
              `${user?.user_settings.distance_unit}`
            )}`}
            type="number"
            value={mileage}
            role="textbox"
          />
          <MyDatePicker
            onChange={(value: string) => {
              setDate(moment(value).format('YYYY-MM-DD'));
            }}
            value={date}
          />
          {log && (
            <IonButton
              color="light"
              onClick={() => setIsEditing(false)}
              size="small"
              slot="end"
              id="close"
            >
              <IonIcon icon={closeOutline} />
            </IonButton>
          )}
          <IonButton
            id="create-log"
            color="dark"
            onClick={handleSubmit}
            type="submit"
            size="small"
            slot="end"
            disabled={isDisabled}
            role="button"
          >
            {log ? (
              <IonIcon icon={checkmarkOutline} />
            ) : (
              <>
                {t('components.MileageForm.LOG')} &nbsp;
                <IonIcon
                  icon={arrowForwardOutline}
                  style={{ color: 'var(--color-primary-500)' }}
                />
              </>
            )}
          </IonButton>
        </IonItem>
      ) : (
        <IonItem key={log.date || log.created_at}>
          <IonLabel>
            <h3>
              {log.date ? (
                <Moment format="MMM D">{log.date}</Moment>
              ) : (
                <Moment format="MMM D" unix>
                  {Math.floor(log.created_at / 1000)}
                </Moment>
              )}
              {log.category ? (
                <em style={{ color: 'grey' }}> {log.category}</em>
              ) : null}
            </h3>
            <p>
              {renderDistance(log.mileage, user?.user_settings.distance_unit)}
            </p>
          </IonLabel>
          {log.source ? (
            displayIcon(log)
          ) : (
            <>
              <IonIcon
                icon={pencilOutline}
                size="small"
                slot="end"
                onClick={startEditing}
                id="edit"
              />
              <IonIcon
                icon={trashOutline}
                size="small"
                slot="end"
                onClick={deleteLogEntry}
                id="delete"
              />
            </>
          )}
        </IonItem>
      )}
    </div>
  );
};
