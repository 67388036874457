import { gql } from 'apollo-boost';

export const GROUP_TYPE = `
conversation { id }
name
public
owner {
  avatar
  id
  first_name
}
route_id
secret
users {
  avatar
  first_name
  id
  last_name
  user_settings {
    private
  }
  route_settings {
    mileage
    route_id
  }
}
`;

export const GROUPS = gql`
  {
    groups {
      ${GROUP_TYPE}
    }
  }
`;

export const FETCH_GROUP = gql`
    query group($secret: String!){
    group(secret: $secret) {
      ${GROUP_TYPE}
    }
  }
`;

export const CREATE_GROUP = gql`
  mutation createGroup($name: String!, $public: Boolean!, $route_id: Int) {
    createGroup(name: $name, public: $public, route_id: $route_id) {
      ${GROUP_TYPE}
    }
  }
`;

export const JOIN_GROUP = gql`
  mutation joinGroup($secret: String) {
    joinGroup(secret: $secret) {
      ${GROUP_TYPE}
    }
  }
`;

export const LEAVE_GROUP = gql`
  mutation leaveGroup($secret: String) {
    leaveGroup(secret: $secret) {
      ${GROUP_TYPE}
    }
  }
`;

export const DELETE_GROUP = gql`
  mutation deleteGroup($secret: String) {
    deleteGroup(secret: $secret) {
      groups {
        ${GROUP_TYPE}
      }
    }
  }
`;

export const UPDATE_GROUP = gql`
  mutation updateGroup($secret: String, $options: GroupOptions) {
    updateGroup(secret: $secret, options: $options) {
      ${GROUP_TYPE}
    }
  }
`;
