import React from 'react';

export const ModalHeader = ({ children, height, imageUrl, maxHeight }: any) => (
  <div
    style={{
      height: height || '40vh',
      maxHeight: maxHeight || '410px',
      backgroundImage: `linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.67)), url(${imageUrl})`,
      backgroundSize: 'cover',
      backgroundPosition: '50%',
      display: 'flex',
      alignItems: 'flex-end',
      padding: '10px',
      color: 'white',
    }}
  >
    {children}
  </div>
);
