export function useQueryParams<T>() {
  const params = new URLSearchParams(window.location.search);
  // @ts-ignore
  const values: T = {};
  //@ts-ignore Type 'URLSearchParams' is not an array type or a string type. Use compiler option '--downlevelIteration' to allow iterating of iterators.ts
  for (let [k, v] of params) {
    // @ts-ignore
    values[k] = v;
  }

  return values;
}
