import React from 'react';

import { IDestination } from '../../context/DestinationContext';
import { P, Text } from '../Text';

type Props = {
  destination: IDestination;
};

export const DestinationDescription: React.FC<Props> = ({ destination }) => (
  <>
    {destination.description && (
      <P>
        <br />
        <span
          dangerouslySetInnerHTML={{
            __html: destination.description,
          }}
        />
      </P>
    )}
    {destination.quote && (
      <Text className="quote">
        <div
          style={{
            background: '#f4f4f4',
            padding: '15px 20px 1px',
            borderRadius: '4px',
            margin: '10px 0',
          }}
          dangerouslySetInnerHTML={{
            __html: destination.quote,
          }}
        />
      </Text>
    )}
    {destination.tip && (
      <>
        <P style={{ fontWeight: 'bold', marginTop: '20px' }}>Walking Tip</P>
        <P style={{ marginTop: '20px' }}>
          <span
            dangerouslySetInnerHTML={{
              __html: destination.tip,
            }}
          />
        </P>
      </>
    )}
  </>
);
