import {
  IonAlert,
  IonCol,
  IonContent,
  IonFab,
  IonFabButton,
  IonIcon,
  IonItem,
  IonList,
  IonRow,
} from '@ionic/react';
import { addOutline as add } from 'ionicons/icons';
import { defer, groupBy, map, orderBy } from 'lodash';
import moment from 'moment';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Preferences } from '@capacitor/preferences';
import { Button } from '../../components/Button/Button';
import { Message } from '../../components/Message/Message';
import { H2, Text } from '../../components/Text';
import { Group } from '../../context/GroupContext';
import {
  MessageContext,
  Message as MessageType,
} from '../../context/MessageContext';
import { User } from '../../context/UserContext';
import { useBoolean } from '../../hooks/useBoolean';

type Props = {
  inGroup: boolean;
  group: Group;
  user: User;
  onload: () => void;
};

export const Messages = ({ inGroup, group, user, onload }: Props) => {
  const [isOpen, openModal, closeModal] = useBoolean(false);
  const [messages, setMessages] = useState<MessageType[]>([]);
  const { createMessage, deleteMessage, fetchMessages } =
    useContext(MessageContext);
  // TODO - Not sure why lastViewed is set but never used @fabio?
  // eslint-disable-next-line
  const [lastViewed, setLastViewed] = useState('');
  const alertRef = React.createRef<any>();

  const fetch = useCallback(async () => {
    const { value } =
      (await Preferences.get({
        key: 'messages',
      })) || {};
    const lastViewedMap = value ? { ...JSON.parse(value) } : {};
    const conversationId = group.conversation.id!;

    if (lastViewedMap[conversationId]) {
      setLastViewed(lastViewedMap[conversationId]);
    }

    lastViewedMap[conversationId] = moment().format('x');
    await Preferences.set({
      key: 'messages',
      value: JSON.stringify(lastViewedMap),
    });
    group.conversation.id &&
      setMessages(await fetchMessages(group.conversation.id));
  }, [fetchMessages, group.conversation.id]);

  useEffect(() => {
    (async () => {
      await fetch();
      onload();
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const onDidPresent = () => {
    defer(() => alertRef.current.querySelector('textarea').focus());
  };

  const onSubmit = async ({ message }: { message: string }) => {
    // @FIXME: This is a quick hack for not submitting an empty message, but the UX is not great.
    if (message.length) {
      await createMessage(group.conversation.id!, message);
      await fetch();
    }
  };

  const onDelete = async (message: MessageType) => {
    if (window.confirm('Are you sure you want to delete this message?')) {
      await deleteMessage(message);
      await fetch();
    }
  };

  const groupedMessages = groupBy(
    orderBy(messages, ['created_at'], ['desc']),
    (message) => {
      return moment(message.created_at, 'x').format('MMM Do, YYYY');
    }
  );
  const { t } = useTranslation();

  return (
    <IonContent>
      {messages.length === 0 && (
        <IonRow>
          <IonCol
            sizeMd="12"
            style={{
              marginTop: '20px',
              textAlign: 'center',
            }}
          >
            <H2>{t('components.MESSAGES.NO_MESSAGES')}</H2>
            <Text style={{ padding: '20px 0' }}>
              {t('components.MESSAGES.CREATE_A_MESSAGE')}
            </Text>

            <Button onClick={openModal} color={'primary'}>
              <IonIcon icon={add} /> Post a message
            </Button>
          </IonCol>
        </IonRow>
      )}
      <IonList>
        {map(groupedMessages, (messages, date) => (
          <div key={date}>
            <IonItem>
              <strong>{date}</strong>
            </IonItem>
            {map(messages, (message) => (
              <Message
                key={message.id}
                message={message}
                owner={
                  message.user.id === user.id || user.id === group.owner.id
                }
                onDelete={onDelete}
              />
            ))}
          </div>
        ))}
      </IonList>

      {inGroup && (
        <>
          <IonFab vertical="bottom" horizontal="end" slot="fixed">
            <IonFabButton id="msgBtn" onClick={openModal}>
              <IonIcon icon={add} />
            </IonFabButton>
          </IonFab>
          <IonAlert
            id="msgAlert"
            onDidPresent={onDidPresent}
            ref={alertRef}
            buttons={[
              {
                text: 'Cancel',
                role: 'cancel',
              },
              {
                text: 'Save',
                handler: onSubmit,
              },
            ]}
            header="Post a message"
            inputs={[
              {
                name: 'message',
                type: 'textarea',
              },
            ]}
            isOpen={isOpen}
            onDidDismiss={closeModal}
          />
        </>
      )}
    </IonContent>
  );
};
