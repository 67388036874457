import { IonItem, IonIcon, IonActionSheet, IonPopover } from '@ionic/react';
import {
  clipboardOutline,
  optionsOutline as cog,
  addCircle as add,
} from 'ionicons/icons';
import _ from 'lodash';
import React, { useCallback, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components/Button/Button';
import { GroupForm } from '../../components/GroupForm';
import { GroupContext } from '../../context/GroupContext';
import { UserContext } from '../../context/UserContext';
import { useBoolean } from '../../hooks/useBoolean';
import { Clipboard } from '@capacitor/clipboard';
const copyUrl = function (string: string) {
  try {
    Clipboard.write({ string });
  } catch (e) {}
};
export const GroupSettings = ({ group, style }: any) => {
  const { user, setUser } = useContext(UserContext);

  const { deleteGroup, joinGroup, leaveGroup } = useContext(GroupContext);
  const { t } = useTranslation();

  const [showActions, openActions, closeActions] = useBoolean(false);
  const [isShareOpen, openShare, closeShare] = useBoolean(false);
  const inGroup = useCallback<() => boolean>(
    () => _.findIndex(user?.groups, { secret: group?.secret }) !== -1,
    [group, user]
  );
  const getShareLink = useCallback(
    () => `${process.env.REACT_APP_FRONTEND}/dashboard/group/${group.secret}`,
    [group]
  );
  const [isEditing, setEditing] = useState(false);
  const myGroups = user?.groups || [];

  const onDelete = async (e?: React.MouseEvent) => {
    e && e.preventDefault();

    await deleteGroup(group!.secret);

    window.location.pathname = '/dashboard/groups';
  };

  const onJoin = async (e?: React.MouseEvent) => {
    e && e.preventDefault();

    try {
      const groupObject = await joinGroup(group!.secret);

      setUser({
        groups: _.uniqBy([...myGroups, groupObject], 'secret'),
      });
    } catch (e) {
      // if Group has been deleted and a user tries to join group
      // they will be redirected to the Group page
      console.error('Error Joining Group:', e);
      window.location.pathname = '/dashboard/groups';
    }
  };

  const onLeave = async () => {
    try {
      await leaveGroup(group!.secret);

      setUser({
        groups: _.filter(
          myGroups,
          (groupObject) => groupObject.secret !== group!.secret
        ),
      });
    } catch (err) {
      // if Group has been deleted and a user tries to leave group
      // they will be redirected to the Group page
      console.error('Error Leaving Group:', err);
      window.location.pathname = '/dashboard/groups';
    }
  };

  const isOwner = useCallback<() => boolean>(
    () => group?.owner?.avatar === user?.avatar,
    [group, user]
  );

  return (
    <>
      {inGroup() ? (
        <Button onClick={openActions} size="small" style={style}>
          <IonIcon icon={cog} style={{ opacity: 0.75 }} />
        </Button>
      ) : (
        <Button color="light" onClick={openActions} size="small" style={style}>
          <IonIcon color="primary" icon={add} style={{ marginRight: '5px' }} />
          <span>{t('pages.group.JOIN')}</span>
        </Button>
      )}
      <IonActionSheet
        isOpen={showActions}
        onDidDismiss={closeActions}
        buttons={[
          { text: t('pages.group.SHARE'), handler: () => openShare() },
          ...(isOwner()
            ? [
                {
                  text: t('pages.group.EDIT'),
                  handler: () => setEditing(true),
                },
                {
                  text: t('pages.group.DELETE'),
                  role: 'destructive',
                  handler: () => onDelete(),
                },
              ]
            : [
                {
                  text: inGroup()
                    ? t('pages.group.LEAVE')
                    : t('pages.group.JOIN'),
                  handler: () => {
                    inGroup() ? onLeave() : onJoin();
                  },
                },
              ]),
        ]}
      />
      <IonPopover
        // cssClass="group-form"
        isOpen={isEditing}
        onDidDismiss={() => setEditing(false)}
      >
        <GroupForm
          onDismiss={() => setEditing(false)}
          group={group}
          onSave={(params) => {
            // setGroup(params?.updatedGroup);
            setEditing(false);
          }}
        />
      </IonPopover>
      <IonPopover
        animated
        // cssClass="group-form"
        isOpen={isShareOpen}
        onDidDismiss={closeShare}
        mode="ios"
      >
        <div style={{ padding: '20px' }}>
          <IonItem lines="none">
            <h2
              style={{
                textAlign: 'center',
                width: '100%',
              }}
            >
              {t('pages.group.SHARETHIS')}
            </h2>
          </IonItem>
          <IonItem
            lines="none"
            style={{
              textAlign: 'center',
            }}
          >
            <p
              style={{
                borderRadius: '5px',
                border: '1px solid #ccc',
                background: 'var( --ion-color-light-tint)',
                color: 'var(--color-primary-600',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                padding: '12px 8px',
              }}
            >
              {getShareLink()}
            </p>
          </IonItem>
          <div style={{ textAlign: 'center' }}>
            <Button
              onClick={() => {
                copyUrl(getShareLink());
              }}
            >
              Copy{' '}
              <IonIcon
                style={{ color: 'var(--color-primary-500)' }}
                icon={clipboardOutline}
              />
            </Button>
          </div>
        </div>
      </IonPopover>
    </>
  );
};
