import {
  IonFab,
  IonFabButton,
  IonPopover,
  IonIcon,
  IonToast,
  useIonAlert,
} from '@ionic/react';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GroupForm } from '../components/GroupForm';
import { GroupList } from '../components/GroupList';
import { Toolbar } from '../components/Toolbar';
import { PageWrapper } from '../components/PageWrapper';
import { add } from 'ionicons/icons';
import { GroupContext } from '../context/GroupContext';
import { useActiveRoute } from '../hooks/useActiveRoute';
import { useLocation } from 'react-router-dom';

export const Groups = () => {
  const { loading, loadGroups } = useContext(GroupContext);
  const [showAlert] = useIonAlert();
  const { t } = useTranslation();
  const { route } = useActiveRoute();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [showToast, setShowToast] = useState(false);
  const location = useLocation<{ deleted: boolean }>();

  useEffect(() => {
    if (location.state?.deleted) {
      showAlert({
        header: t('pages.group.SORRY'),
        message: t('pages.group.GROUP_DOES_NOT_EXIST'),
        buttons: [
          {
            text: t('common.OK'),
          },
        ],
      });
    }
    loadGroups();
  }, [route.id, location]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Toolbar />
      <PageWrapper loading={loading}>
        <GroupList />
        <IonPopover
          // cssClass="group-form"
          isOpen={isModalOpen}
          onDidDismiss={() => setModalOpen(false)}
        >
          <GroupForm
            onDismiss={() => setModalOpen(false)}
            onSave={() => {
              setModalOpen(false);
              setShowToast(true);
            }}
          />
        </IonPopover>

        {/* TODO: Move to a NotificationContext */}
        <IonToast
          color="dark"
          duration={3000}
          isOpen={showToast}
          onDidDismiss={() => setShowToast(false)}
          message="Changes saved."
          position="bottom"
        />
      </PageWrapper>
      <IonFab vertical="bottom" horizontal="end">
        <IonFabButton onClick={() => setModalOpen(!isModalOpen)}>
          <IonIcon icon={add} />
        </IonFabButton>
      </IonFab>
    </>
  );
};
