import React from 'react';

type Props = {
  children?: React.ReactNode;
  disabled?: boolean;
  href?: string;
  onClick?(e?: React.SyntheticEvent): void;
};

export const Link: React.FC<Props> = ({
  children,
  disabled,
  href = undefined,
  onClick = undefined,
}) => {
  const props = {
    disabled: disabled,
    style: {
      backgroundColor: 'transparent',
      color: '#376492',
      display: 'inline-block',
      textDecoration: 'underline',
    },
  };
  return href ? (
    <a href={href} {...props}>
      {children}
    </a>
  ) : (
    <button onClick={onClick} {...props}>
      {children}
    </button>
  );
};
