import { useIonRouter } from '@ionic/react';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DestinationProvider } from '../context/DestinationContext';
import { ImageProvider } from '../context/ImageContext';
import { MessageProvider } from '../context/MessageContext';
import { PathProvider } from '../context/PathContext';
import { RouteContext } from '../context/RouteContext';
import { UserContext } from '../context/UserContext';
import { LoadingRoute } from './LoadingRoute';
import { Login } from './Login';

import { SplashScreen } from '@capacitor/splash-screen';

export const Loading = ({
  privateRoute = false,
  component: Component = Login,
  prompt: LoginPrompt = Login,
  ...restProps
}: any) => {
  const { loading: userLoading, loggedIn, user } = useContext(UserContext);
  const { loading: routesLoading } = useContext(RouteContext);
  const { i18n } = useTranslation();
  const router = useIonRouter();

  useEffect(() => {
    if (!(userLoading || routesLoading)) {
      i18n.changeLanguage(user?.language);
    }
  }, [i18n, routesLoading, user?.language, userLoading]);

  useEffect(() => {
    if (privateRoute) {
      if (
        (!userLoading && loggedIn && !routesLoading) ||
        (!userLoading && !loggedIn) // Not logged in. Hide the splash screen
      ) {
        setTimeout(SplashScreen.hide, 1000);
      }
    } else {
      if (!userLoading) {
        setTimeout(SplashScreen.hide, 1000);
      }
    }
  }, [loggedIn, userLoading, routesLoading, privateRoute]);

  useEffect(() => {
    return () => {
      SplashScreen.hide();
    };
  });

  useEffect(() => {
    if (privateRoute) {
      if (!userLoading && !routesLoading) {
        if (!loggedIn) {
          router.push('/login'); // Redirect to login page if not logged in
        } else {
          SplashScreen.hide();
        }
      }
    }
  }, [userLoading, loggedIn, routesLoading, privateRoute, router]);

  const renderComponent = () => {
    // Public Route, we don't care just show the component
    if (!privateRoute) {
      if (!userLoading && !routesLoading) {
        return (
          <>
            <Component {...restProps} />;
          </>
        );
      }
    } else {
      // Private route
      // User has been loaded
      if (!userLoading && !routesLoading) {
        // User is logged
        return (
          <DestinationProvider>
            <ImageProvider>
              <PathProvider>
                <MessageProvider>
                  <>
                    {/* LOADING THIS COMPONEINTN? */}
                    <LoadingRoute component={Component} {...restProps} />
                  </>
                </MessageProvider>
              </PathProvider>
            </ImageProvider>
          </DestinationProvider>
        );
      }
    }
  };

  return <>{renderComponent()}</>;
};
