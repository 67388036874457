import { IonIcon } from '@ionic/react';
import { playCircle, pauseCircle } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { audioPlayer } from '../../util/audio';
import { Button } from '../Button/Button';
import { Caption } from '../Text';

export const AudioButton = ({
  audio,
  disabled,
  text = '',
  ...rest
}: {
  audio: string;
  disabled?: boolean;
  slot?: string;
  text?: string;
}) => {
  const [isPlaying, setIsPlaying] = useState(null);
  const { t } = useTranslation();

  const play = () => {
    if (!isPlaying) {
      audioPlayer.play();
    } else if (isPlaying) {
      audioPlayer.pause();
    }
    // @ts-ignore
    setIsPlaying(!isPlaying);
  };

  const restart = () => {
    audioPlayer.restart();
  };

  useEffect(() => {
    audioPlayer.load(
      `https://camino-for-good.s3.us-east-2.amazonaws.com/public/audio/${audio}`
    );
    return () => {
      audioPlayer.pause();
      audioPlayer.release();
    };
  }, [audio]);

  return (
    <div>
      <Button
        color={isPlaying ? 'primary' : 'dark'}
        disabled={disabled}
        onClick={play}
        size="default"
        style={{
          width: '100%',
        }}
        {...rest}
      >
        <IonIcon
          color={isPlaying ? 'dark' : 'primary'}
          icon={isPlaying ? pauseCircle : playCircle}
          size="large"
          slot="start"
          role="img"
        />
        {isPlaying ? t('components.AudioButton.PAUSE') : text}
      </Button>
      <div
        onClick={restart}
        style={{
          color: isPlaying ? 'grey' : 'white',
          textAlign: 'center',
        }}
      >
        <Caption id="something">{t('components.AudioButton.RESTART')}</Caption>
      </div>
    </div>
  );
};
