import styled from '@emotion/styled';
import { IonAvatar } from '@ionic/react';
import React from 'react';

import { PublicUser } from '../../context/UserContext';

type StyledProps = {
  isActive?: boolean;
  size?: string;
};

type Props = StyledProps & {
  showName?: boolean;
  user: PublicUser;
};

const StyledAvatar = styled(IonAvatar)<StyledProps>`
  ${(p) => (p.size ? { height: p.size, width: p.size } : {})}

  img {
    box-shadow: ${(p) =>
      p.isActive ? '0 0 0 2px white, 0 0 0 4px var(--color-primary-500)' : ''};
  }
`;

const StyledWrapper = styled.div`
  align-items: center;
  display: grid;
  font-size: 10px;
  grid-gap: 4px;
  padding: 5px;
  justify-content: center;
  text-align: center;
`;

export const UserAvatar: React.FC<Props> = ({
  isActive,
  showName = true,
  size,
  user,
}) => (
  <StyledWrapper
    onClick={
      () => (window.location.pathname = `/dashboard/user/${user.id}`) // FIXME VEIKO
    }
  >
    <StyledAvatar isActive={isActive} size={size}>
      <img alt={`${user.first_name} ${user.last_name}`} src={user.avatar} />
    </StyledAvatar>
    {showName && (
      <span>
        {user.first_name} {user.last_name.charAt(0)}
      </span>
    )}
  </StyledWrapper>
);
