import React, { useContext, useEffect, useState } from 'react';
import { InAppPurchase2, IAPProduct } from '@ionic-native/in-app-purchase-2';
import { IonToast, IonButton } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { Product } from '../../context/RouteContext';
import { UserContext } from '../../context/UserContext';
import constants from '../../util/constants';

interface Props {
  onSuccess(): void;
  product: Product;
}
let hasRefreshed = false;

export const PaymentInApp = ({ onSuccess, product }: Props) => {
  const { user } = useContext(UserContext);
  const [processing, setProcessing] = useState(false);
  const [toast, setToast] = useState<string | false>(false);
  const [currency, setCurrency] = useState<string>('USD');
  const [validationError, setValidationError] = useState<string>('');
  const { t } = useTranslation();

  const showCurrency = (price: number) =>
    price.toLocaleString('en-US', {
      style: 'currency',
      currency: currency,
    });

  useEffect(() => {
    handleRefresh();
  }, [product]);

  useEffect(() => {
    const productId = product.external_id;

    InAppPurchase2.validator = `${constants.ENDPOINT}/check-purchase/${user.id}`;

    InAppPurchase2.when(productId).cancelled((p: IAPProduct) => {
      setProcessing(false);
    });

    InAppPurchase2.when(productId).unverified((p: IAPProduct) => {
      p.finish();
    });

    InAppPurchase2.when(productId).verified((p: IAPProduct) => {
      p.finish();
    });

    InAppPurchase2.when(productId).updated((p: IAPProduct) => {
      setCurrency(p.currency);
    });

    InAppPurchase2.when(productId).approved((p: IAPProduct) => {
      p.verify();
      p.finish();
    });

    InAppPurchase2.when(productId).finished(async () => {
      setToast('Purchase successful. We hope you enjoy the virtual Camino!');

      setProcessing(false);
      onSuccess();
    });
    InAppPurchase2.error(function (error: any) {
      setValidationError(error.message);
      console.log('ERROR ' + error.code + ': ' + error.message);
    });

    handleRefresh();
    // eslint-disable-next-line
  }, []);

  const handleRefresh = async () => {
    setProcessing(true);
    if (!hasRefreshed) await InAppPurchase2.refresh();
    hasRefreshed = true;
    setProcessing(false);
  };

  const handlePurchase = () => {
    setProcessing(() => {
      InAppPurchase2.order(product.external_id);
      return true;
    });
  };

  return (
    <>
      <IonButton expand="block" disabled={processing} onClick={handlePurchase}>
        {/* if processing then display a processing message to user */}
        {processing
          ? t('components.PaymentInApp.Processing')
          : // else display price
            `${t('components.PaymentInApp.Upgrade')} ${showCurrency(
              product.amount / 100
            )}`}
      </IonButton>
      {validationError && (
        <div
          style={{
            color: 'var(--ion-color-danger)',
            padding: '5px',
            textAlign: 'center',
          }}
        >
          {validationError}
        </div>
      )}
      <IonToast
        onDidDismiss={() => setToast(false)}
        isOpen={!!toast}
        message={toast || ''}
        duration={2000}
      />
    </>
  );
};
