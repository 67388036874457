import { gql } from 'apollo-boost';

export const GET_ROUTES = gql`
  query GetRoutes {
    routes {
      id
      category_id
      meta {
        distance
        milestones
        images
        places {
          en
          es
        }
        waypoints
      }
      description {
        en
        es
      }
      route_image
      certificate_image_code
      name
      path
      product {
        amount
        currency
        external_id
        type
      }
      version
    }
  }
`;

export const GET_SUBSCRIPTIONS = gql`
  query GetSubscriptions {
    subscriptions {
      product {
        amount
        currency
        external_id
        type
      }
    }
  }
`;
