import constants from './constants';

const {
  DISTANCE_UNITS: { MILES, MULTIPLIER },
} = constants;

export const normalizeDistance = (
  distance: number,
  distance_unit: string = constants.DISTANCE_UNITS.DEFAULT
) => {
  return distance_unit === MILES ? distance / MULTIPLIER : distance;
};
