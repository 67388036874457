import styled from '@emotion/styled';
import {
  IonIcon,
  IonItem,
  IonItemSliding,
  IonItemOption,
  IonItemOptions,
  isPlatform,
} from '@ionic/react';
import { trashOutline as deleteIcon } from 'ionicons/icons';
import moment from 'moment';
import React from 'react';

import { UserAvatar } from '../../components/UserAvatar/UserAvatar';
import { Message as MessageType } from '../../context/MessageContext';
import { Caption } from '../Text';

const StyledMessage = styled.div`
  display: grid;
  grid-gap: 2px;
  grid-template-columns: 1fr 1fr;
  padding: 20px;
  width: 100%;
`;

export const Message = ({
  message,
  onDelete,
  owner,
}: {
  message: MessageType;
  onDelete: Function;
  owner: boolean;
}) => {
  return (
    <IonItemSliding>
      <IonItem>
        <UserAvatar
          isActive={owner}
          showName={false}
          user={message.user}
          {...(isPlatform('desktop') ? { size: '40px' } : {})}
        />

        <StyledMessage>
          <Caption>
            <strong>
              {message.user.first_name} {message.user.last_name}
            </strong>
          </Caption>
          <div>
            <Caption
              color="var(--color-text-faded)"
              style={{ textAlign: 'right' }}
            >
              {moment(message.created_at, 'x').fromNow()}
            </Caption>
          </div>
          <div
            style={{
              alignItems: 'center',
              display: 'flex',
              gridColumn: '1 / 3',
            }}
          >
            {message.content}
            {isPlatform('desktop') && owner && (
              <>
                &nbsp;&nbsp;
                <Caption
                  color="var(--color-text-faded)"
                  inline
                  lineHeight="14px"
                  onClick={() => onDelete(message)}
                >
                  <IonIcon icon={deleteIcon} />
                </Caption>
              </>
            )}
          </div>
        </StyledMessage>
      </IonItem>
      {!isPlatform('desktop') && owner && (
        <IonItemOptions side="end">
          <IonItemOption color="danger" onClick={() => onDelete(message)}>
            <IonIcon icon={deleteIcon} />
          </IonItemOption>
        </IonItemOptions>
      )}
    </IonItemSliding>
  );
};
