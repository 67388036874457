import { IonAvatar } from '@ionic/react';
import React, { CSSProperties } from 'react';

export const Avatar: React.FC<{
  alt?: string;
  image?: string;
  size?: number;
  slot?: any;
  style?: CSSProperties;
}> = ({ alt, image, size = 100, slot, style }) => {
  const dimension = `${size}px`;

  return (
    <IonAvatar
      {...(slot ? { slot } : {})}
      style={{ height: dimension, width: dimension, ...style }}
    >
      <img
        alt={alt || 'user photo'}
        src={image}
        style={{ height: dimension, width: dimension }}
      />
    </IonAvatar>
  );
};
