import {
  IonContent,
  IonFab,
  IonFabButton,
  IonModal,
  IonIcon,
  isPlatform,
} from '@ionic/react';
import { last } from 'lodash';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNotification } from '../../context/NotificationContext';
import { usePayment } from '../../hooks/usePayment';

import { P } from '../Text';
import { DestinationContext } from '../../context/DestinationContext';
import { Product } from '../../context/RouteContext';
import { UserContext } from '../../context/UserContext';
import { closeOutline } from 'ionicons/icons';
import { ModalHeader } from '../ModalHeader';
import { Button } from '../Button/Button';

interface Props {
  isOpen: boolean;
  onClose?(): void;
  product: Product;
}

export const CancelModal = ({ isOpen, onClose = () => {} }: Props) => {
  const { destinations } = useContext(DestinationContext);
  const { setUser } = useContext(UserContext);
  const { notify } = useNotification();
  const { cancelSubscription } = usePayment(true);

  const { t } = useTranslation();

  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();

    try {
      const user = await cancelSubscription();
      await setUser(user);
      onClose();
      notify(t('components.STOP_SUBSCRIPTION.SORRY'));
    } catch (e) {
      console.log('error:', e);
    }
  };

  return (
    <IonModal onDidDismiss={onClose} isOpen={isOpen}>
      <div>
        <IonFab vertical="top" horizontal="end">
          <IonFabButton color="dark" onClick={onClose} size="small">
            <IonIcon icon={closeOutline} size="small" />
          </IonFabButton>
        </IonFab>
        <ModalHeader
          height={isPlatform('desktop') ? '200px' : '40vh'}
          imageUrl={last(destinations)?.image}
          maxHeight="210px"
        />
        <IonContent
          style={{ height: isPlatform('desktop') ? '350px' : '60vh' }}
        >
          <div style={{ padding: '10px' }}>
            <P style={{ fontSize: '14px', margin: '25px 0' }}>
              <>{t('components.STOP_SUBSCRIPTION.TEXT')}</>
            </P>
            <Button color="light" onClick={onClose}>
              {t('common.CANCEL')}
            </Button>
            <Button onClick={handleSubmit}>
              {t('components.STOP_SUBSCRIPTION.STOP')}
            </Button>
          </div>
        </IonContent>
      </div>
    </IonModal>
  );
};
