import _ from 'lodash';
import React, { createContext, useCallback, useState, useEffect } from 'react';
import { useActiveRoute } from '../hooks/useActiveRoute';

export interface Image {
  id: string;
  src: string;
  author: string;
  site: string;
  lat: number;
  lng: number;
  from_start: number;
  thumbnail?: string;
  town_id: string;
}

interface IImageContext {
  Images: Image[];
  findImage(config: Partial<Image>): Image | null;
  findImagesNear(d: string): Image[];
  getNextImage(m: number): Image | null;
  getLockedImages(m: number): Image[];
  getUnlockedImages(m: number): Image[];
  isImageLocked(id: string, m: number): boolean;
  loading: boolean;
  loadImages(): Promise<void>;
}

export const ImageContext = createContext<IImageContext>({
  Images: [],
  findImage: () => null,
  findImagesNear: () => [],
  getNextImage: () => null,
  getLockedImages: () => [],
  getUnlockedImages: () => [],
  isImageLocked: () => true,
  loading: true,
  loadImages: async () => {},
});

const imageCache: { [key: string]: Image[] } = {};

export const ImageProvider = ({ children }: any) => {
  const { route } = useActiveRoute();
  const [loading, setLoading] = useState<boolean>(true);
  const [Images, setImages] = useState<Image[]>([]);

  const loadImages = useCallback(async () => {
    if (route) {
      const cacheKey = route.path;
      if (imageCache[cacheKey]) {
        setImages(imageCache[cacheKey]);
        setLoading(false);
        return;
      }

      const towns = await fetch(route.meta.images).then((res) => res.json());

      imageCache[cacheKey] = towns;
      setImages(towns);
      setLoading(false);
    }
  }, [route]);

  useEffect(() => {
    loadImages();
  }, [loadImages]);

  const findImage = (config: Partial<Image>) => _.find(Images, config) || null;

  const getLockedImages: (mileage: number) => Image[] = (mileage) =>
    _.filter(Images, (d) => d.from_start > mileage);

  const getUnlockedImages: (mileage: number) => Image[] = (mileage) =>
    _.filter(Images, (d) => d.from_start < mileage);

  const getNextImage = (m: number) => getLockedImages(m)[0];

  const isImageLocked = (src: string, mileage: number) => {
    const d = findImage({ src });
    return d ? d.from_start > mileage : true;
  };

  const findImagesNear = (town_id: string): Image[] =>
    _.filter(
      _.orderBy(Images, ['from_start'], ['desc']),
      (img) => img.town_id === town_id
    );

  return (
    <ImageContext.Provider
      value={{
        Images,
        findImage,
        findImagesNear,
        getNextImage,
        getLockedImages,
        getUnlockedImages,
        isImageLocked,
        loading,
        loadImages,
      }}
    >
      {children}
    </ImageContext.Provider>
  );
};
