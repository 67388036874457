import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { useNotification } from '../context/NotificationContext';
import { UserContext } from '../context/UserContext';

export const LanguageSettings = () => {
  const { notify } = useNotification();
  const { user, updateUser } = useContext(UserContext);
  const { i18n, t } = useTranslation();

  const handleSubmit = async (e: {
    preventDefault: () => void;
    detail: { value: string };
  }) => {
    e.preventDefault();
    try {
      await updateUser({ language: e.detail.value });
      i18n.changeLanguage(e.detail.value);
      notify(t('pages.settings.CHANGES_SAVED'));
    } catch (e) {
      console.error('Could not update user language:', e);
    }
  };

  return (
    <IonItem
      // Removed IonItem line because IonSelect was causing the line to be too long
      lines="none"
    >
      <IonLabel>{t('pages.settings.LANGUAGE')}</IonLabel>
      <IonSelect
        cancelText={t('common.CANCEL')}
        okText={t('common.OK')}
        onIonChange={handleSubmit}
        placeholder={t('pages.settings.SELECT_ONE')}
        value={user?.language}
      >
        <IonSelectOption defaultChecked value="en">
          {t('pages.settings.ENGLISH')}
        </IonSelectOption>
        <IonSelectOption value="es">
          {t('pages.settings.SPANISH')}
        </IonSelectOption>
      </IonSelect>
    </IonItem>
  );
};
