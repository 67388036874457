import moment from 'moment';
import { Preferences } from '@capacitor/preferences';

export type StorageType = boolean | number | string | null;

type TimeConfig = {
  createdAt: string;
  duration: number;
  unit?: string;
};

type GetStorageType = (key: string) => Promise<StorageType>;

type SetStorageType = (
  key: string,
  value: StorageType,
  ttl?: TimeConfig
) => void;

type StoredObject = {
  expiry?: string;
  key: string;
  type: string; // for now, support boolean and string
  value: StorageType;
};

type RemoveStorageType = (key: string) => void;

type UseLocalStorageType = () => [
  GetStorageType,
  SetStorageType,
  RemoveStorageType
];

// set Storage property with expiration date
export const setStorage: SetStorageType = (
  key: string,
  value: StorageType,
  ttl?: TimeConfig
) => {
  const finalValue = JSON.stringify({
    expiry: ttl
      ? // - Argument of type 'number' is not assignable to parameter of type 'DurationConstructor'.ts(2769)
        // @ts-ignore
        moment(ttl.createdAt)
          .add(ttl.duration, ttl?.unit || 'weeks')
          .format('MM/DD/YYYY')
      : undefined,
    key,
    type: typeof value,
    value,
  });

  Preferences.set({ key, value: finalValue });
};

// grabs Storage variable
export const getStorage: GetStorageType = async (key: string) => {
  const { value } = await Preferences.get({ key });
  // if the item doesn't exist, return null
  if (!value) return null;

  try {
    // if the value was stored as JSON
    const item = JSON.parse(value) as StoredObject;
    // If the item is expired, delete the item from storage
    // and return null
    // if (moment().isAfter(item.expiry)) {
    //   Preferences.remove({ key });
    //   return null;
    // }
    return item.value;
  } catch (e) {
    return value;
  }
};

export const removeStorage: RemoveStorageType = (key: string) => {
  try {
    Preferences.remove({ key });
  } catch (error) {
    console.log('Could not remove storage item', error);
  }
};

export const useLocalStorage: UseLocalStorageType = () => [
  getStorage,
  setStorage,
  removeStorage,
];
