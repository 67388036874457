import {
  IonChip,
  IonCol,
  IonGrid,
  IonIcon,
  IonInput,
  IonLabel,
  IonRow,
} from '@ionic/react';
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { closeCircle } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import StripeIcon from '../../assets/powered_by_stripe.svg';
import { Product } from '../../context/RouteContext';
import { usePayment } from '../../hooks/usePayment';
import { formatCurrency } from '../../util/formatCurrency';
import { isEmailValid } from '../../util/isEmailValid';
import { Button } from '../Button/Button';
import { DiscountForm, PromotionCode } from '../DiscountForm/DiscountForm';
import { Caption } from '../Text';

const stripePromise = loadStripe(
  process.env.REACT_APP_STRIPE_PUBLISH_KEY || ''
);

interface SuccessProps {
  email?: string;
  name?: string;
}

interface Props {
  email: string;
  isLoggedIn?: boolean;
  name: string;
  onCancel?(): void;
  onSuccess(data?: SuccessProps): void;
  product: Product;
}

const UnwrappedPaymentForm = ({
  email: userEmail,
  isLoggedIn = true,
  name,
  onCancel,
  onSuccess,
  product,
}: Props) => {
  const elements = useElements();
  const stripe = useStripe();
  const { t } = useTranslation();
  const [amount, setAmount] = useState(product.amount / 100);
  const [cardValidationError, setCardValidationError] = useState();
  const [code, setCode] = useState<PromotionCode>();
  const [fullName, setFullName] = useState(name);
  const [email, setEmail] = useState(userEmail);
  const [isCardComplete, setIsCardComplete] = useState<boolean>();
  const [isCardProcessing, setIsCardProcessing] = useState<boolean>();
  const [paymentError, setPaymentError] = useState();
  const [showDiscountForm, setShowDiscountForm] = useState(false);
  const { makePayment } = usePayment(isLoggedIn);

  const missingInfo = !fullName || !isEmailValid(email);

  const applyCoupon = (code: PromotionCode) => {
    setAmount((amt: number) =>
      code.coupon.percent_off
        ? amt - (amt * parseFloat(code.coupon.percent_off)) / 100
        : // NOTE: Dividing by 100 because amount_off is in cents instead of dollars
          amt - parseFloat(code.coupon.amount_off) / 100
    );
    setCode(code);
    toggleDiscountForm();
  };

  const toggleDiscountForm = () => {
    setShowDiscountForm(!showDiscountForm);
  };

  const clearCoupon = () => {
    setAmount(product.amount / 100);
    setCode(undefined);
  };

  const handleCardElementOnChange = (event: any) => {
    setCardValidationError(event.error);
    setIsCardComplete(event.complete);
    // reset form error if user is trying again.
    setPaymentError(undefined);
  };

  const handlePayment = async (client_secret: any, error: any, data: any) => {
    if (error) throw error;
    const cardElement = elements!.getElement(CardElement);

    const { paymentIntent, error: paymentError } =
      await stripe!.confirmCardPayment(client_secret, {
        payment_method: {
          // @ts-ignore Argument of type '{ card: StripeCardElement | null; }' is not assignable to parameter of type 'ConfirmCardPaymentData'.
          card: cardElement,
          billing_details: {
            name: fullName,
          },
        },
      });

    if (paymentError) {
      if (paymentError.payment_intent) {
        if (paymentError.payment_intent.status === 'succeeded') {
          onSuccess(data);
        }
      }
    }
    if (paymentIntent && paymentIntent.status === 'succeeded') {
      onSuccess(data);
    }
  };

  const handleSubmit = async (e: React.MouseEvent) => {
    e.preventDefault();
    let cancelProcess = false;

    if (!stripe || !elements) {
      return;
    }

    if (paymentError) {
      elements.getElement('card')!.focus();
      return;
    }

    if (isCardComplete) {
      setIsCardProcessing(true);
    }

    if (cancelProcess) {
      setIsCardProcessing(false);
      return;
    }

    try {
      const { client_secret, error, ...rest } = await makePayment(
        product,
        code ? code.id : undefined,
        {
          email,
          name: fullName,
        }
      );

      await handlePayment(client_secret, error, {
        ...rest,
        email,
        name: fullName,
      });
    } catch (e) {
      setIsCardProcessing(false);
      // @ts-ignore
      setPaymentError(e);
    }
  };

  /** Update price when product changes */
  useEffect(() => {
    setAmount(product.amount / 100);
  }, [product.amount]);

  return (
    <IonGrid>
      <IonRow style={{ display: showDiscountForm ? 'block' : 'none' }}>
        <DiscountForm
          isLoggedIn={isLoggedIn}
          onCancel={toggleDiscountForm}
          onSuccess={applyCoupon}
        />
      </IonRow>

      {userEmail && isEmailValid(userEmail) ? (
        <></>
      ) : (
        <IonRow
          style={{
            display: showDiscountForm ? 'none' : 'block',
            border:
              email && !isEmailValid(email)
                ? '1px solid var(--ion-color-danger)'
                : '1px solid #eee',
            borderRadius: '8px',
            marginTop: '5px',
          }}
        >
          <IonInput
            onIonChange={(e: any) => {
              setPaymentError(undefined);
              setEmail(e.target.value);
            }}
            placeholder="Email"
            style={{
              borderRadius: '8px',
              width: '100%',
              // @ts-ignore
              '--padding-end': '18px',
              '--padding-start': '18px',
              '--padding-top': '16px',
              '--padding-bottom': '16px',
            }}
            value={email}
          />
        </IonRow>
      )}

      <IonRow
        style={{
          display: showDiscountForm ? 'none' : 'block',
          border: '1px solid #eee',
          borderRadius: '8px',
          marginTop: '5px',
        }}
      >
        <IonInput
          onIonChange={(e: any) => {
            setFullName(e.target.value);
            setPaymentError(undefined);
          }}
          placeholder="Full Name"
          style={{
            borderRadius: '8px',
            width: '100%',
            // @ts-ignore
            '--padding-end': '18px',
            '--padding-start': '18px',
            '--padding-top': '16px',
            '--padding-bottom': '16px',
          }}
          value={fullName}
        />
      </IonRow>

      <IonRow
        style={{
          display: showDiscountForm ? 'none' : 'block',
          border: '1px solid #eee',
          padding: '16px',
          borderRadius: '8px',
          marginTop: '5px',
        }}
      >
        <CardElement onChange={handleCardElementOnChange} />
        {cardValidationError && (
          // @ts-ignore
          <div>{cardValidationError.message}</div>
        )}
      </IonRow>

      <IonRow
        style={{
          color: 'var(--ion-color-danger)',
          display: showDiscountForm ? 'none' : paymentError ? 'block' : 'none',
          padding: '16px',
          borderRadius: '8px',
          marginTop: '5px',
        }}
      >
        An error occurred: {paymentError} <br />
        <br />
        If you continue to have problems please reach out to{' '}
        <a href="mailto:support@caminoforgood.com">support@caminoforgood.com</a>
      </IonRow>
      <IonRow
        style={{
          display: showDiscountForm ? 'none' : 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        {code?.coupon ? (
          <IonChip onClick={clearCoupon}>
            <IonLabel>{code?.code}</IonLabel>
            <IonIcon icon={closeCircle} />
          </IonChip>
        ) : (
          <Caption
            onClick={toggleDiscountForm}
            style={{
              color: 'grey',
              cursor: 'pointer',
              textDecoration: 'underline',
            }}
          >
            {t('components.PaymentForm.ApplyCoupon')}
          </Caption>
        )}

        <div className="buttons" style={{ float: 'right' }}>
          {onCancel && !code?.coupon && (
            <Button color="light" onClick={onCancel}>
              {t('common.CANCEL')}
            </Button>
          )}
          <Button
            color="success"
            onClick={handleSubmit}
            disabled={missingInfo || !isCardComplete || isCardProcessing}
          >
            {t('components.PaymentForm.Pay')} {formatCurrency(amount)}
          </Button>
        </div>
      </IonRow>

      <IonRow>
        <IonCol className="ion-text-right">
          <img alt={t('components.PaymentForm.PoweredBy')} src={StripeIcon} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export const PaymentForm = ({ ...props }: Props) => (
  <Elements stripe={stripePromise}>
    <UnwrappedPaymentForm {...props} />
  </Elements>
);
