import { gql } from 'apollo-boost';
import { GROUP_TYPE } from './group';

export const UPDATE_USER_SETTINGS = gql`
  mutation updateUserSettings($distance_unit: String!, $private: Boolean!) {
    updateUserSettings(distance_unit: $distance_unit, private: $private) {
      distance_unit
      private
    }
  }
`;

export const USER = gql`
  query user($id: Int!) {
    user(id: $id) {
      id
      avatar
      email
      first_name
      last_name
      user_settings {
        distance_unit
        private
      }
      created_at
      route_settings {
        route_id
        mileage
      }
    }
  }
`;

export const FETCH_SAMPLE_TRACKER_LOGS = gql`
  query fetchSampleTrackerLogs($source: String) {
    fetchSampleTrackerLogs(source: $source) {
      categorized {
        category
        date
        mileage
        source
      }
      uncategorized {
        date
        mileage
        source
      }
    }
  }
`;

export const LOGOUT = gql`
  {
    logout
  }
`;

export const USER_TYPE = `
avatar
completed
email
first_name
id
last_name
providers
plan
cancellation_date
language
user_settings {
  distance_unit
  private
}
team {
  id
  name
  owner
}
fitbit_base_date
fitbit_categories
fitbit_id
fitbit_mode
healthkit_base_date
healthkit_categories
ua_base_date
ua_categories
ua_id
route_id
route_slug
route_settings {
  destinations_visited
  logs {
    id
    created_at
    date
    mileage
    source
    category
  }
  mileage
  payment_status
  route_id
  stamps
}
source
tracker_settings {
  healthkit
  fitbit
}
handle
`;

export const UPDATE_USER = gql`
  mutation updateUser($userConfig: InputUser) {
    updateUser(userConfig: $userConfig) {
      user {
        ${USER_TYPE}
      }
    }
  }
`;

export const UPDATE_CONTACT_INFO = gql`
mutation updateUserDisplayName($updatedUser: InputUser) {
  updateUserDisplayName(updatedUser: $updatedUser) {
    user {
      ${USER_TYPE}
    }
  }
}
`;

export const UPDATE_TRACKER_SETTINGS = gql`
  mutation updateTrackerSettings(
    $source: String
    $date: String
    $categories: [String]
    $mode: String
    $token: String
    $deleteOnDisconnect: Boolean
  ) {
    updateTrackerSettings(
      source: $source
      date: $date
      categories: $categories
      mode: $mode
      token: $token
      deleteOnDisconnect: $deleteOnDisconnect
    ) {
      user {
        ${USER_TYPE}
      }
    }
  }
`;

export const UPDATE_STAMPS = gql`
  mutation updateStamps($stamps: [String]) {
    updateStamps(stamps: $stamps) {
      data
    }
  }
`;

export const RESET_USER = gql`
  mutation resetUser {
    resetUser {
      user {
        ${USER_TYPE}
      }
    }
  }
`;

export const UPDATE_TRACKER_DATE = gql`
  mutation updateUserTrackerDate($source: String, $date: String) {
    updateUserTrackerDate(source: $source, date: $date) {
      user {
        ${USER_TYPE}
      }
    }
  }
`;

export const UPDATE_DESTINATIONS_VISITED = gql`
  mutation updateDestinationsVisited($destinations_visited: [String]) {
    updateDestinationsVisited(destinations_visited: $destinations_visited) {
      data
    }
  }
`;

export const AUTHORIZE_THIRD_PARTY = gql`
mutation authorize($source: String!, $secret: String) {
  authorize(source: $source, secret: $secret) {
    user {
      ${USER_TYPE}
    }
  }  
}`;

export const SYNC_All_LOGS = gql`
mutation syncAllTrackerLogs($source: String!) {
  syncAllTrackerLogs(source: $source) {
    user {
      ${USER_TYPE}
    }
  }  
}`;

export const SYNC_THIRD_PARTY = gql`
mutation sync($source: String!) {
  sync(source: $source) {
    user {
      ${USER_TYPE}
    }
  }  
}`;

export const CATEGORY_TYPE = `
name
`;

export const TRACKER_CATEGORIES = gql`
  query trackerCategories($source: String) {
    trackerCategories(source: $source) {
      ${CATEGORY_TYPE}
    }
  }
`;

export const CURRENT_USER = gql`
  {
    currentUser {
      ${USER_TYPE}
      mobs {
        ${GROUP_TYPE}
      }
      groups {
        ${GROUP_TYPE}
      }
      created_at
    }
  }
`;

//       mileage TODO
export const FRIENDS = gql`
  {
    friends {
      avatar
      email
      first_name
      last_name
      user_settings {
        private
      }
    }
  }
`;
