import {
  IonBadge,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { findIndex } from 'lodash';
import moment from 'moment';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  peopleOutline as IconDetails,
  mapOutline as IconMap,
  chatbubbleEllipsesOutline as IconMessages,
} from 'ionicons/icons';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PageWrapper } from '../components/PageWrapper';
import { Toolbar } from '../components/Toolbar';
import { Group, GroupContext } from '../context/GroupContext';
import { Message, MessageContext } from '../context/MessageContext';
import { RouteContext } from '../context/RouteContext';
import { UserContext } from '../context/UserContext';
import { unreadMessageCount } from '../util/unreadMessageCount';
import { Detail } from './Group/Detail';
import { Map as GroupMap } from './Group/Map';
import { Messages } from './Group/Messages';

import { Preferences } from '@capacitor/preferences';

export const GroupPage = ({ match }: any) => {
  const { fetchGroup } = useContext(GroupContext);
  const { getById } = useContext(RouteContext);
  const { user } = useContext(UserContext);
  const { fetchMessages } = useContext(MessageContext);
  const [messages, setMessages] = useState<Message[]>([]);
  const [lastViewed, setLastViewed] = useState<string>('');
  const { t } = useTranslation();

  const [isDeleted, setIsDeleted] = useState(false);
  const [route, setRoute] = useState<any>();
  const [group, setGroup] = useState<Group>({
    conversation: { id: 0 },
    name: '',
    owner: {
      avatar: '',
      first_name: '',
    },
    public: true,
    route_id: 0,
    secret: '',
    users: [],
  });
  const [loading, setLoading] = useState(true);

  const isOwner = useCallback<() => boolean>(
    () => (group?.owner?.avatar ? group?.owner.avatar === user?.avatar : false),
    [group, user]
  );

  const inGroup = useCallback<() => boolean>(
    () => findIndex(user?.groups, { secret: group?.secret }) !== -1,
    [group, user]
  );

  // Load the selected group from URL
  useEffect(() => {
    setLoading(true);
    const load = async () => {
      const loadedGroup = await fetchGroup(match.params.id);
      if (!loadedGroup) {
        setIsDeleted(true);
      } else {
        setGroup(loadedGroup);
        setRoute(getById(loadedGroup.route_id));

        // Load messages here to get a count
        const conversationId = loadedGroup?.conversation.id!;
        setMessages(await fetchMessages(conversationId));

        // Check when the last time we saw messages was to determine if we show the count bubble
        const { value } =
          (await Preferences.get({
            key: 'messages',
          })) || {};
        const lastViewedMap = value ? { ...JSON.parse(value) } : {};

        if (lastViewedMap[conversationId]) {
          setLastViewed(lastViewedMap[conversationId]);
        }
        setLoading(false);
      }
    };

    load();
  }, [match.params.id, user]); // eslint-disable-line react-hooks/exhaustive-deps

  const subtitle = useMemo(() => {
    return isOwner()
      ? t('pages.group.YOUCREATED')
      : `${t('pages.group.CREATEDBY')} ${group?.owner?.first_name}`;
  }, [group.owner, isOwner, t]);

  const unreadCount = useMemo(
    () => unreadMessageCount({ lastViewed, messages }),
    [lastViewed, messages]
  );

  const onload = (): void => {
    // when the message page is visited lastViewed should be updated
    setLastViewed(moment().format('x'));
  };

  return (
    <>
      <Toolbar loading={loading} subtitle={subtitle} title={group.name} />
      <PageWrapper loading={loading}>
        <IonTabs>
          <IonRouterOutlet>
            <Switch>
              {/* TODO: Route set up, but commented out until group_name exists  */}
              <Route
                exact
                path={`/dashboard/group/:id/details`}
                component={() => (
                  <Detail group={group} user={user} route={route} />
                )}
              />
              <Route
                exact
                path={`/dashboard/group/:id/map`}
                component={() => (
                  <GroupMap group={group} user={user} route={route} />
                )}
              />
              <Route
                exact
                path={`/dashboard/group/:id/messages`}
                component={() => (
                  <Messages
                    inGroup={inGroup()}
                    group={group}
                    user={user}
                    onload={onload}
                  />
                )}
              />
            </Switch>
          </IonRouterOutlet>
          <IonTabBar slot="bottom">
            <IonTabButton
              tab="details"
              href={`/dashboard/group/${group.secret}/details`}
            >
              <IonIcon icon={IconDetails} />
              <IonLabel>{t('pages.group.DETAILS')}</IonLabel>
            </IonTabButton>

            <IonTabButton
              tab="map"
              href={`/dashboard/group/${group.secret}/map`}
            >
              <IonIcon icon={IconMap} />
              <IonLabel>{t('pages.group.MAP')}</IonLabel>
            </IonTabButton>
            {inGroup() ? (
              <IonTabButton
                tab="messages"
                href={`/dashboard/group/${group.secret}/messages`}
              >
                <IonIcon icon={IconMessages} />
                <IonLabel>Messages</IonLabel>
                {!loading && unreadCount > 0 ? (
                  <IonBadge>{unreadCount}</IonBadge>
                ) : null}
              </IonTabButton>
            ) : null}
          </IonTabBar>
        </IonTabs>
      </PageWrapper>
      {isDeleted && (
        <Redirect
          to={{
            pathname: `/dashboard/groups`,
            state: { deleted: isDeleted },
          }}
        />
      )}
    </>
  );
};
