import React, { CSSProperties, ReactNode } from 'react';
import { IonList, IonListHeader } from '@ionic/react';

export const ListHeader: React.FC<{
  style?: CSSProperties;
  children: ReactNode;
}> = ({ children, style }) => (
  <IonListHeader style={style}>{children}</IonListHeader>
);

export const List: React.FC<{ children: ReactNode }> = ({ children }) => (
  <IonList>{children}</IonList>
);
