import {
  // WIP: Alert user to upgrade
  // IonBadge,
  IonHeader,
  IonIcon,
  IonToolbar,
  IonItem,
  IonButtons,
  IonMenuButton,
  IonBackButton,
  IonRouterLink,
  isPlatform,
  IonBadge,
} from '@ionic/react';
import { arrowBack } from 'ionicons/icons';
import { capitalize } from 'lodash';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Moment from 'react-moment';
import { useLocation } from 'react-router-dom';

import { Avatar } from './Avatar';
import { Caption } from './Text';
import { UserContext } from '../context/UserContext';
import { NavigationContext } from '../context/NavigationContext';
import { useActiveRoute } from '../hooks/useActiveRoute';

const isDesktop = isPlatform('desktop');

type Props = {
  loading?: boolean;
  menuEnabled?: boolean;
  showBack?: boolean;
  subtitle?: React.ReactNode;
  title?: string;
};

export const Toolbar: React.FC<Props> = ({
  loading,
  menuEnabled = true,
  showBack = true,
  subtitle,
  title = '',
}) => {
  const { user } = useContext(UserContext);
  const { payment_status } = useActiveRoute();
  const { history } = useContext(NavigationContext);
  const location = useLocation();
  const { t } = useTranslation();

  const isHome = location.pathname.includes('/home');

  const currentPage = useMemo(() => {
    let pageName;

    if (title === '') {
      pageName = capitalize(location.pathname.split('/')[2] || 'dashboard');
      if (pageName === 'Home') {
        pageName = `${t(`components.Toolbar.HI`)}, ${user?.first_name}`;
      }
    } else {
      pageName = title;
    }

    return pageName;
  }, [location, title, user, t]);

  return (
    <IonHeader>
      <IonToolbar
        color="dark"
        style={{
          // @ts-ignore
          '--min-height': '60px',
        }}
      >
        <IonItem color="dark" lines="none">
          {!loading && (
            <>
              {!isDesktop && isHome && (
                <Avatar image={user?.avatar} size={40} slot="start" />
              )}
              <div>
                {!subtitle && (
                  <Caption style={{ color: 'var(--ion-color-primary)' }}>
                    <Moment
                      local
                      format={user?.language === 'es' ? 'D MMM' : 'ddd MMM D'}
                    >
                      {new Date()}
                    </Moment>
                  </Caption>
                )}
                {currentPage}
                {subtitle && (
                  <Caption
                    style={{
                      color: 'var(--ion-color-medium)',
                      fontWeight: 600,
                    }}
                  >
                    {subtitle}
                  </Caption>
                )}
              </div>
            </>
          )}
        </IonItem>
        {showBack && (
          <IonButtons slot="start">
            {history.length > 1 ? (
              <IonBackButton defaultHref="/dashboard" text="" />
            ) : (
              <IonRouterLink routerLink="/dashboard/home" color="light">
                <IonIcon
                  icon={arrowBack}
                  style={{ fontSize: '24px', padding: '0 12px' }}
                />
              </IonRouterLink>
            )}
          </IonButtons>
        )}
        {!isDesktop && menuEnabled && (
          <IonButtons slot="end">
            <IonMenuButton id="sidebar-menu-button" autoHide={false} />
            {!payment_status && (
              <IonBadge
                color="primary"
                style={{
                  pointerEvents: 'none',
                  position: 'absolute',
                  right: '0',
                  top: '0',
                }}
                slot="end"
              >
                1
              </IonBadge>
            )}
          </IonButtons>
        )}
      </IonToolbar>
    </IonHeader>
  );
};
