import { IonGrid, IonRow, IonCol } from '@ionic/react';
import { round } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useState, useMemo } from 'react';

import { Map } from '../components/Map/Map';
import { UserContext, User as IUser } from '../context/UserContext';
import { PageWrapper } from '../components/PageWrapper';
import { Toolbar } from '../components/Toolbar';
import { PathContext, Waypoint } from '../context/PathContext';
import { UserDetail } from '../components/UserDetail/UserDetail';
import { useTranslation } from 'react-i18next';
import { useActiveRoute } from '../hooks/useActiveRoute';

const UserChip = ({ primary, secondary }: any) => (
  <IonCol>
    <div
      style={{
        backgroundColor: '#ebebeb',
        padding: '10px',
        borderRadius: '4px',
        textAlign: 'right',
      }}
    >
      <span style={{ color: '#141F39', fontSize: '30px', fontWeight: 300 }}>
        {primary}
      </span>
      <br />
      <span style={{ color: '#8F8F8F', fontSize: '14px' }}>{secondary}</span>
    </div>
  </IonCol>
);

export const User = ({
  location,
  match: {
    params: { id },
  },
}: any) => {
  const { t } = useTranslation();

  const { getLastWaypoint, getUnlockedWaypoints, getWaypoints } =
    useContext(PathContext);
  const { fetchUser, user: currentUser } = useContext(UserContext);
  const [user, setUser]: any = useState<IUser | void>();
  const { route } = useActiveRoute();
  const [waypoints, setWaypoints] = useState<Waypoint[]>([]);
  useEffect(() => {
    (async () => {
      setWaypoints(await getWaypoints(route));
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function getUser() {
      setUser(await fetchUser!(parseInt(id)));
    }
    getUser();
  }, [fetchUser, id]);

  /* @TODO Re-enable when we have the information for these chips */
  const readyForChips = false;
  const mileagePerDay = useMemo(() => {
    const a = moment(parseInt(user?.created_at));
    const b = moment();
    const numberDays = b.diff(a, 'days');

    return user ? round(user.mileage / numberDays, 1) : 0;
  }, [user]);

  const mapCenter = useMemo(() => {
    const calculated = user && getLastWaypoint(user.mileage);
    return user ? calculated : undefined;
  }, [getLastWaypoint, user]);

  return (
    <>
      <Toolbar title="User Details" />
      <PageWrapper>
        {user && (
          <UserDetail
            distanceUnit={currentUser?.user_settings.distance_unit}
            isTransparent
            route={route}
            showDistance
            user={user}
          />
        )}
        {readyForChips && (
          <IonGrid>
            <IonRow>
              <UserChip
                primary={location.search}
                secondary={t('pages.user.PLACE')}
              />
              <UserChip
                primary={mileagePerDay}
                secondary={currentUser?.user_settings.distance_unit}
              />
              <UserChip primary="9,421" secondary={t('pages.user.STEPS')} />
            </IonRow>
          </IonGrid>
        )}
        <div style={{ height: '300px' }}>
          {mapCenter && user && (
            <Map
              center={mapCenter}
              controls={false}
              draggable={true}
              paths={[
                // @TODO: Can we use CSS variables here?
                { color: '#e9b20a', dashed: true, path: waypoints },
                {
                  color: '#de4e00',
                  path: getUnlockedWaypoints(user?.mileage),
                },
              ]}
              route={route}
              users={[user]}
            />
          )}
        </div>
      </PageWrapper>
    </>
  );
};
