import { IonButton, IonIcon } from '@ionic/react';
import { GoogleMap, Polyline, useLoadScript } from '@react-google-maps/api';
import { locateOutline } from 'ionicons/icons';
import { isBoolean, map } from 'lodash';
import React, { useContext, useMemo, useState } from 'react';

import { PathContext } from '../../context/PathContext';
import { Route } from '../../context/RouteContext';
import { PublicUser, UserContext } from '../../context/UserContext';
import { AvatarMarker } from './AvatarMarker';

const containerStyle = {
  width: '100%',
  height: '100%',
};

type MapControls = {
  fullscreenControl?: boolean;
  mapTypeControl?: boolean;
  streetViewControl?: boolean;
  zoomControl?: boolean;
};

type MapProps = {
  activeUserId?: string;
  center: { lat: number; lng: number };
  controls?: boolean | MapControls;
  draggable?: boolean;
  paths: any[];
  route: Route;
  users?: PublicUser[];
  zoom?: number;
};

const UnwrappedMap = ({
  activeUserId,
  center,
  controls,
  draggable,
  paths,
  route,
  users,
  zoom = 8,
}: MapProps) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: 'AIzaSyC4P37OXvYhn84IS6aiBnyWrchQ9X66pNo',
  });
  const [centerPosition, setCenter] = useState(center);
  const [buttonVisibility, setButtonVisibility] = useState('hidden');
  const { getNextWaypoint } = useContext(PathContext);
  const { getRouteSettings } = useContext(UserContext);

  const handleCenter = () => {
    setCenter({ lat: center.lat, lng: center.lng });
    setButtonVisibility('hidden');
  };

  const displayControls = useMemo(
    () => ({
      fullscreenControl: isBoolean(controls)
        ? controls
        : !!controls?.fullscreenControl,
      mapTypeControl: isBoolean(controls)
        ? controls
        : !!controls?.mapTypeControl,
      streetViewControl: isBoolean(controls)
        ? controls
        : !!controls?.streetViewControl,
      zoomControl: isBoolean(controls) ? controls : !!controls?.zoomControl,
    }),
    [controls]
  );

  return isLoaded ? (
    // @ts-ignore
    <GoogleMap
      options={{
        draggable,
        draggableCursor: draggable ? undefined : 'default',
        ...displayControls,
      }}
      mapContainerStyle={containerStyle}
      center={centerPosition}
      zoom={zoom}
      onDragEnd={() => setButtonVisibility('visible')}
    >
      <IonButton
        onClick={handleCenter}
        color="light"
        size="small"
        style={{
          '--background': '#fff',
          '--border-radius': '0',
          bottom: '200px',
          boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px',
          height: '40px',
          position: 'absolute',
          right: '10px',
          visibility: buttonVisibility,
          width: '40px',
        }}
      >
        <IonIcon icon={locateOutline} size="small" />
      </IonButton>
      {map(users, (user: PublicUser) => {
        const mileage = getRouteSettings(route, user).mileage || 0;
        const friendPosition = getNextWaypoint(mileage);

        return (
          user &&
          user.avatar &&
          friendPosition && (
            <AvatarMarker
              key={user.email || user.id}
              active={user.id === activeUserId}
              avatar={user.avatar}
              position={friendPosition}
            />
          )
        );
      })}
      {/* Child components, such as markers, info windows, etc. */}
      {paths.map((pathConfig: any, index: number) => (
        <Polyline
          key={index}
          path={pathConfig.path}
          options={{
            clickable: false,
            draggable: false,
            editable: false,
            visible: true,
            ...(pathConfig.dashed
              ? {
                  icons: [
                    {
                      icon: {
                        path: 'M 0,-1 0,1',
                        strokeOpacity: 1,
                        strokeColor: pathConfig.color,
                        scale: 3,
                        strokeWeight: 4,
                      },
                      offset: '0',
                      repeat: '20px',
                    },
                  ],
                  strokeOpacity: 0,
                }
              : {
                  strokeColor: pathConfig.color,
                  strokeOpacity: 0.8,
                  strokeWeight: 4,
                }),
          }}
        />
      ))}
    </GoogleMap>
  ) : null;
};

export const Map = React.memo(UnwrappedMap);
