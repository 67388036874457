import React, { useContext } from 'react';
import { IonIcon, IonImg, IonItem, IonLabel, IonThumbnail } from '@ionic/react';
import {
  lockClosedOutline,
  trailSignOutline,
  volumeHighOutline,
} from 'ionicons/icons';

import { Badge } from '../../components/Badge/Badge';
import {
  IDestination,
  DestinationContext,
} from '../../context/DestinationContext';
import { renderDistance } from '../../util/renderDistance';

export const DestinationListItem = ({
  currentMileage,
  destination,
  distanceUnit,
  isStamped,
  visited,
}: {
  currentMileage: number;
  destination: IDestination;
  distanceUnit: string;
  isStamped: boolean;
  visited: boolean;
}) => {
  const { isDestinationLocked } = useContext(DestinationContext);
  const isLocked = isDestinationLocked(
    destination?.id || '',
    currentMileage || 0
  );

  return (
    <IonItem
      data-test-class="destination-list-item"
      routerLink={`/dashboard/dest/${destination.id}`}
    >
      {isLocked ? (
        <IonThumbnail
          slot="start"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <IonIcon size="large" icon={lockClosedOutline} />
        </IonThumbnail>
      ) : (
        <IonThumbnail slot="start">
          <IonImg src={destination.image} />
        </IonThumbnail>
      )}
      <IonLabel>
        <h3
          style={{
            display: 'flex',
            alignItems: 'center',
            fontWeight: isLocked || visited ? 'normal' : 'bolder',
          }}
        >
          {!(isLocked || visited) && (
            <span
              style={{
                color: 'var(--color-primary-500)',
                paddingRight: '3px',
              }}
            >
              •
            </span>
          )}
          {destination.name}{' '}
          {destination.audio && <IonIcon icon={volumeHighOutline} />}
        </h3>
        <p>
          <span style={{ display: 'flex', alignItems: 'center' }}>
            <IonIcon icon={trailSignOutline} /> &nbsp;
            {renderDistance(destination.from_start, distanceUnit)}&nbsp;
          </span>{' '}
        </p>
      </IonLabel>
      {isStamped && (
        <IonThumbnail slot="end">
          <Badge size={60} name="Stamped" destinationName={destination.name} />
        </IonThumbnail>
      )}
    </IonItem>
  );
};
