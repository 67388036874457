import {
  IonFab,
  IonFabButton,
  IonIcon,
  IonImg,
  IonModal,
  isPlatform,
} from '@ionic/react';
import { arrowForwardOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import bootsImage from '../../assets/boots.png';
import welcomeBg from '../../assets/welcome_bg.jpg';
import { DestinationContext } from '../../context/DestinationContext';
import { useActiveRoute } from '../../hooks/useActiveRoute';
import { useLocalStorage } from '../../hooks/useLocalStorage';

import { H2, H3, P } from '../Text';

interface IBlankSlate {
  isOpen: boolean;
}

export const BlankSlate: React.FC<IBlankSlate> = ({ isOpen }) => {
  const { t } = useTranslation();
  const [hasClosedSlate, setHasClosed] = useState(true);
  const [get, set] = useLocalStorage();
  const { destinations, firstDestinationId } = useContext(DestinationContext);
  const { route } = useActiveRoute();

  useEffect(() => {
    const load = async () => {
      const storageItem = await get('blankSlateClosed');
      setHasClosed(!!storageItem);
    };
    load();
  }, [get, setHasClosed]);

  return (
    <IonModal isOpen={hasClosedSlate !== true && isOpen}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url(${welcomeBg})`,
            backgroundPosition: '50% 33%',
            backgroundSize: 'cover',
            color: 'white',
            padding: '20px',
          }}
        >
          <H3>{t('pages.blankSlate.WELCOME')}</H3>
          <H2 style={{ fontWeight: 500 }}>{t('Camino for Good')}</H2>
        </div>
        <div
          style={{
            padding: isPlatform('desktop') ? '20px' : '80px 20px',
          }}
        >
          <P>
            <span
              dangerouslySetInnerHTML={{
                __html: t('pages.blankSlate.BEGIN_ADVENTURE', {
                  destinationName: destinations[0]?.name,
                  routeName: route.name,
                }),
              }}
            />

            <IonImg src={bootsImage} style={{ opacity: 0.5, height: '50px' }} />
            <IonFab vertical="bottom" horizontal="end">
              <IonFabButton
                id="close-blank-slate"
                onClick={() => set('blankSlateClosed', true)}
                routerLink={`/dashboard/dest/${firstDestinationId}`}
                role="button"
              >
                <IonIcon icon={arrowForwardOutline} />
              </IonFabButton>
            </IonFab>
          </P>
        </div>
      </div>
    </IonModal>
  );
};
