import { IonButton, IonModal, isPlatform } from '@ionic/react';
import { useState, useEffect } from 'react';

import { P } from '../Text';
import { ModalHeader } from '../ModalHeader';
import { useLocalStorage, StorageType } from '../../hooks/useLocalStorage';
import { useTranslation } from 'react-i18next';
import { useUserDestinations } from '../../hooks/useUserDestinations';

type Props = {
  isOpen?: boolean;
};

export const RateAppModal: React.FC<Props> = ({
  isOpen: isModalOpen = false,
}) => {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(isModalOpen || false);
  const { nextDestination } = useUserDestinations();
  const [get, set] = useLocalStorage();

  useEffect(() => {
    (async () => {
      if (isPlatform('cordova')) {
        const promptTimerStatus = await get('prompt_timer_status');
        const count: StorageType = await get('rate_prompt_timer');
        let modifiedCount = count;

        if (!count) {
          await set('prompt_timer', 0);
        }
        if (promptTimerStatus && count !== -1) {
          set('prompt_timer_status', false);
          // @ts-ignore An arithmetic operand must be of type 'any', 'number', 'bigint' or an enum type.ts(2356)
          modifiedCount++;
        }
        // @ts-ignore An arithmetic operand must be of type 'any', 'number', 'bigint' or an enum type.ts(2356)
        if (modifiedCount % 3 === 0 && modifiedCount !== 0) {
          setOpen(true);
        }

        set('rate_prompt_timer', modifiedCount);
      }
    })();
  }, [set, get]);

  const close = () => {
    setOpen(false);
    set('rate_prompt_timer', -1);
  };

  const remindLater = () => {
    setOpen(false);
    set('rate_prompt_timer', 0);
  };

  const toAppStore = () => {
    if (isPlatform('android')) {
      window.open(
        'https://play.google.com/store/apps/details?id=com.f1v.camino'
      );
    }
    if (isPlatform('ios')) {
      window.open('https://apps.apple.com/us/app/camino-for-good/id1525657849');
    }
    close();
  };

  return (
    <>
      <IonModal
        isOpen={isOpen}
        onDidDismiss={() => {
          setOpen(false);
        }}
      >
        <div>
          <ModalHeader imageUrl={nextDestination?.image} />
          <br />
          <P
            style={{
              padding: '20px',
            }}
          >
            <span>{t('components.RateModal.Text')}</span>
          </P>
          <div style={{ float: 'right', padding: '20px' }}>
            <IonButton id="remind" color="light" onClick={remindLater}>
              {t('components.RateModal.Remind')}
            </IonButton>
            <IonButton id="no" color="light" onClick={close}>
              {t('components.RateModal.No')}
            </IonButton>
            <IonButton id="rate" onClick={toAppStore}>
              {t('components.RateModal.Rate')}
            </IonButton>
          </div>
        </div>
      </IonModal>
    </>
  );
};
