import _ from 'lodash';
import constants from '../../util/constants';

let windowObjectReference: any = null;
let previousUrl: string = '';

// Creates a popup if you are on desktop
export const createPopup = async (
  url: string,
  name: string = 'Camino For Good',
  source: string,
  onReceiveMessage: (source: string, data: any) => {}
) => {
  if (source === 'ua') {
    source = 'under_armour';
  }
  const addEventListener = _.get(
    window,
    'addEventListener',
    document.addEventListener
  );
  const removeEventListener = _.get(
    window,
    'removeEventListener',
    document.removeEventListener
  );

  const receiveMessage = (event: any) => {
    if (
      ![constants.ENDPOINT, 'https://dev.caminoforgood.com'].includes(
        event.origin
      )
    ) {
      return;
    }
    const { data } = event;
    onReceiveMessage(source, data);
    removeEventListener('message', receiveMessage);

    windowObjectReference.close();
  };

  // remove any existing event listeners
  removeEventListener('message', receiveMessage);
  // window features
  const strWindowFeatures =
    'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';
  if (windowObjectReference === null || windowObjectReference.closed) {
    /* if the pointer to the window object in memory does not exist
     or if such pointer exists but the window was closed */
    windowObjectReference = window.open(url, name, strWindowFeatures);
  } else if (previousUrl !== url) {
    /* if the resource to load is different,
     then we load it in the already opened secondary window and then
     we bring such window back on top/in front of its parent window. */
    windowObjectReference = window.open(url, name, strWindowFeatures);
    windowObjectReference.focus();
  } else {
    /* else the window reference must exist and the window
     is not closed; therefore, we can bring it back on top of any other
     window with the focus() method. There would be no need to re-create
     the window or to reload the referenced resource. */
    windowObjectReference.focus();
  }
  // add the listener for receiving a message from the popup
  addEventListener('message', receiveMessage);
  // assign the previous URL
  previousUrl = url;
};
