import {
  IonCard,
  IonCardContent,
  IonIcon,
  IonImg,
  IonItem,
  IonLabel,
  IonList,
  isPlatform,
} from '@ionic/react';
import { flashOutline, sunnyOutline, walkOutline } from 'ionicons/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { PageWrapper } from '../components/PageWrapper';
import { Toolbar } from '../components/Toolbar';
import bootsImage from '../assets/walk-for-good-light.png';
import { Caption, P } from '../components/Text';

export const Challenge = () => {
  const { t } = useTranslation();

  return (
    <>
      <Toolbar />
      <PageWrapper>
        {!isPlatform('desktop') && (
          <div
            style={{ backgroundColor: '#e6ecf3', margin: '-10px -10px 10px' }}
          >
            <IonImg
              src={bootsImage}
              style={{ height: '120px', padding: '20px' }}
            />
          </div>
        )}
        <IonCard color="dark">
          <IonCardContent>
            {isPlatform('desktop') ? (
              <P>{t('pages.challenge.DesktopMessage')}</P>
            ) : (
              <Caption>
                <span
                  dangerouslySetInnerHTML={{
                    __html: t('pages.challenge.MobileMessage'),
                  }}
                />
              </Caption>
            )}
          </IonCardContent>
        </IonCard>
        <IonList>
          <IonItem routerLink="/dashboard/group/ZjZmODM4ZTAtNTQzMy0xMWViLTgwMjYtMjMxY2UyY2EzODdj">
            <IonIcon icon={flashOutline} slot="start"></IonIcon>
            <IonLabel>
              <h3>{t('pages.challenge.Born To Run')}</h3>
              <p>{t('pages.challenge.Three Month Challenge')}</p>
            </IonLabel>
          </IonItem>
          <IonItem routerLink="/dashboard/group/MDc5MGUxMjAtNTQzNC0xMWViLTgwMjYtMjMxY2UyY2EzODdj">
            <IonIcon icon={walkOutline} slot="start"></IonIcon>
            <IonLabel>
              <h3>{t('pages.challenge.These Boots Are Made for Walking')}</h3>
              <p>{t('pages.challenge.Six Month Challenge')}</p>
            </IonLabel>
          </IonItem>
          <IonItem routerLink="/dashboard/group/MTg2OTlmYTAtNTQzNC0xMWViLTgwMjYtMjMxY2UyY2EzODdj">
            <IonIcon icon={sunnyOutline} slot="start"></IonIcon>
            <IonLabel>
              <h3>{t('pages.challenge.Walking on Sunshine')}</h3>
              <p>{t('pages.challenge.Twelve Month Challenge')}</p>
            </IonLabel>
          </IonItem>
        </IonList>
      </PageWrapper>
    </>
  );
};
