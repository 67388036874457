import React, { createContext, useCallback, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface INavigationContext {
  history: string[];
  updateHistory(path: string): void;
}

export const NavigationContext = createContext<INavigationContext>({
  history: [],
  updateHistory: () => {},
});

export const NavigationProvider = ({ children }: any) => {
  let location = useLocation();
  const [history, setHistory] = useState<string[]>([]);

  const updateHistory = useCallback(
    (path: string) => {
      setHistory([path, ...history]);
    },
    [history]
  );

  useEffect(() => {
    if (history[0] !== location.pathname) {
      updateHistory(location.pathname);
    }
  }, [location, history, updateHistory]);

  return (
    <NavigationContext.Provider
      value={{
        history,
        updateHistory,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};
