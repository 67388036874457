import { IonImg } from '@ionic/react';
import React from 'react';

import logo from '../../assets/logo_inverted.png';
import { H1 } from '../Text';
import { LoginFooterLinks } from './LoginFooterLinks';

type Props = {
  children: React.ReactNode;
  title?: string;
};

export const LoginMobileView: React.FC<Props> = ({ children }) => (
  <>
    <div
      style={{
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <IonImg src="/assets/camino_bg_4_mobile.jpg" />

      <H1
        style={{
          background: 'var(--color-secondary-800)',
          borderRadius: '3px',
          marginTop: '-35px',
        }}
      >
        <IonImg src={logo} style={{ width: '240px' }} />
      </H1>
      <div style={{ padding: '20px 20px 0' }}>{children}</div>
    </div>
    <br />
    <br />
    <LoginFooterLinks />
  </>
);
