import { useContext, useEffect } from 'react';

import { DestinationContext } from '../context/DestinationContext';
import { ImageContext } from '../context/ImageContext';
import { PathContext } from '../context/PathContext';
import { UserContext } from '../context/UserContext';
import { Login } from './Login';

export const LoadingRoute = ({
  component: Component = Login,
  ...restProps
}: any) => {
  const { user } = useContext(UserContext);
  const { loadDestinations } = useContext(DestinationContext);
  const { loadImages } = useContext(ImageContext);
  const { loading: pathsLoading, loadPaths } = useContext(PathContext);

  useEffect(() => {
    loadDestinations(user?.language || 'en');
    loadImages();
    loadPaths(user.route_slug!);
  }, [
    loadDestinations,
    loadImages,
    loadPaths,
    user?.language,
    user.route_slug,
  ]);

  return <>{pathsLoading ? null : <Component {...restProps} />}</>;
};
