import styled from '@emotion/styled';
import { IonRouterLink } from '@ionic/react';
import { map } from 'lodash';
import React, { useContext } from 'react';

import { PublicUser, UserContext } from '../../context/UserContext';
import { useActiveRoute } from '../../hooks/useActiveRoute';
import { renderDistance } from '../../util/renderDistance';
import { UserAvatar } from '../UserAvatar/UserAvatar';

type Props = {
  users: PublicUser[];
};

const StyledAvatar = styled(IonRouterLink)<{ active?: boolean }>`
  & > div {
    align-items: center;
    color: var(--color-text-default);
    display: grid;
    font-size: 10px;
    grid-gap: 4px;
    justify-content: center;
    text-align: center;
  }
`;

export const UserAvatarSlides: React.FC<Props> = ({ users }) => {
  const { getRouteSettings, user } = useContext(UserContext);
  const { route } = useActiveRoute();

  return (
    <div
      style={{
        display: 'grid',
        gridGap: '10px',
        gridTemplateColumns: `repeat(${users.length}, 60px)`,
        overflowX: 'scroll',
        padding: '10px 0',
        width: '100%',
      }}
    >
      {map(users, (u) => {
        const mileage = getRouteSettings(route, u)?.mileage || 0;
        return (
          <StyledAvatar
            active
            key={u.id}
            routerLink={`/dashboard/user/${u.id}`}
          >
            <div>
              <UserAvatar
                isActive={u.id === user.id}
                showName={false}
                user={u}
              />
              <span className="group-member-name">
                {u.first_name} {u.last_name.charAt(0)}
              </span>
              <span>
                {renderDistance(mileage, user?.user_settings.distance_unit)}
              </span>
            </div>
          </StyledAvatar>
        );
      })}
    </div>
  );
};
