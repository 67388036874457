import _ from 'lodash';
import { Message } from '../context/MessageContext';

export interface BadgeData {
  lastViewed: string;
  messages: { created_at: string }[];
}

export const unreadMessageCount = (data: BadgeData): number => {
  if (_.isEmpty(data.lastViewed)) {
    return data.messages.length;
  } else if (
    parseInt(data.lastViewed) <
    parseInt(_.get(_.last(data.messages), 'created_at', ''))
  ) {
    return _.filter(
      data.messages,
      (message: Message) =>
        parseInt(data.lastViewed) < parseInt(message.created_at)
    ).length;
  } else {
    return 0;
  }
};
