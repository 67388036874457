import OneSignal from 'onesignal-cordova-plugin';
import { isPlatform } from '@ionic/react';
import { User } from '../context/UserContext';

class OneSignalUtil {
  hasPermission: boolean = false;
  hasPrompted: boolean = false;
  instance: any;
  isSubscribed: boolean = false;
  oneSignalId: string = '';

  constructor() {
    this.instance = isPlatform('cordova')
      ? Promise.resolve(OneSignal)
      : this.initWebOneSignal();
    this.init();
  }

  initWebOneSignal() {
    return new Promise((resolve) => {
      window.OneSignal = window.OneSignal || [];
      window.OneSignal.push(() => {
        window.OneSignal.getUserId((id: string) => {
          this.oneSignalId = id;
          resolve(window.OneSignal);
        });
      });
    });
  }

  async getOneSignalId() {
    await this.instance;
    return this.oneSignalId;
  }

  async init() {
    const instance = await this.instance;
    if (isPlatform('cordova')) {
      this.initCordova(instance);
    } else {
      this.initWeb(instance);
    }
  }

  initCordova(instance: any) {
    instance.startInit(
      process.env.REACT_APP_ONE_SIGNAL_APP_ID!,
      process.env.REACT_APP_GOOGLE_PROJECT_NUMBER
    );

    instance.setLogLevel({ logLevel: 6, visualLevel: 0 });
    instance.setNotificationWillShowInForegroundHandler((event: any) => {
      console.log('Foreground notification:', event.getNotification());
      event.complete(event.getNotification());
    });

    instance
      .handleNotificationReceived()
      .subscribe(() => console.log('Notification received'));
    instance
      .handleNotificationOpened()
      .subscribe((openResult: any) =>
        console.log('Notification opened:', openResult)
      );

    instance.iOSSettings({
      kOSSettingsKeyAutoPrompt: false,
      kOSSettingsKeyInAppLaunchURL: false,
    });
    instance.endInit();

    instance.getPermissionSubscriptionState((status: any) => {
      this.processSubscriptionStatus(status);
      if (!this.hasPermission || !this.hasPrompted) {
        instance.addTrigger('prompt_ios', 'true');
      }
    });
  }

  initWeb(instance: any) {
    instance.getNotificationPermission((permission: string) => {
      if (permission === 'default') {
        instance.showSlidedownPrompt({ force: true });
        this.hasPrompted = true;
      }
      this.hasPermission = permission === 'granted';
    });

    instance.isPushNotificationsEnabled((isEnabled: boolean) => {
      this.isSubscribed = isEnabled;
    });
  }

  processSubscriptionStatus(status: any) {
    this.hasPrompted = status.permissionStatus.hasPrompted;
    this.oneSignalId = status.subscriptionStatus.userId;
    this.hasPermission = isPlatform('ios')
      ? status.permissionStatus.status === 2
      : status.permissionStatus.state === 1;
    this.isSubscribed = status.subscriptionStatus.subscribed;
  }

  async status(): Promise<{
    hasPermission: boolean;
    hasPrompted: boolean;
    isSubscribed: boolean;
    oneSignalId: string;
  }> {
    const instance = await this.instance;
    if (isPlatform('cordova')) {
      return new Promise((resolve) =>
        instance.getPermissionSubscriptionState(resolve)
      );
    } else {
      await Promise.all([
        new Promise((resolve) => instance.getNotificationPermission(resolve)),
        new Promise((resolve) => instance.isPushNotificationsEnabled(resolve)),
      ]);
    }
    return {
      hasPermission: this.hasPermission,
      hasPrompted: this.hasPrompted,
      isSubscribed: this.isSubscribed,
      oneSignalId: this.oneSignalId,
    };
  }

  async sendSelfNotification(message: string) {
    const instance = await this.instance;
    const notification = {
      contents: { en: message },
      include_player_ids: [this.oneSignalId],
    };
    if (isPlatform('cordova')) {
      instance.postNotification(notification);
    } else {
      instance.sendSelfNotification('New Notification', message);
    }
  }

  async sendTag(key: string, value: string) {
    const instance = await this.instance;
    instance.sendTag(key, value);
  }

  async setExternalUserId(id: string) {
    const instance = await this.instance;
    return instance.setExternalUserId(id);
  }

  async setSubscription(status: boolean) {
    const instance = await this.instance;
    instance.setSubscription(status);
  }

  async setUser(user: User) {
    const instance = await this.instance;
    await this.sendTag('first_name', user.first_name);
    await this.sendTag('last_name', user.last_name);

    try {
      await instance.removeExternalUserId();
      await this.setExternalUserId(`${user.id}`);
    } catch (e) {
      console.error('Failed to set OneSignal user:', e);
    }
  }

  isAvailable() {
    return typeof window.OneSignal === 'function';
  }
}

export const oneSignalUtil = new OneSignalUtil();
