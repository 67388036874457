import React from 'react';
import { Marker } from '@react-google-maps/api';

import defaultImage from '../../assets/welcome_bg.jpg';

interface AvatarProps {
  active?: boolean;
  avatar: string;
  position: { lat: number; lng: number };
}

export const AvatarMarker = ({ active, avatar, position }: AvatarProps) => (
  <>
    <Marker
      icon={{
        anchor: new google.maps.Point(12, 46),
        scaledSize: { equals: () => false, height: 24, width: 24 },
        url: avatar || defaultImage,
      }}
      position={{ lat: position.lat, lng: position.lng }}
    />
    <Marker
      icon={{
        scaledSize: { equals: () => false, height: 51, width: 32 },
        url: active ? '/assets/marker-yellow.png' : '/assets/marker.png',
      }}
      position={{ lat: position.lat, lng: position.lng }}
    />
  </>
);
