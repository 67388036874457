import { isPlatform } from '@ionic/react';
import _, {
  capitalize,
  some,
  filter,
  groupBy,
  lowerCase,
  map,
  sumBy,
  uniqBy,
} from 'lodash';
import moment from 'moment';
import { Log } from '../context/UserContext';
import { CapacitorHealthkit } from '@perfood/capacitor-healthkit';

const today = new Date();

type FetchDataType = {
  type: string;
  startDate: Date;
};

type HealthKitData = {
  duration: number;
  endDate: string;
  source: string;
  sourceBundleId: string; // 'com.apple.Health';
  startDate: string;
  totalDistance?: number;
  unitName: string;
  uuid: string;
  value?: number;
  workoutActivityName?: string;
};

class HealthTracker {
  requestPermission = async () => {
    if (isPlatform('cordova')) {
      try {
        return await CapacitorHealthkit.requestAuthorization({
          all: [''], // ask for Read & Write permission
          read: ['steps', 'distance', 'activity'], // ask for Read Only permission
          write: [''], // ask for Write Only permission
        });
      } catch (e) {
        console.log(e);
      }
    }
    return false;
  };

  fetchDataByType = async (config: FetchDataType): Promise<Partial<Log>[]> => {
    const startMonth = moment(config.startDate);
    const endMonth = moment(today);
    const months = endMonth.diff(startMonth, 'months');
    const timer = new Date().getTime();
    console.log(`HealthKit Complete`);

    let resultDataList: HealthKitData[][] = [];
    for (let i = 0; i <= months; i++) {
      const nextMonth = moment(startMonth).add(1, 'month');
      await this.requestPermission();
      const { resultData } =
        await CapacitorHealthkit.queryHKitSampleType<HealthKitData>({
          endDate:
            nextMonth > endMonth
              ? endMonth.toISOString()
              : nextMonth.toISOString(),
          limit: 0,
          sampleName: config.type,
          startDate: startMonth.toISOString(),
        });
      startMonth.add(1, 'month');
      resultDataList.push(resultData);
    }

    const resultData = _.flattenDeep(resultDataList);
    const hasWatchRecords: boolean = some(resultData, (item: HealthKitData) =>
      lowerCase(item.source).includes('watch')
    );

    const filteredRecords: HealthKitData[] = filter(
      resultData,
      (item: HealthKitData) =>
        hasWatchRecords ? lowerCase(item.source).includes('watch') : true
    );

    const cleanedLogs = map(
      filteredRecords,
      ({
        workoutActivityName: category = 'Walking + Running',
        ...s
      }: HealthKitData) => ({
        category: s.unitName === 'count' ? undefined : capitalize(category),
        date: moment(s.startDate).format('YYYY-MM-DD'),
        source: 'healthkit',
        // Convert m to km / Convert step to km
        mileage:
          s.unitName === 'count'
            ? s.value! * 0.00075
            : (s.totalDistance! || s.value!) / 1000,
      })
    );

    // tslint:disable-next-line
    const logs = groupBy(
      cleanedLogs,
      (entry): string => `${entry.category}-${entry.date}`
    );
    console.log(
      `HealthKit Complete: Took `,
      new Date().getTime() - timer,
      'ms'
    );

    return map(logs, (logs) => ({
      ...logs[0],
      mileage: sumBy(logs, 'mileage'),
    }));
  };

  fetchData = async (
    date?: string | null
  ): Promise<{
    categorized: Partial<Log>[];
    uncategorized: Partial<Log>[];
  }> => {
    if (
      !(
        isPlatform('cordova') &&
        CapacitorHealthkit &&
        CapacitorHealthkit.isAvailable()
      )
    ) {
      return { categorized: [], uncategorized: [] };
    }

    const startDate = date
      ? moment(parseInt(date)).toDate()
      : moment(today).toDate();

    const steps = await this.fetchDataByType({
      startDate,
      type: 'stepCount',
    });

    const walkingRunning = await this.fetchDataByType({
      startDate,
      type: 'distanceWalkingRunning',
    });

    const workouts = await this.fetchDataByType({
      startDate,
      type: 'workoutType',
    });

    return {
      categorized: uniqBy(
        [...workouts, ...walkingRunning],
        (l) => `${l.mileage}-${l.date}`
      ),
      uncategorized: steps,
    };
  };
}

export const healthTracker = new HealthTracker();
