import React, { useContext, useEffect, useState } from 'react';
import { Map as MapComponent } from '../components/Map/Map';
import { Toolbar } from '../components/Toolbar';
import { PathContext, Waypoint } from '../context/PathContext';
import { UserContext } from '../context/UserContext';
import { useActiveRoute } from '../hooks/useActiveRoute';

export const Map = () => {
  const { getLastWaypoint, getUnlockedWaypoints, loading, getWaypoints } =
    useContext(PathContext);
  const { friends, user } = useContext(UserContext);
  const { mileage, route } = useActiveRoute();
  const [showMap, setShowMap] = useState(false);
  const [waypoints, setWaypoints] = useState<Waypoint[]>([]);

  useEffect(() => {
    (async () => {
      setWaypoints(await getWaypoints(route));
    })();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loading) {
      setShowMap(true);
    }
  }, [loading]);

  return (
    <>
      <Toolbar />
      {showMap && (
        <MapComponent
          activeUserId={user.id}
          controls
          draggable
          center={getLastWaypoint(mileage)}
          route={route}
          paths={[
            // @TODO: Can we use CSS variables here?
            { color: '#e9b20a', dashed: true, path: waypoints },
            {
              color: '#de4e00',
              path: getUnlockedWaypoints(mileage),
            },
          ]}
          users={[...friends, user]}
        />
      )}
    </>
  );
};
