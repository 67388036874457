import { useMutation } from 'react-apollo';
import { Product } from '../context/RouteContext';
import { PURCHASE } from '../queries/payment';
import { CANCEL_SUBSCRIPTION } from '../queries/cancel';
import constants from '../util/constants';

interface BasicData {
  email: string;
  name: string;
}

export function usePayment(isLoggedIn: boolean) {
  const [makePurchase] = useMutation(PURCHASE);
  const [cancellation] = useMutation(CANCEL_SUBSCRIPTION);

  const makePayment = async (
    product: Product,
    promoCode?: string,
    data?: BasicData
  ) => {
    if (isLoggedIn) {
      const {
        data: { purchase },
      } = await makePurchase({
        variables: { external_id: product.external_id, promo_code: promoCode },
      });
      return purchase;
    } else {
      return await fetch(`${constants.ENDPOINT}/stripe/purchase`, {
        method: 'post',
        headers: {
          'Content-type': 'application/json',
        },
        body: JSON.stringify({
          promo_code: promoCode,
          external_id: product.external_id,
          ...data,
          // route:
        }),
      }).then((result) => result.json());
    }
  };

  const validateCoupon = async (promoCode: string) => {
    return await fetch(`${constants.ENDPOINT}/coupon?code=${promoCode}`, {
      method: 'get',
      headers: {
        'Content-type': 'application/json',
      },
    }).then((result) => result.json());
  };

  const cancelSubscription = async () => {
    const {
      data: {
        cancelSubscription: { user },
      },
    } = await cancellation();
    return user;
  };

  return { makePayment, validateCoupon, cancelSubscription } as const;
}
