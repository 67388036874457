import { IonItem, IonLabel, IonSelect, IonSelectOption } from '@ionic/react';
import { map } from 'lodash';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteContext } from '../context/RouteContext';
import _ from 'lodash';
import { useActiveRoute } from '../hooks/useActiveRoute';

export const RouteSettings = () => {
  const { routes, setRoute } = useContext(RouteContext);
  const { route } = useActiveRoute();
  const { t } = useTranslation();

  const handleChange = async (e: { detail: { value: any } }) => {
    const { value } = e.detail;
    try {
      // NOTE: Using type 'any' because onIonChange returns 'value' as both a number and a string when updateUser() is called, even when forcing 'value' to be number
      // 'value' is first a number, then a string, then a number again
      setRoute(_.find(routes, { id: parseInt(value, 10) })!);
    } catch (error) {
      console.error('Could not update user route_id:', error);
    }
  };

  return (
    <IonItem lines="none">
      <IonLabel>{t('pages.settings.ROUTE')}</IonLabel>

      <IonSelect
        value={route.id}
        placeholder={t('pages.settings.SELECT_ONE')}
        onIonChange={handleChange}
        interface="alert"
      >
        {map(routes, (route) => (
          <IonSelectOption key={route.id} value={route.id}>
            {route.name}
          </IonSelectOption>
        ))}
      </IonSelect>
    </IonItem>
  );
};
