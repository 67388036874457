import { IonBadge, IonContent } from '@ionic/react';
import { sortBy, sumBy } from 'lodash';
import React, { useContext, useMemo } from 'react';

import { H4 } from '../../components/Text';
import { Timeline } from '../../components/Timeline/Timeline';
import { DestinationContext } from '../../context/DestinationContext';
import { Group } from '../../context/GroupContext';
import { Route, RouteContext } from '../../context/RouteContext';
import { PublicUser, User, UserContext } from '../../context/UserContext';
import constants from '../../util/constants';
import { renderDistance } from '../../util/renderDistance';
import { GroupSettings } from './GroupSettings';

type Props = { group: Group; route: Route; user: User };

export const Detail = ({ group, route, user }: Props) => {
  const { getUnlockedDestinations } = useContext(DestinationContext);
  const { getRouteSettings } = useContext(UserContext);
  const { getById } = useContext(RouteContext);

  const sortedUsers = useMemo(() => {
    return sortBy(
      group.users,
      (user) => getRouteSettings(route, user).mileage
    ).reverse();
  }, [group.users, group.users.length]); // eslint-disable-line

  const distanceUnit =
    user?.user_settings.distance_unit || constants.DISTANCE_UNITS.DEFAULT;

  const totalDistance = useMemo(() => {
    return renderDistance(
      sumBy(sortedUsers, (user) => getRouteSettings(route, user).mileage),
      distanceUnit,
      {
        displayUnit: false,
      }
    );
  }, [distanceUnit, getRouteSettings, route, sortedUsers]);

  const unlockedDestinations = useMemo(() => {
    const user = sortedUsers[0] as PublicUser;
    const mileage = getRouteSettings(route, user).mileage || 0;
    return getUnlockedDestinations(mileage);
  }, [getRouteSettings, getUnlockedDestinations, route, sortedUsers]);

  return (
    <IonContent
      style={{
        // @ts-ignore
        '--offset-top': '200px',
      }}
    >
      <div
        style={{
          backgroundImage: `linear-gradient(rgba(0,0,0,0), rgba(0,0,0,.66)), url(${
            unlockedDestinations[unlockedDestinations.length - 1]?.image
          })`,
          backgroundPositionY: 'center',
          backgroundSize: 'cover',
          color: 'white',
          height: '40%',
          position: 'relative',
          width: '100%',
        }}
      >
        <div
          style={{
            position: 'absolute',
            right: '10px',
            top: '10px',
          }}
        >
          <GroupSettings group={group} />
        </div>
        <div style={{ bottom: '10px', left: '10px', position: 'absolute' }}>
          {group.name} arrived at <br />
          <H4>{unlockedDestinations[unlockedDestinations.length - 1].name}</H4>
          <IonBadge>{getById(group.route_id).name}</IonBadge>
          <br />
          <strong>{sortedUsers.length}</strong> member(s)
          <br />
          <strong>{totalDistance}</strong> {distanceUnit} traveled
        </div>
      </div>
      <Timeline
        currentUser={user}
        distanceUnit={distanceUnit}
        route={route}
        sortedUsers={sortedUsers}
      />
    </IonContent>
  );
};
