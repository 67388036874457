import styled from '@emotion/styled';
import { random, sample } from 'lodash';
import React, { CSSProperties, SVGProps, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Badge1 } from '../../assets/badge_1.svg';
import { ReactComponent as Badge2 } from '../../assets/badge_2.svg';
import { ReactComponent as Badge3 } from '../../assets/badge_3.svg';

import Stamp from '../../assets/trace.svg';
import Stamp33 from '../../assets/stamp_33.png';
import Stamp67 from '../../assets/stamp_67.png';
import Stamp100 from '../../assets/stamp_100.png';
import Stamp200 from '../../assets/stamp_200.png';
import Stamp300 from '../../assets/stamp_300.png';
import Stamp400 from '../../assets/stamp_400.png';
import Stamp500 from '../../assets/stamp_500.png';
import { Caption, Text } from '../Text';
import { IDestination } from '../../context/DestinationContext';
import { useActiveRoute } from '../../hooks/useActiveRoute';
import _ from 'lodash';
import { renderDistance } from '../../util/renderDistance';
import constants from '../../util/constants';

const backgrounds = [Badge1, Badge2, Badge3];

const distanceStamps: any = {
  'camino/frances': {
    160: Stamp100,
    320: Stamp200,
    480: Stamp300,
    640: Stamp400,
  },
  'camino/finisterre': {
    53: Stamp33,
    108: Stamp67,
    160: Stamp100,
  },
  // TODO: Make sure that we have correct milestones / stamps for Norte route
  'camino/norte': {
    160: Stamp100,
    320: Stamp200,
    480: Stamp300,
    640: Stamp400,
    800: Stamp500,
  },
};

const BadgeText = styled(Text)`
  --color-shadow: #f0ede2;
  color: #555;
  font-family: var(--font-stamp);
  padding: 2px;
  position: absolute;
  text-align: center;
  text-shadow: 2px 0 0 var(--color-shadow), -2px 0 0 var(--color-shadow),
    0 2px 0 var(--color-shadow), 0 -2px 0 var(--color-shadow),
    1px 1px var(--color-shadow), -1px -1px 0 var(--color-shadow),
    1px -1px 0 var(--color-shadow), -1px 1px 0 var(--color-shadow);
`;

export type BadgeConfig =
  | IDestination
  | { id: string; from_start: number; name?: string };

export const Badge = ({
  distance,
  destinationName,
  name,
  onClick,
  size,
  style,
  disabled = false,
}: {
  disabled?: boolean;
  distance?: number;
  destinationName?: string;
  name?: string;
  onClick?(): void;
  size?: number;
  style?: CSSProperties;
}) => {
  const { t } = useTranslation();
  const angle = useMemo(
    () =>
      (destinationName ? destinationName.length % 45 : random(0, 45)) - 22.5,
    [destinationName]
  );
  const sizePx = `${size}px`;
  const useStaticImage = !name;

  // Random color
  const badgeColor = useMemo(
    () =>
      destinationName
        ? `badge badge-color-${(destinationName.length % 6) + 1}`
        : undefined,
    [destinationName]
  );

  const { route } = useActiveRoute();

  // Random background
  const Background: React.FC<SVGProps<SVGSVGElement>> | undefined =
    useMemo(() => {
      const useStamp = useStaticImage && distance;
      let src = '';
      if (useStamp) {
        src = _.get(distanceStamps, `[${route.path}][${distance}]`);
      }

      return useStamp
        ? () => (
            <div
              style={{
                padding: src ? '0' : '5px',
              }}
            >
              <img alt="Distance Stamp" src={src || Stamp} />
              {!src ? (
                // When we don't have a stamp image, use the fallback and display the distances
                <Caption
                  color="var(--color-secondary-900)"
                  style={{ fontWeight: '500', fontSize: '16px' }}
                >
                  {renderDistance(distance, constants.DISTANCE_UNITS.MILES, {
                    displayUnit: false,
                  })}{' '}
                  mi /{' '}
                  {renderDistance(distance, constants.DISTANCE_UNITS.KM, {
                    displayUnit: false,
                  })}{' '}
                  km
                </Caption>
              ) : null}
            </div>
          )
        : destinationName
        ? backgrounds[destinationName.length % backgrounds.length]
        : sample(backgrounds);
    }, [destinationName, distance, useStaticImage, route]);

  return (
    <div
      onClick={onClick}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '5px',
        transform: `rotate(${angle}deg)`,
        ...(size ? { height: sizePx, width: sizePx } : {}),
        ...style,
      }}
    >
      {Background && (
        <Background
          className={useStaticImage ? undefined : badgeColor}
          style={disabled ? { filter: 'grayscale(100%)', opacity: 0.4 } : {}}
        />
      )}

      {!useStaticImage && (
        <BadgeText
          fontFamily="var(--font-stamp)"
          fontSize={size ? `${(30 * size) / 220}px` : undefined}
        >
          {disabled ? t('pages.destination.CLICK_TO_STAMP') : name}
        </BadgeText>
      )}
    </div>
  );
};
