import {
  useIonAlert,
  isPlatform,
  IonButtons,
  IonTitle,
  IonToolbar,
  IonFooter,
  IonIcon,
} from '@ionic/react';
import { lockOpenOutline } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../components/Button/Button';

import { PageWrapper } from '../components/PageWrapper';
import { PaymentModal } from '../components/PaymentModal/PaymentModal';
import { RouteCard } from '../components/RouteCard/RouteCard';
import { Toolbar } from '../components/Toolbar';
import { useNotification } from '../context/NotificationContext';
import { Product, Route, RouteContext } from '../context/RouteContext';
import { UserContext } from '../context/UserContext';
import { useActiveRoute } from '../hooks/useActiveRoute';

export const Routes = () => {
  const [showAlert] = useIonAlert();
  const { notify } = useNotification();
  const { user } = useContext(UserContext);
  const { routes, setRoute } = useContext(RouteContext);
  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [selectedRoute, setSelectedRoute] = useState<Route>();
  const [product, selectProduct] = useState<Product>(routes[0].product);
  const activeRoute = useActiveRoute().route;
  const {
    subscriptions: [subscription],
  } = useContext(RouteContext);
  const { t } = useTranslation();

  const activateRoute = (route: Route) => {
    showAlert({
      header: t('pages.routes.SWITCHING_ROUTES'),
      message: t('pages.routes.SWITCHING_ROUTES_MSG', {
        routeName: route.name,
      }),
      buttons: [
        t('common.CANCEL'),
        {
          text: t('common.OK'),
          handler: () => {
            setSelectedRoute(route);
            setRoute(route);
            notify(t('pages.routes.SELECTED', { routeName: route.name }));
          },
        },
      ],
    });
  };

  const purchase = (product: Product) => {
    selectProduct(product);
    setShowPaymentModal(true);
  };

  const isSubscriberOrMember = user.plan === 'monthly' || user.plan === 'team';

  return (
    <>
      <Toolbar />
      {/* TODO: set up subscription for IAP2: Issue#341  */}
      {!isPlatform('cordova') && !isPlatform('ios') && !isSubscriberOrMember && (
        <IonFooter>
          <IonToolbar color="dark">
            <IonButtons slot="start">
              <Button
                style={{ marginLeft: '20px' }}
                color="primary"
                fill="solid"
                onClick={() => purchase(subscription.product)}
              >
                <IonIcon icon={lockOpenOutline} />
                &nbsp;{t('pages.routes.BANNER_BUTTON')}
              </Button>
            </IonButtons>
            <IonTitle>{t('pages.routes.BANNER_TEXT')}</IonTitle>
          </IonToolbar>
        </IonFooter>
      )}
      <PageWrapper>
        {routes.map((route: Route) => (
          <RouteCard
            activeRoute={activeRoute}
            key={route.id}
            onActivate={activateRoute}
            onPurchase={(route) => purchase(route.product)}
            onSelect={() => setSelectedRoute(route)}
            route={route}
            selected={selectedRoute?.id === route.id}
          />
        ))}
        <PaymentModal
          key={
            selectedRoute?.product.external_id ||
            subscription.product.external_id
          }
          onClose={() => {
            setShowPaymentModal(false);
          }}
          isOpen={showPaymentModal}
          product={product}
        />
      </PageWrapper>
    </>
  );
};
