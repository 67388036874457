import { Media, MediaObject } from '@ionic-native/media';
import { isPlatform } from '@ionic/react';

class AudioPlayer {
  // @ts-ignore
  file: MediaObject | HTMLAudioElement = undefined;
  isMobile = isPlatform('cordova');

  load(src: string) {
    if (this.isMobile) {
      this.file = Media.create(src);
    } else {
      this.file = new Audio(src);
    }
  }

  play() {
    try {
      this.file && this.file.play();
    } catch (e) {
      console.log(e);
    }
  }

  pause() {
    this.file && this.file.pause();
  }

  release() {
    if (this.isMobile) {
      // @ts-ignore
      this.file.release();
    }
    // @ts-ignore
    this.file = null;
  }

  restart() {
    if (this.file) {
      if (this.isMobile) {
        const mobileFile = this.file as MediaObject;
        mobileFile.seekTo(0);
      } else {
        const audioFile = this.file as HTMLAudioElement;
        audioFile.currentTime = 0;
      }
    }
  }
}

export const audioPlayer = new AudioPlayer();
