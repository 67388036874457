import React, { createContext, useState, useEffect, useContext } from 'react';
import { useApolloClient } from 'react-apollo';
import { InAppPurchase2, IAPProduct } from '@ionic-native/in-app-purchase-2';

import { Log, UserContext } from './UserContext';
import { GET_ROUTES, GET_SUBSCRIPTIONS } from '../queries/route';
import _ from 'lodash';
import { isPlatform } from '@ionic/react';
import constants from '../util/constants';

export enum ProductType {
  SUBSCRIPTION = 'recurring',
  ONE_TIME = 'one_time',
}

export interface RouteMeta {
  distance: number;
  milestones: string[];
  waypoints: string;
  images: string;
  places: {
    en: string;
    es: string;
  };
}

export interface DescriptionMeta {
  en: string;
  es: string;
}

export interface Route {
  id: number;
  description: DescriptionMeta;
  route_image: string;
  certificate_image_code: string;
  meta: RouteMeta;
  name: string;
  path: string;
  category_id?: number;
  product: Product;
  version: string;
}
export interface Product {
  id?: string;
  amount: number;
  currency: string;
  external_id: string;
  type: ProductType;
}
export interface RouteSettings {
  destinations_visited: string[];
  logs: Log[];
  mileage: number;
  payment_status: boolean;
  route_id: number;
  stamps: string[];
}
export interface RouteConsumer {
  getById(id: number): Route;
  loading: boolean;
  routes: Route[];
  setRoute(route: Route): void;
  subscriptions: Subscription[];
}

export interface Subscription {
  name: string;
  product: Product;
}

export const RouteContext = createContext<RouteConsumer>({} as RouteConsumer);

export const RouteProvider = ({ children }: any) => {
  const client = useApolloClient();
  const { loading: userLoading, updateUser, user } = useContext(UserContext);
  const [routes, setRoutes] = useState<Route[]>([]);
  const [subscriptions, setSubscriptions] = useState<Subscription[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const getSubscriptions = async () => {
      try {
        const {
          data: { subscriptions },
        } = await client.query({
          query: GET_SUBSCRIPTIONS,
          fetchPolicy: 'cache-first',
        });

        setSubscriptions(() => subscriptions);
        return subscriptions;
      } catch (e) {
        // console.error('Could not fetch subscription:', e);
      }
    };

    const getRoutes = async () => {
      try {
        const {
          data: { routes },
        } = await client.query({
          query: GET_ROUTES,
          fetchPolicy: 'cache-first',
        });

        if (routes.length) {
          setRoutes(() => routes);
          return routes;
        }
      } catch (e) {
        console.error('Could not fetch routes:', e);
      }
    };

    const updateRoutePrices = async (routes: Route[]) => {
      InAppPurchase2.validator = `${constants.ENDPOINT}/check-purchase/${user.id}`;

      const products = _.map(routes, (route) => {
        return {
          id: route.product.external_id,
          type: InAppPurchase2.NON_CONSUMABLE,
        };
      });

      const promises = _.map(products, async (prod) => {
        const updatePrice = new Promise<void>((resolve, reject) => {
          InAppPurchase2.when(prod.id).updated(async (p: IAPProduct) => {
            const priceIAP = p.priceMicros / 10000;

            setRoutes((routes) => {
              const updatedRoutes = _.map(routes, (route) => {
                if (route.product.external_id === p.id) {
                  return {
                    ...route,
                    product: {
                      ...route.product,
                      amount: priceIAP,
                      currency: p.currency,
                    },
                  };
                } else {
                  return route;
                }
              });
              resolve();
              return updatedRoutes;
            });
          });
        });
        return updatePrice;
      });

      InAppPurchase2.register(products);
      for (const product of products) {
        InAppPurchase2.when(product.id).verified((p: IAPProduct) => {
          p.finish();
        });
        InAppPurchase2.when(product.id).approved((p: IAPProduct) => {
          p.verify();
          p.finish();
        });
      }
      InAppPurchase2.refresh();
      await Promise.all(promises);
      return products;
    };

    //@TODO: Issue#341 ----
    // const updateSubscriptionPrice = async (subscriptionSubscription) => {
    //   const product = {
    //     id: subscription.product.external_id,
    //     type: InAppPurchase2.NON_CONSUMABLE,
    //   };

    //   InAppPurchase2.when(product.id).updated(async (p: IAPProduct) => {
    //     const priceIAP = p.priceMicros / 10000;

    //     setSubscription({
    //       product: {
    //         ...subscription.pricing,
    //         amount: priceIAP,
    //         currency: p.currency,
    //       },
    //     });
    //   });
    //   // InAppPurchase2.refresh();
    //   // InAppPurchase2.register(product);
    //   return product;
    // };
    // const updateProducts = async (
    //   routes: Route[],
    //   subscription: Subscription
    // ) => {
    //   const routesIAP = await updateRoutePrices(routes);
    //   const subscriptionIAP = await updateSubscriptionPrice(subscription);
    //   const products = [...routesIAP, subscriptionIAP];

    //   InAppPurchase2.refresh();
    //   InAppPurchase2.register(products);
    // };
    // @TODO:end ----

    const getProducts = async () => {
      setLoading(() => true);
      try {
        // TODO: Issue#341
        await getSubscriptions();
        const routes = await getRoutes();

        if (isPlatform('ios') && isPlatform('cordova')) {
          // TODO: setting up subscription for IAP2: Issue#341
          // await updateProducts(routes, subscription);
          await updateRoutePrices(routes);
        }
        setLoading(() => false);
      } catch (e) {
        // console.error('Could not fetch routes and/or subscription:', e);
        setLoading(() => false);
      }
    };

    if (!userLoading) {
      getProducts();
    }
  }, [user.id, client, userLoading]);

  const setRoute = (route: Route) => {
    updateUser({
      route_id: route.id,
    });
  };

  const getById = (id: number): Route => {
    return (
      _.find(routes, { id }) ||
      ({
        name: 'Missing Route',
      } as Route)
    );
  };

  return (
    <RouteContext.Provider
      value={{ getById, loading, routes, setRoute, subscriptions }}
    >
      {children}
    </RouteContext.Provider>
  );
};
