import React, { createContext, useState } from 'react';
import { useMutation } from 'react-apollo';

import {
  CONVERSATION,
  CREATE_MESSAGE,
  DELETE_MESSAGE,
} from '../queries/message';
import { PublicUser } from './UserContext';

export interface Message {
  id: number;
  content: string;
  created_at: string;
  user: PublicUser;
}
interface MessageConsumer {
  loading: boolean;
  createMessage(conversationId: number, message: string): Promise<Message[]>;
  deleteMessage(message: Message): Promise<Message[]>;
  fetchMessages(conversationId: number): Promise<Message[]>;
}

export const MessageContext = createContext<MessageConsumer>({
  loading: true,
} as MessageConsumer);

export const MessageProvider = ({ children }: any) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [loadConversation] = useMutation(CONVERSATION);
  const [createMessageApi] = useMutation(CREATE_MESSAGE);
  const [deleteMessageApi] = useMutation(DELETE_MESSAGE);

  const fetchMessages = async (id: number) => {
    setLoading(true);
    const {
      data: { conversation: result },
    } = await loadConversation({
      variables: {
        id,
      },
    });
    setLoading(false);
    return result;
  };

  const createMessage = async (id: number, message: string) => {
    const {
      data: { createMessage: result },
    } = await createMessageApi({
      variables: {
        id,
        message,
      },
    });
    return result;
  };

  const deleteMessage = async (message: Message) => {
    const {
      data: { deleteMessage: result },
    } = await deleteMessageApi({
      variables: {
        id: message.id,
      },
    });
    return result ? result.messages : [];
  };

  return (
    <MessageContext.Provider
      value={{
        loading,
        createMessage,
        deleteMessage,
        fetchMessages,
      }}
    >
      {children}
    </MessageContext.Provider>
  );
};
