import {
  IonIcon,
  IonModal,
  IonContent,
  IonFabButton,
  IonFab,
} from '@ionic/react';
import { closeOutline } from 'ionicons/icons';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { P } from '../components/Text';
import { ModalHeader } from './ModalHeader';
import pilgrims_passport from '../assets/pilgrims_passport.jpg';

export const PassportInfoModal = ({ onClose }: { onClose?(): void }) => {
  const [showModal, setShowModal] = useState(true);
  const { t } = useTranslation();

  return (
    <IonModal
      onDidDismiss={() => {
        setShowModal(false);
        onClose && onClose();
      }}
      isOpen={showModal}
    >
      <IonFab vertical="top" horizontal="end">
        <IonFabButton
          color="dark"
          onClick={() => setShowModal(false)}
          size="small"
        >
          <IonIcon icon={closeOutline} size="small" />
        </IonFabButton>
      </IonFab>
      <ModalHeader imageUrl={pilgrims_passport} />
      <IonContent style={{ height: '60vh' }}>
        <P style={{ padding: '18px' }}>
          {t('components.PassportInfoModal.Description')}
        </P>
      </IonContent>
    </IonModal>
  );
};
