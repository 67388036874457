import {
  IonIcon,
  IonModal,
  IonContent,
  IonFabButton,
  IonButton,
  IonFab,
  isPlatform,
} from '@ionic/react';
import { closeOutline, trailSignOutline } from 'ionicons/icons';
import { includes } from 'lodash';
import React, { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { UserContext } from '../../context/UserContext';
import { Caption, H4, P } from '../Text';
import { DestinationTitle } from '../DestinationTitle/DestinationTitle';
import { ModalHeader } from '../ModalHeader';
import { renderDistance } from '../../util/renderDistance';
import { useUserDestinations } from '../../hooks/useUserDestinations';

export const CongratulationsModal = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const {
    finishedCamino,
    lastDestination,
    nextDestination,
    unlockedDestinations,
  } = useUserDestinations();

  const [showModal, setShowModal] = useState(false);
  const [upcomingDestination, setUpcomingDestination] =
    useState(nextDestination);

  useEffect(() => {
    if (
      includes(unlockedDestinations, upcomingDestination) &&
      showModal === false
    ) {
      setShowModal(true);
    }
    setUpcomingDestination(nextDestination);
  }, [nextDestination, showModal, unlockedDestinations, upcomingDestination]);

  // Do not show for the first town
  // preventing intermittent error 'cannot read property 'from_start' of undefined by
  // checking if lastDestination is defined before checking any props on lastDestination
  return lastDestination && lastDestination.from_start > 0 ? (
    <IonModal
      onDidDismiss={() => setShowModal(false)}
      isOpen={showModal}
      id="congradulations-modal"
    >
      <IonFab
        vertical="top"
        horizontal="end"
        style={{ padding: isPlatform('ios') ? '10px' : '' }}
      >
        <IonFabButton
          id="close-congradulations-modal"
          color="dark"
          onClick={() => setShowModal(false)}
          size="small"
        >
          <IonIcon icon={closeOutline} size="small" />
        </IonFabButton>
      </IonFab>
      <div>
        <>
          <ModalHeader imageUrl={lastDestination.image}>
            <DestinationTitle arrived destination={lastDestination} />
          </ModalHeader>
          <IonContent style={{ height: '60vh' }}>
            <div
              style={{
                padding: '20px',
                textAlign: finishedCamino ? 'center' : 'inherit',
              }}
            >
              <H4 color="#333">
                {t('components.CongratulationsModal.CONGRATS')}
              </H4>
              {finishedCamino && (
                <P>{t('components.CongratulationsModal.YOU_FINISHED')}</P>
              )}
              <Caption color="#999" style={{ marginBottom: '10px' }}>
                {t('components.CongratulationsModal.YOU_TRAVELLED')}{' '}
                <IonIcon icon={trailSignOutline} />
                {renderDistance(
                  lastDestination.from_start,
                  user?.user_settings.distance_unit
                )}{' '}
                {t('components.CongratulationsModal.ALONG_THE_CAMINO')}.
              </Caption>
              <IonButton routerLink={`/dashboard/dest/${lastDestination.id}`}>
                {t('components.CongratulationsModal.WHAT_WE_SAW')}
              </IonButton>
            </div>
          </IonContent>
        </>
      </div>
    </IonModal>
  ) : null;
};
