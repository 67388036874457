import { gql } from 'apollo-boost';

export const PURCHASE = gql`
  mutation purchase($route: Int, $promo_code: String, $external_id: String) {
    purchase(
      route: $route
      external_id: $external_id
      promo_code: $promo_code
    ) {
      client_secret
    }
  }
`;
