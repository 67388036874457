import React, { useContext, useEffect } from 'react';
import { Login } from './Login';
import { P } from '../components/Text';
import { TeamContext, TeamProvider } from '../context/TeamContext';
import { Spinner } from '../components/Spinner/Spinner';
import { UserContext } from '../context/UserContext';
import { useLocalStorage } from '../hooks/useLocalStorage';

export const RedeemLogin = (props: any) => {
  return (
    <TeamProvider>
      <RedeemLoginComponent {...props} />
    </TeamProvider>
  );
};

const RedeemLoginComponent = (props: any) => {
  const {
    match: {
      params: { handle },
    },
  } = props;
  const { loading, team, loadTeam } = useContext(TeamContext);
  const { loading: loadingUser, loggedIn } = useContext(UserContext);
  // 'get' is assigned a value but never used
  // eslint-disable-next-line
  const [get, set] = useLocalStorage();

  const redirectUrl = `/dashboard/redeem/${handle}`;
  // Login will force a redirect to this page
  set('redirectUrl', redirectUrl);

  useEffect(() => {
    if (!loading && !team) loadTeam(handle);
    if (!loadingUser && loggedIn) {
      // @TODO today we need to reload the page since navigation doesn't work.
      // history.push(redirectUrl);
      window.location.pathname = redirectUrl;
    }
    // eslint-disable-next-line
  }, []);
  return (
    <Login
      {...props}
      message={
        <P style={{ textAlign: 'center' }}>
          {loading || loadingUser || loggedIn ? (
            <Spinner />
          ) : (
            <>
              Welcome to Camino for Good!
              <br />
              You'll need to login before you can redeem your seat{' '}
              {team?.name && 'on'}
              <br />
              <strong>{team?.name}</strong>
            </>
          )}
        </P>
      }
    />
  );
};
