import React, { createContext, useState } from 'react';
import { useMutation } from 'react-apollo';
import { JOIN_TEAM } from '../queries/team';
import constants from '../util/constants';

interface TeamConsumer {
  loading: boolean;
  team: TeamMembership | undefined;
  loadTeam(id: number, email?: string): void;
  joinTeam(id: number): Promise<TeamResponse>;
}

interface TeamMembership {
  name: String;
  onTeam: boolean;
  owner: String;
  hasCapacity: boolean;
}

interface TeamVars {
  id: Number;
}

export interface TeamResponse {
  onTeam: boolean;
  owner: String;
  team: String;
  seatCount: Number;
  seatsAvailable: Number;
  seatsUsed: Number;
}

export const TeamContext = createContext<TeamConsumer>({
  loading: true,
} as TeamConsumer);

export const TeamProvider = ({ children }: any) => {
  const [loadingTeam, setLoadingTeam] = useState(false);
  const [team, setTeam] = useState(undefined);

  const [joinTeamMutation, { loading: joiningTeam }] = useMutation<
    TeamMembership,
    TeamVars
  >(JOIN_TEAM);

  const loadTeam = async (id: number, email = '') => {
    const params = email ? `?email=${encodeURIComponent(email)}` : '';
    setLoadingTeam(true);
    try {
      const response = await fetch(
        `${constants.ENDPOINT}/teams/${id}${params}`
      ).then((res) => res.json());

      if (response.message) {
        // Something went wrong.
      } else {
        setTeam(response);
      }
    } catch (e) {}
    setLoadingTeam(false);
  };

  const joinTeam = async (id: number) => {
    const {
      // @ts-ignore Empty response
      data: { joinTeam },
    } = await joinTeamMutation({ variables: { id } });
    return joinTeam;
  };
  return (
    <TeamContext.Provider
      value={{
        loading: loadingTeam || joiningTeam,
        team,
        joinTeam,
        loadTeam,
      }}
    >
      {children}
    </TeamContext.Provider>
  );
};
