import React, { useContext, useEffect, useState } from 'react';
import { TeamContext, TeamProvider } from '../context/TeamContext';
import { Toolbar } from '../components/Toolbar';
import { PageWrapper } from '../components/PageWrapper';
import { IonCol, IonRow } from '@ionic/react';
import { UserContext } from '../context/UserContext';
import { Button } from '../components/Button/Button';
import { useLocation } from 'react-router-dom';
import { Caption, H2, P, Text } from '../components/Text';
import { useTranslation } from 'react-i18next';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export const Redeem = (props: any) => {
  return (
    <TeamProvider>
      <RedeemComponent {...props} />
    </TeamProvider>
  );
};

export const RedeemComponent = ({
  match: {
    params: { id },
  },
}: any) => {
  const { joinTeam, loading, team, loadTeam } = useContext(TeamContext);
  const { fetchCurrentUser, user } = useContext(UserContext);
  const { t } = useTranslation();

  const [joiningTeam, joiningTheTeam] = useState<boolean>(false);
  const [onTeam, setOnTeam] = useState<boolean>(false);
  const [previouslyJoined, setPreviouslyJoined] = useState<boolean>(false);
  let query = useQuery();
  const force = query.get('force');

  // Load up the team object from the url
  useEffect(() => {
    if (!team && user) loadTeam(id, user.email);
    // eslint-disable-next-line
  }, []);

  // Check if the user is already on the team
  useEffect(() => {
    if (team) {
      setOnTeam(team.onTeam);
      setPreviouslyJoined(team.onTeam);
    }
  }, [team]);

  const join = async () => {
    joiningTheTeam(true);
    const { onTeam: joinedTeamSuccess } = await joinTeam(id);
    setOnTeam(joinedTeamSuccess);
    if (joinedTeamSuccess) {
      // Reload the user
      fetchCurrentUser();
    }
    joiningTheTeam(false);
  };

  return (
    <>
      <Toolbar />
      <PageWrapper
        loading={loading || joiningTeam}
        gridStyle={{
          alignItems: 'center',
          display: 'flex',
          height: '100%',
          justifyContent: 'center',
        }}
      >
        <IonRow>
          <IonCol sizeMd="12">
            {!loading && !joiningTeam && (
              <>
                {!team && (
                  <div>
                    <H2 style={{ textAlign: 'center' }}>
                      {t('pages.redeem.ISSUE')}
                    </H2>
                    <Text style={{ marginTop: '20px', textAlign: 'center' }}>
                      {t('pages.redeem.NO_TEAM')}
                    </Text>
                  </div>
                )}

                {onTeam && (
                  <>
                    <H2 style={{ textAlign: 'center' }}>
                      {t('common.TEAM')} <strong>{team?.name}</strong>
                    </H2>
                    <Text style={{ marginTop: '20px', textAlign: 'center' }}>
                      {previouslyJoined
                        ? t('pages.redeem.ALREADY_IN_TEAM')
                        : t('pages.redeem.CONGRATULATIONS')}
                    </Text>
                  </>
                )}

                {(!onTeam || force) && team && (
                  <div>
                    <H2 style={{ textAlign: 'center' }}>
                      {t('common.TEAM')} <strong>{team?.name}</strong>
                    </H2>
                    <P style={{ marginTop: '20px', textAlign: 'center' }}>
                      {t('pages.redeem.WANT_TO_REDEM')}
                    </P>
                    <Text style={{ marginTop: '20px', textAlign: 'center' }}>
                      <Button onClick={join}>{t('pages.redeem.JOIN')}</Button>
                    </Text>
                  </div>
                )}
              </>
            )}
          </IonCol>
        </IonRow>
        {team && (
          <Caption
            style={{
              bottom: 0,
              position: 'fixed',
              textAlign: 'center',
              padding: '20px',
            }}
          >
            {t('pages.redeem.NEED_SUPPORT')}{' '}
            <a
              href={`mailto:${team.owner}?subject=Having trouble with ${team.name}`}
            >
              {team.owner}
            </a>
          </Caption>
        )}
      </PageWrapper>
    </>
  );
};
