import React, { useContext } from 'react';

import { DonutChart } from '../../components/charts/DonutChart';
import { UserContext } from '../../context/UserContext';
import constants from '../../util/constants';
import { renderDistance } from '../../util/renderDistance';
import { useTranslation } from 'react-i18next';

import { subtractDistance } from '../../util/subtractDistance';

export const ProgressDonutChart = ({
  currentMileage,
  isResponsive,
  route,
  size,
}: any) => {
  const {
    user: { user_settings } = {
      user_settings: { distance_unit: constants.DISTANCE_UNITS.DEFAULT },
    },
  } = useContext(UserContext);
  const { t } = useTranslation();

  return (
    <DonutChart
      currentMileage={currentMileage}
      chartData={[
        {
          y: currentMileage,
        },
        {
          // @TODO: FIX WITH A SUBTRACTDISTANCE COMPONENT
          // if entering 215.299km on route Finisterre, the trail complete with 100%
          // the route.meta.distance is 215.3
          // the currentMileage is 215.299
          // the route.meta.distance - currentMileage is 0 instead of 0.001
          y: subtractDistance(route.meta.distance, currentMileage),
        },
      ]}
      hasHiddenLabels
      height={size}
      isResponsive={isResponsive}
      text={`${renderDistance(
        subtractDistance(route.meta.distance, currentMileage),
        user_settings.distance_unit
      )} ${t(`components.DonutChart`)}`}
      sliceColors={['var(--ion-color-primary)', '#f3d5787d']}
      width={size}
    />
  );
};
