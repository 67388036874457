import { filter, find, uniq, without } from 'lodash';
import { useCallback, useContext, useMemo } from 'react';
import { useMutation } from 'react-apollo';
import { Route, RouteContext } from '../context/RouteContext';
import { UserContext } from '../context/UserContext';
import { UPDATE_DESTINATIONS_VISITED, UPDATE_STAMPS } from '../queries/user';
import constants from '../util/constants';

export function useActiveRoute() {
  const { getRouteSettings, setUser, user } = useContext(UserContext);
  const { routes } = useContext(RouteContext);
  const [updateUserDestinations] = useMutation(UPDATE_DESTINATIONS_VISITED);
  const [updateUserStamps] = useMutation(UPDATE_STAMPS);

  const activeRoute =
    (find(routes, { id: user.route_id }) as Route) || routes[0];

  const activeUserRoute = useMemo(
    () => getRouteSettings(activeRoute),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeRoute, user, getRouteSettings]
  );

  const updateDestinations = useCallback(
    async (destinations: string[]) => {
      // @TODO: Can remove the _.without(empty) once no users are left with destinations_visited set to ['empty']
      const destinations_visited = without(
        uniq([
          ...(activeUserRoute ? activeUserRoute.destinations_visited : []),
          ...destinations,
        ]),
        'empty'
      );

      const {
        data: {
          updateDestinationsVisited: { data: list },
        },
      } = await updateUserDestinations({
        variables: {
          destinations_visited,
        },
      });
      const route_settings = [...user.route_settings];
      const route = find(route_settings, { route_id: activeRoute.id });
      if (route) {
        route.destinations_visited = list;
        setUser({ route_settings });
      }
    },
    [activeRoute.id, activeUserRoute, setUser, user, updateUserDestinations]
  );

  const updateStamps = useCallback(
    async (stamps: string[]) => {
      const {
        data: {
          updateStamps: { data: list },
        },
      } = await updateUserStamps({
        variables: { stamps },
      });
      const route_settings = [...user.route_settings];
      const route = find(route_settings, { route_id: activeRoute.id });
      if (route) {
        route.stamps = list;
        setUser({ route_settings });
      }
    },
    [activeRoute.id, setUser, user, updateUserStamps]
  );

  const addStamp = useCallback(
    async (stamp: string) => {
      const stamps = [
        ...(activeUserRoute ? activeUserRoute.stamps : []),
        stamp,
      ];

      await updateStamps(stamps);
    },
    [activeUserRoute, updateStamps]
  );

  const removeStamp = useCallback(
    async (stamp: string) => {
      const stamps = [
        ...(activeUserRoute
          ? filter(activeUserRoute.stamps, (savedStamp) => savedStamp !== stamp)
          : []),
      ];

      await updateStamps(stamps);
    },
    [activeUserRoute, updateStamps]
  );

  const {
    destinations_visited = [],
    logs = [],
    mileage = 0,
    payment_status = false,
    stamps = [],
  } = activeUserRoute;

  return {
    addStamp,
    destinations_visited,
    logs,
    mileage,
    payment_status,
    removeStamp,
    route: activeRoute,
    showPaywall: !payment_status && mileage >= constants.PAYWALL_DISTANCE,
    stamps,
    updateDestinations,
  } as const;
}
