import { gql } from 'apollo-boost';
const baseUser = `
avatar
id
first_name
last_name
email
`;
export const CONVERSATION = gql`
  mutation conversation($id: Int!) {
    conversation(id: $id) {
      id
      content
      created_at
      user {
        ${baseUser}
      }
    }
  }
`;

export const CREATE_MESSAGE = gql`
  mutation createMessage($id: Int!, $message: String!) {
    createMessage(id: $id, message: $message) {
      id
      content
      created_at
      user {
        ${baseUser}
      }
    }
  }
`;

export const DELETE_MESSAGE = gql`
  mutation deleteMessage($id: Int!) {
    deleteMessage(id: $id) {
      id
      messages {
        id
        content
        created_at
        user {
          ${baseUser}
        }
      }
    }
  }
`;
