import { isPlatform } from '@ionic/core/components';
import { IonCol, IonRow } from '@ionic/react';
import logo from '../../assets/logo_horizontal.svg';
import { P } from '../Text';

type Props = {
  title: string;
  caption?: string;
};

export const SlideHeader: React.FC<Props> = ({ caption, title }) => (
  <IonRow
    className="ion-align-items-center"
    style={{ marginLeft: '20px', marginRight: '20px' }}
  >
    <IonCol sizeMd="4" sizeXs="12">
      <img alt="Camino for Good" src={logo} />
    </IonCol>
    <IonCol
      className={`ion-text-${isPlatform('desktop') ? 'right' : 'left'}`}
      sizeMd="8"
      sizeXs="12"
    >
      <h1>{title}</h1>
      {caption && <P color="grey">{caption}</P>}
    </IonCol>
  </IonRow>
);
